import { React } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

export const TextBoxField = ({
  value,
  setValue,
  placeholder,
  label,
  disabled,
}) => {
  return (
    <TextareaAutosize
      className="professional-comment-box"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      rowsMin='10'
      variant="outlined"
      required
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus
      multiline="true"
      test-id='upload-comment-box'
      style={{
        borderWidth: '3.4px',
        borderRadius: '5px',
        borderColor: '#c2c2c1',
        fontSize: '1.3em'
      }}
    />
  );
};

export default TextBoxField;