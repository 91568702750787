/** @description - analytics.js
 * 
 *  - Provides a central store of state for user data and the functions to set that user data.
 * 
 *  @todo
 *  @author Keith Ong
 */


export const clearUserState = () => {
    return ({
        type: 'RESET_STATE'
    })
}

export const setUser = data => {
    return ({
        type: 'SET_USER',
        data
    })
}

export const setIsThirdPartyAuthUser  = data => {
    return ({
        type: 'SET_IS_THIRD_PARTY_AUTH_USER',
        data
    })
}

export const setFirebaseToken  = data => {
    return ({
        type: 'SET_FIREBASE_TOKEN',
        data
    })
}

export const setSubcriptionData = data => {
    return({
        type: 'SET_SUBSCRIPTION_DATA',
        data
    })
}

/** @todo remove the default googleAnalyticsId, for testing only */
const INITIAL_STATE = {
    id: null,
    username: null,
    email: null,
    filteredViewId: null,
    viewId: 'null',
    isThirdPartyAuthUser: false,
    routeIdentifier: null,
    firebaseToken: null,
    subscriptionData: {}
}

/**@todo storing firebase token may not be necessary */
const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_USER':
            const newState = {
                ...state,
                ...action.data,
                viewId: `${action.data.viewId}`,
                filteredViewId: `${action.data.filteredViewId}`
            }
            return newState
        case 'SET_IS_THIRD_PARTY_AUTH_USER':
            return { ...state, isThirdPartyAuthUser: action.data }
        case 'SET_FIREBASE_TOKEN':
            return { ...state, firebaseToken: action.data }
        case 'RESET_STATE':
            return { INITIAL_STATE }
        default:
            return state
    }
}

export default userReducer