import { React } from 'react';
import Dropzone from 'react-dropzone-uploader'

export const FileUploadField = ({ handleSubmit, width }) => {
  return (
    <Dropzone
      className='upload-zone'
      id='drop-zone'
      test-id='drop-zone'
      data-cy="drag-and-drop"
      //Limit file size to 1mb
      maxSizeBytes={1024 * 1024}
      accept="
          .doc,
          .docx,
          text/plain,
          application/msword,
          application/vnd.openxmlformats-officedocument.wordprocessing,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/vnd.openxmlformats-officedocument.wordprocessingml.d,
          application/rtf,
          application/wordperfect,
          application/vnd.oasis.opendocument.text,
          application/x-iwork-pages-sffpages,
          application/vnd.apple.pages
          text/html
          "
      onSubmit={handleSubmit}
      inputContent={(files, extra) => (extra.reject ? 'Text files only' : (<span><span style={{ color: 'var(--font-label-color)', opacity: '50%' }}>Drag a File or Click to Browse (txt, doc)</span> <b style={{ color: 'var(--font-label-color)' }}>no pdf</b></span>))}
      maxFiles={1}
      styles={{
        dropzone: {
          minHeight: 200,
          maxHeight: 250,
          // maxWidth: '60%',
          marginRight: '100%',
          marginTop: '5%',
          backgroundColor: 'white',
          borderRadius: '5px',
          borderWidth: '5px',
          overflow: 'auto',
          color: 'red',
          width: width || '100%'
        }
      }}
    >

      {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
        const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > 1;
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {!isDragActive && 'Click here or drop a file to upload!'}
            {isDragActive && !isDragReject && "Drop it like it's hot!"}
            {isDragReject && "File type not accepted, sorry!"}
            {isFileTooLarge && (
              <div className="text-danger mt-2">
                File is too large.
              </div>
            )}
          </div>
        )
      }
      }
    </Dropzone>
  );
};

export default FileUploadField;