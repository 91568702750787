export const createChartFilter = ({ usePublication, useDate, useLocalTime, lastDaysAmount, routeIdentifier }) => {
  const filter = {};
  const dateFilter = {
    $gte: {
      $date: new Date(
        new Date() - (lastDaysAmount || 20000) * 24 * 60 * 60 * 1000
      ).getTime(),
    },
  }

  usePublication && (filter.publication = routeIdentifier);
  !usePublication && (filter.publisher = routeIdentifier);
  useDate && (filter.date = dateFilter);
  useLocalTime && (filter.userLocalTime = dateFilter);
  
  return filter;
}