import './ProductButton.css'
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import Spinner from '../../../../UI/Spinner/Spinner'
import { ADVERTISING_TIER_SUBSCRIPTION, STARTER_TIER_SUBSCRIPTION, BUSINESS_TIER_SUBSCRIPTION, ENTERPRISE_TIER_SUBSCRIPTION } from '../../../../../constants/subscription-constants'
import { COLORS } from '../../../../../constants/theme';
import axios from 'axios'

const ProductButton = ({ tier, disabled }) => {
    const [showSpinner, setShowSpinner] = useState(false)
    const [link, setLink] = useState('')
    const userCurrentTier = useSelector(state => state.subscription.tier);
    const user = useSelector(state => state.user)
    let text = "subscribe"

    const onClick = async (event) => {
        setShowSpinner(true)
        try {
            await axios.post('/payment/attempt', { user });
        } catch (err) {
            setShowSpinner(false)
            console.log(err)
        }
    }

    let button = <btn className="product-button">{text}</btn>;
    //userCurrentTier may be undefined if a user just signed up (first login, customer doc might not be updated yet)
    if (userCurrentTier) {
        if (userCurrentTier.includes('corporate')) {
            button = <btn className="product-button">{text}</btn>;
        }
    }
    let items = [];
    useEffect(() => {

        if (tier === "Advertising") {
            items = ADVERTISING_TIER_SUBSCRIPTION
            setLink('https://buy.stripe.com/cN23dVbk71w46UE28e')
        }

        if (tier === "Starter") {
            items = STARTER_TIER_SUBSCRIPTION
            setLink('https://buy.stripe.com/dR68yfbk7a2Adj2147')
        }
        if (tier === "Business") {
            items = BUSINESS_TIER_SUBSCRIPTION
            setLink('https://buy.stripe.com/5kA4hZbk7gqY2Eo5km')
        }
        if (tier === "Enterprise") {
            items = ENTERPRISE_TIER_SUBSCRIPTION
            setLink('https://buy.stripe.com/9AQg0H5ZNb6E3Is7sx')
        }
    }, [tier])

    return (
        <a onClick={onClick} href={link} className="product-button-positioner stripe-link">
            {showSpinner && <Spinner overrideColor={COLORS.contrastPrimary} />}
            {!showSpinner && disabled && button}
            {!disabled && <btn className="product-button-disabled">Current Tier</btn>}
        </a>
    );
};

export default ProductButton;
