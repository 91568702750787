const {upArrow, downArrow} = require("./percentArrows.json");

const PercentChange = ({ change, days }) => {
  const changeColor = change.startsWith("+") ? "MediumSeaGreen" : "Crimson";
  const arrow = change.startsWith("+") ? upArrow : downArrow;

  return (
    <p className="countPercentChange">
      <b>
        <span
          style={{
            color: changeColor,
          }}
        >
          {change + " "}
        </span>
        in last {days} days
      </b>
      <div className="percentArrowBox">
        <svg viewBox="0 0 24 24">
          <path fill={changeColor} d={arrow} />
        </svg>
      </div>
    </p>
  );
};

export default PercentChange;
