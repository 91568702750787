import React, { useEffect, useState } from 'react';
import { TextField, Grid, makeStyles, Paper, Button } from '@material-ui/core';
import axios from "axios"
import TierCard from '../PricingModal/TierCard/TierCard'
const useStyle = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        backgroundColor: 'rgba(131, 221, 53, 0.75)',
        borderRadius: '20px'
    }
}))


const ProductInfo = ({ discount, selectedProduct, price, coupon, setCoupon, attemptingPayment }) => {
    const [total, setTotal] = useState(null)
    const [couponCode, setCouponCode] = useState('')
    const [waitingForCoupon, setWaitingForCoupon] = useState(false)
    const [couponErrorMessage, setCouponErrorMessage] = useState(null)
    const classes = useStyle()
    const applyCoupon = () => {
        setWaitingForCoupon(true)
        setCouponErrorMessage(null)
        axios.post("/payment/discount", {
            tier: selectedProduct,
            couponCode
        }).then(res => {
            console.log(res.data)
            setCoupon(res.data)
            setWaitingForCoupon(false)

        }).catch(err => {
            setCoupon(null)
            console.log("Could not apply coupon code")
            setWaitingForCoupon(false)
            setCouponErrorMessage("Sorry your coupon was invalid")
        })
    }

    const calculateTotal = (price, coupon) => {
        if (coupon && coupon.percent_off) {
            return (price * (100 - coupon.percent_off) / 100).toFixed(2)
        } else {
            return price
        }
    }
    useEffect(() => {
        setTotal(calculateTotal(price, coupon))
    }, [coupon, price])

    return (
        <Paper className={classes.pageContent}>
            <div className="checkout-content">
                <h1>Subscribing To {selectedProduct} Tier</h1>
                <TierCard tier={selectedProduct} displayOnly />
                {!price && <h1>Calculating your total</h1>}
                {price && <h1>${price} per month</h1>}
                {price && <h1>Total due today ${total}</h1>}
                <div className="coupon-section">
                    {coupon && <h2 className="coupon-message percent-off">{coupon.percent_off}% Off Coupon Applied</h2>}
                    {couponErrorMessage && <h2 className="coupon-message">{couponErrorMessage}</h2>}
                    {waitingForCoupon && <h2 className="coupon-message">checking coupon...</h2>}
                    <div className="message-spacer"></div>
                    <div className="discount">
                        <TextField
                            variant="outlined"
                            label="Coupon Code"
                            name="coupon"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                        />
                        {/* <Button color="primary" variant="contained" onClick={applyCoupon} >Apply Coupon</Button> */}
                        <button className="coupon-button" onClick={applyCoupon}>APPLY COUPON</button>
                    </div>
                </div>
            </div>
        </Paper>

    );
};

export default ProductInfo;