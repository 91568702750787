import React from "react";

const Message = () => {
	return (
		<div>
			<u>
				<b>Quartile </b>
			</u>
			is a measure of relative performance to other publications on the Ad Auris
			platform.
			<br></br>
			<i>
				<b>Top quartile: </b>
			</i>
			You're in the highest 25% of publications!
			<br></br>
			<i>
				<b>2nd quartile: </b>
			</i>
			You're better than 50% of publications!
			<br></br>
			<i>
				<b>3rd quartile: </b>
			</i>
			You're better than 25% of publications!
			<br></br>
			<i>
				<b>4th quartile: </b>
			</i>
			Like a phoenix, you will soon rise!
		</div>
	);
};

export default Message;
