import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import "./CustomTooltip.css";

const CustomTooltip = ({ message, link, placement }) => {
	return (
		<Tooltip title={message} placement={placement}>
			<IconButton
				size="small"
				className="help-icon"
				href={link}
				target="_blank"
				rel="noopener noreferrer"
			>
				<HelpOutlineIcon />
			</IconButton>
		</Tooltip>
	);
};

export default CustomTooltip;
