import React, { useState, useEffect /*, useRef, useCallback*/ } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./Dashboard.css";
import ProfessionalEditing from "./ProfessionalEditing/ProfessionalEditing";
// import InstantFileUpload from './InstantFileUpload/InstantFileUpload';
// import UrlUpload from './UrlUpload/UrlUpload';
import FileList from "./FileList/FileList";
// import UploadMenu from './UploadMenu/UploadMenu.js';
import FileInfo from "./FileInfo/FileInfo";
import RssFeedInsert from "./RssFeedInsert/RssFeedInsert";
import RssFeeds from "./RssFeeds/RssFeeds";
import RssView from "./RssView/RssView";
import NewPodcast from "./NewPodcast/NewPodcast";
import PodcastDetails from "./PodcastDetails/PodcastDetails";
import SideBar from "./Sidebar/SideBar";
import Settings from "./Settings/Settings";
import HomePage from "./HomePage/HomePage";
import Analytics from "./Analytics/Analytics";
import InstantNarration from "./InstantNarration/InstantNarration";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import backendService, {
  getTTSP,
  getRSS,
  getSingleRSS,
  getCategories
} from "../../../services/backend-service";
import {
  setPackages,
  setRss,
  setPodcast,
  setCurrentRss,
  setCategories
} from "../../../reducers/analytics";
import { clearAnalyticsState } from "../../../reducers/analytics";
import { clearUserState } from "../../../reducers/user";
import {
  setSubscriptionData,
  clearSubscriptionData,
} from "../../../reducers/subscription";
import { useHistory } from "react-router-dom";
import dashBoardStyles from "../../../constants/dashBoardStyles";
import ActionBar from "./ActionBar/ActionBar";
import InviteModal from "./InviteModal/InviteModal";
import VideoModal from "./VideoModal/VideoModal";
import SampleNarrations from "./SampleNarrations/SampleNarrations";
import { firebase } from "../../../services/firebase-service";
import PricingModal from "./PricingModal/PricingModal";
import PopupModal from "../../UI/PopupModal/PopupModal";
import { getSubscriptionData } from "../../../services/subscription-service";
import CheckoutPage from "./Checkout/CheckoutPage";
import { logAction } from "../../../services/logging-service"
/* Filename: Dashboard.js
 *
 * - Provides a sidebar and displays either analytics or the file upload widget
 * - Handles logout logic for the app
 * - Queries backend for user ttsps on render
 *
 */

export default function Dashboard() {
  const [showVideo, setShowVideo] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = dashBoardStyles();
  const [mode, setMode] = useState("showHomePage");
  const iframe =
    '<iframe style="width: 1450px; height: 1000px; margin-top: -130px;" title="fx." src="https://www.ad-auris.com/how-it-works" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>';
  const user = useSelector((state) => state.user);
  const subscription = useSelector((state) => state.subscription);
  const organisation = useSelector((state) => state.organisation);
  const [rssWarningOpen, setRssWarningOpen] = useState(false);
  const [podcastApologyOpen, setPodcastApologyOpen] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const subData = await getSubscriptionData();
          console.log("subData.subscriptionData");
          console.log(subData.subscriptionData);
          dispatch(setSubscriptionData(subData.subscriptionData));
          const userPackages = await getTTSP(user.id, user.organisationId);
          dispatch(setPackages(userPackages));
          const rssPackages = await getRSS(user.organisationId);
          dispatch(setRss(rssPackages));
          // const categoryPackages = await getCategories(user.organisationId);
          dispatch(setCategories(categoryPackages));
          const podcast = await backendService.checkPodcast({
            routeIdentifier: user.routeIdentifier,
          });
          dispatch(setPodcast(podcast));
        } catch (err) {
          console.log(err);
        }
      } else {
        history.push("/login");
      }
    });
  }, []);

  //On first signup, sometimes firebase subscription data isn't ready yet. Delay 2 seconds and check again
  useEffect(() => {
    const timeout = setTimeout(async () => {
      console.log("Updating Subscription");
      try {
        const subData = await getSubscriptionData();
        dispatch(setSubscriptionData(subData.subscriptionData));
      } catch (err) {
        console.log(err);
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const handleLogout = () => {
    console.log("logging out!");
    dispatch(clearAnalyticsState());
    dispatch(clearUserState());
    dispatch(clearSubscriptionData());
    history.push("/");
  };

  const backNavigateFromRSSInsert = () => setMode("showRss");
  const onProfessionalButtonClicked = () => setMode("showProfessional");
  const onSettingsButtonClicked = () => setMode("showSettings");
  const onUrlButtonClicked = () => setMode("showUrl");
  const onInstantButtonClicked = () => setMode("showInstant");
  const onHomeButtonClicked = () => setMode("showHomePage");
  const onInstantNarrationClicked = () => setMode("showInstantNarration");
  const onAggregateAnalyticsClicked = () => setMode("showAggregateAnalytics");
  const onSamplesButtonClicked = () => setMode("showSampleNarrations");

  const onPodcastClicked = async () => {
    // if (!subscription.podcast_active) {
    //   setPodcastApologyOpen(true);
    //   return;
    // }
    try {
      const podcast = await backendService.checkPodcast({
        routeIdentifier: user.routeIdentifier,
      });

      if (podcast.id === null || podcast.id === undefined || podcast === null) {
        setMode("showNewPodcast");
      } else {
        dispatch(setPodcast(podcast));
        setMode("showPodcast");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onNewPodcastClicked = () => {
    if (subscription.podcast_active) {
      setMode("showNewPodcast");
    } else {
      setPodcastApologyOpen(true);
    }
  };

  const onRssClicked = async (event) => {
    // if (subscription.rss_active) {
      try {
        const rssPackages = await getRSS(user.organisationId);
        dispatch(setRss(rssPackages));
      } catch (error) {
        console.log(error.message);
      }
      setMode("showRss");
    // } else {
    //   setRssWarningOpen(true);
    // }
  };

  const onIndividualRssClicked = async (rssId, feed) => {
    try {
      const rssPackage = await getSingleRSS(rssId);
      const categories = await getCategories(user.organisationId, rssId);
      dispatch(setCategories(categories));
      dispatch(setPackages(rssPackage));
      dispatch(setCurrentRss(feed));
    } catch (error) {
      console.log(error.message);
    }

    setMode("showRssView");
    return true;
  };

  const onNewRssClicked = () => {
    // if (subscription.rss_active) {
      setMode("showNewRss");
    // } else {
    //   setRssWarningOpen(true);
    // }
  };

  const onAnalyticsClicked = () => {
    setMode("showAnalytics");
    logAction({ ...user, page: 'dashboard', activityType: "showAnalytics" })
  };

  const onFilesButtonClicked = async () => {
    try {
      const podcast = await backendService.checkPodcast({
        routeIdentifier: user.routeIdentifier,
      });
      console.log(podcast.id);
      if (podcast.id !== null) {
        dispatch(setPodcast(podcast));
      }
      const userPackages = await getTTSP(user.id, user.organisationId);
      dispatch(setPackages(userPackages));
    } catch (error) {
      console.log(error.message);
    }
    setMode("showFiles");
    return true;
  };

  return (
    <div id="dashboard" className={classes.root}>
      <PopupModal
        open={rssWarningOpen}
        status="warning"
        warningContent={{
          title: "Sorry, auto-narrate with RSS is a paid feature",
          body: (
            <div>
              <p>
                Setting up auto-narrate lets audio creation be a one-step
                process!
              </p>
              <button
                className="upgrade-button"
                onClick={() => setShowPricing(true)}
              >
                Upgrade
              </button>
            </div>
          ),
        }}
        handleClose={() => {
          setRssWarningOpen(false);
        }}
      />

      <PopupModal
        open={podcastApologyOpen}
        status="warning"
        warningContent={{
          title: "Sorry, Podcasting is a paid feature",
          body: (
            <div>
              <p>
                Grow your audience by distributing your audio on platforms like
                Spotify and Apple Podcasts.{" "}
              </p>
              <button
                className="upgrade-button"
                onClick={() => setShowPricing(true)}
              >
                Upgrade
              </button>
            </div>
          ),
        }}
        handleClose={() => {
          setPodcastApologyOpen(false);
        }}
      />
      <ActionBar
        handleLogout={handleLogout}
        setShowVideo={setShowVideo}
        onSettingsButtonClicked={onSettingsButtonClicked}
        onLogoClicked={onHomeButtonClicked}
      />
      <CssBaseline />
      <SideBar
        mode={mode}
        onProfessionalButtonClicked={onProfessionalButtonClicked}
        onAggregateAnalyticsClicked={onAggregateAnalyticsClicked}
        onFilesButtonClicked={onFilesButtonClicked}
        onInstantButtonClicked={onInstantButtonClicked}
        onUploadMenuButtonClicked={onInstantNarrationClicked}
        onSettingsClicked={onSettingsButtonClicked}
        onHomeClicked={onHomeButtonClicked}
        onAnalyticsClicked={onAnalyticsClicked}
      />
      <div className="dashboard-content-container">
        <PricingModal
          setSelectedProduct={setSelectedProduct}
          open={showPricing}
          setOpen={setShowPricing}
          setDashboardMode={setMode}
        />
        <InviteModal
          setShowInvite={setShowInvite}
          showInvite={showInvite}
          joinCode={user.joinCode}
        />
        <VideoModal
          showVideo={showVideo}
          setShowVideo={setShowVideo}
          videoId={"jWdMAWLXdaY"}
        />
        {/* {mode === 'showUploadMenu' &&
          <UploadMenu onTextButtonClicked={onInstantButtonClicked} onUrlButtonClicked={onUrlButtonClicked} />
        }
        {mode === 'showUrl' &&
          <UrlUpload redirectOnUpload={onFilesButtonClicked} />
        }
        {mode === 'showInstant' &&
          <InstantFileUpload redirectOnUpload={onFilesButtonClicked} />
        } */}
        {mode === "showProfessional" && (
          <ProfessionalEditing onHomeClicked={onHomeButtonClicked} />
        )}
        {mode === "showFiles" && (
          <FileList
            setMode={setMode}
            onNarrationsClicked={onFilesButtonClicked}
            onHomeClicked={onHomeButtonClicked}
            onNewNarration={onInstantNarrationClicked}
          />
        )}
        {mode === "showAudio" && (
          <FileInfo
            onNewPodcastClicked={onNewPodcastClicked}
            onHomeClicked={onHomeButtonClicked}
            onMyNarrationsClicked={onFilesButtonClicked}
            setShowPricing={setShowPricing}
          />
        )}
        {mode === "showSampleNarrations" && (
          <SampleNarrations
            iframe={iframe}
            onSamplesButtonClicked={onSamplesButtonClicked}
          />
        )}
        {mode === "showNewRss" && (
          <RssFeedInsert
            backNavigateFromRSSInsert={backNavigateFromRSSInsert}
            onHomeClicked={onHomeButtonClicked}
            onSettingsClicked={onSettingsButtonClicked}
            onRssClicked={onRssClicked}
          />
        )}
        {mode === "showRss" && (
          <RssFeeds
            onNewRssClicked={onNewRssClicked}
            onIndividualRssClicked={onIndividualRssClicked}
            onRssClicked={onRssClicked}
            onSettingsClicked={onSettingsButtonClicked}
            onHomeClicked={onHomeButtonClicked}
          />
        )}
        {mode === "showRssView" && (
          <RssView
            onNewRssClicked={onNewRssClicked}
            onIndividualRssClicked={onIndividualRssClicked}
            onRssClicked={onRssClicked}
            setMode={setMode}
            onNarrationsClicked={onFilesButtonClicked}
            onSettingsClicked={onSettingsButtonClicked}
            onHomeClicked={onHomeButtonClicked}
          />)}
        {mode === 'showPodcast' && (
          <PodcastDetails
            onEditClicked={onNewPodcastClicked}
            onPodcastClicked={onPodcastClicked}
            onHomeClicked={onHomeButtonClicked}
            onSettingsClicked={onSettingsButtonClicked}
          />)}
        {mode === 'showNewPodcast' && (
          <NewPodcast
            onPodcastClicked={onPodcastClicked}
            onHomeClicked={onHomeButtonClicked}
            onSettingsClicked={onSettingsButtonClicked}
          />
        )}
        {mode === "showSettings" && (
          <Settings
            onNarrationClicked={onFilesButtonClicked}
            onPricingClicked={() => setShowPricing(true)}
            onPodcastClicked={onPodcastClicked}
            onRssClicked={onRssClicked}
            onHomeClicked={onHomeButtonClicked}
            onNewRssClicked={onNewRssClicked}
            setDashboardMode={setMode}
          />
        )}
        {mode === "showHomePage" && (
          <HomePage
            setMode={setMode}
            onNarrationClicked={onFilesButtonClicked}
            onNewNarration={onInstantNarrationClicked}
            onPodcastClicked={onNewPodcastClicked}
            onRssClicked={onRssClicked}
          />
        )}
        {mode === "showInstantNarration" && (
          <InstantNarration
            setShowPricing={setShowPricing}
            onHomeClicked={onHomeButtonClicked}
          />
        )}
        {mode === "showCheckout" && (
          <CheckoutPage selectedProduct={selectedProduct} />
        )}
        {mode === "showAnalytics" && (
          <Analytics
            readableName={organisation}
            user={user}
            onHomeClicked={onHomeButtonClicked}
          />
        )}
      </div>
    </div>
  );
}
