/** @description - firebas-config.js
 *
 *  - Apparently no issue with exposing this config information client side. Standard practice to include it in html script tag.
 *
 *  - Config information for firebase authentication
 *
 *  @author Keith Ong
 */

const config = {
  apiKey: "AIzaSyCzdSHETxm3V44Remxj9j663Tl-JdUVFLQ",
  authDomain: "ad-auris-tts-app.firebaseapp.com",
  databaseURL: "https://ad-auris-tts-app.firebaseio.com",
  projectId: "ad-auris-tts-app",
  storageBucket: "ad-auris-tts-app.appspot.com",
  messagingSenderId: "557045542338",
  appId: "1:557045542338:web:6a76ed21f0955dc1007a5b",
  measurementId: "G-CKMQFPT44X",
};

export default config;
