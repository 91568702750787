import React from "react";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import AutoCompleteField from "../AutoCompleteField/AutoCompleteField";
import TextField from "@material-ui/core/TextField";
import AudioSample from "../AudioSample/AudioSample";
import "./MusicSelector.css";
const CUSTOM_MUSIC_TYPES = [
  { name: "Default Track" },
  { name: "Cheerful" },
  { name: "Melancholy" },
  { name: "Calm" },
  { name: "Curious" },
  { name: "Dramatic" },
  { name: "Ominous" },
];

const INSTANT_MUSIC_TYPES = [{ name: "Default Track" }];

const MusicSelector = ({ narrationTier, music, setMusic, large, width }) => {
  let size = width;
  if (large) {
    size = 600;
  }
  return (
    <div className="input-with-sample audio-select">
      <AutoCompleteField
        value={music}
        setValue={setMusic}
        label="Music"
        options={
          narrationTier == "custom" ? CUSTOM_MUSIC_TYPES : INSTANT_MUSIC_TYPES
        }
        width={size}
      />
      <AudioSample audioName={music} />
    </div>
  );
};

export default MusicSelector;
