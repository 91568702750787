import axios from 'axios'
const SESSION_LOGGING_URL = 'https://ad-auris-logging-db-l72twop3ra-uc.a.run.app/dashboard-telemetry/record-session'
const ACTION_LOGGIN_URL = 'https://ad-auris-logging-db-l72twop3ra-uc.a.run.app/dashboard-telemetry/record-action'

export const logSession = async (userInfo) => {
    try {
        const payload = {
            publication: userInfo.organisation,
            email: userInfo.email,
            sessionId: userInfo.sessionId
        }
        await axios.post(SESSION_LOGGING_URL, payload)
    } catch (err) {
        console.log(err)
    }
}

export const logAction = async (actionInfo) => {
    try {
        const payload = {
            sessionId: actionInfo.sessionId,
            activityType: actionInfo.activityType,
            page: actionInfo.page
        }
        await axios.post(ACTION_LOGGIN_URL, payload)
    } catch (err) {
        console.log(err)
    }
}