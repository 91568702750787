import React from 'react'
import './App.css';
import './AppTheme.css';
import './AppThemeBreakpoints.css';
import HomePage from './components/Pages/HomePage/HomePage'
import DashBoard from './components/Pages/Dashboard/Dashboard'
import LoginPage from './components/Pages/Login/LoginPage'
import SignupPage from './components/Pages/Signup/SignupPage'
import Navbar from './components/Navbar/Navbar'
import VerifyPage from './components/Pages/Verify/VerifyPage'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import "firebase/auth";

/* Filename: App.js
 * Description: Top level component of the frontend, manages user state (logged in or not) and passes state down to its children 
 * Children: Navbar and the different pages of the website
 * Props: none
*/

function App(props) {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Navbar />
            <HomePage />
          </Route>
          <Route path="/dashboard">
            <DashBoard />
          </Route>
          <Route path="/login">
            <Navbar />
            <LoginPage />
          </Route>
          <Route path="/signup">
            <Navbar />
            <SignupPage />
          </Route>
          <Route path="/verify">
            <Navbar />
            <VerifyPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}
export default App;
