// Description: Users can preview sample narrations from ad-auris.com

import React from "react";
import "./SampleNarrations.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	iframeContainer: {
		justifyContent: "center",
		width: "1500px",
		height: "900px",
	},
}));

export default function SampleNarrations(props) {
	const classes = useStyles();
	return (
		<div className={classes.iframeContainer}>
			<div
				dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
			/>
		</div>
	);
}
