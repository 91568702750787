const INITIAL_STATE = {
    subscriptionData: null
}

export const setSubscriptionData = data => {
    return ({
        type: 'SET_SUBSCRIPTION_DATA',
        data
    })
}
export const clearSubscriptionData = () => {
    return ({
        type: 'RESET_STATE'
    })
}

const subscriptionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_SUBSCRIPTION_DATA':
            const newState = {
                ...action.data,
            }
            return newState
        case 'RESET_STATE':
            return { INITIAL_STATE }
        default:
            return state
    }
}
export default subscriptionReducer