import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js"
import React from 'react';
export const PUBLISABLE_STRIPE_KEY = 'pk_live_51HjDpWJwn4ANsYedJt5h4FDFBDUXZGYlwU3MjvYdcnEv1Z7MpmNVABIHDyrU3yqf0NqcvONvzxA1CPA20tcR6xvS002p0FNVab'
export const PUBLISABLE_STRIPE_KEY_TEST = 'pk_test_51HjDpWJwn4ANsYed1aswzLhRIK39WPSpkmh2wFlOgTi7pQ5A0Du87dsTL7p8GuRkwbPx9aF2kTQV06kXo9E6rNdo00VeALYtby'
const stripePromise = loadStripe(PUBLISABLE_STRIPE_KEY)
import './Stripe.css'
import PaymentForm from './PaymentForm'

const StripeContainer = ({ selectedProduct, setDiscount, setPrice, setCoupon, coupon, attemptingPayment, setAttemptingPayment
}) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentForm
                attemptingPayment={attemptingPayment}
                setAttemptingPayment={setAttemptingPayment}
                coupon={coupon}
                setDiscount={setDiscount}
                setPrice={setPrice}
                selectedProduct={selectedProduct}
                setCoupon={setCoupon}
            />
        </Elements>
    );
};

export default StripeContainer;