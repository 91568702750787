import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { styled } from "@mui/material/styles";

const StyledAutocomplete = styled(Autocomplete)({
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "#76ff03",
	},
});

const AutoCompleteField = ({ value, setValue, width, options, label }) => {
	return (
		<StyledAutocomplete
			className="dropDown"
			onChange={(e, value) => (value ? setValue(value.name) : setValue(""))}
			options={options}
			getOptionLabel={(option) => option.name}
			style={{ width }}
			inputValue={value}
			value={value}
			renderInput={(params) => (
				<TextField {...params} placeholder={label} variant="outlined" />
			)}
		/>
	);
};

export default AutoCompleteField;
