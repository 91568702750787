import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { grey, blue, red } from "@mui/material/colors";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";

const theme = createTheme({
	palette: {
		primary: {
			main: grey[500],
		},
		secondary: {
			main: "#76ff03",
		},
	},
});

const buttons = [];

export default function GroupSizesColors({ days, setDays }) {
	return (
		<ThemeProvider theme={theme}>
			<ButtonGroup size="big">
				<Button
					sx={{
						color: days == null ? "secondary.main" : "primary.main",
						fontWeight: "bold",
					}}
					key="all-time"
					onClick={() => {
						setDays(null);
					}}
				>
					ALL TIME
				</Button>
				<Button
					sx={{
						color: days == 90 ? "secondary.main" : "primary.main",
						fontWeight: "bold",
					}}
					key="90-days"
					onClick={() => {
						setDays(90);
					}}
				>
					90 DAYS
				</Button>
				<Button
					sx={{
						color: days == 30 ? "secondary.main" : "primary.main",
						fontWeight: "bold",
					}}
					key="30-days"
					onClick={() => {
						setDays(30);
					}}
				>
					30 DAYS
				</Button>
				<Button
					sx={{
						color: days == 7 ? "secondary.main" : "primary.main",
						fontWeight: "bold",
					}}
					key="7-days"
					onClick={() => {
						setDays(7);
					}}
				>
					7 DAYS
				</Button>
			</ButtonGroup>
		</ThemeProvider>
	);
}
