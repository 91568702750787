import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar'
import { useHistory } from 'react-router-dom'
import {firebase } from '../../../services/firebase-service'
import { useSelector } from 'react-redux'
import backendService from '../../../services/backend-service'
import { useDispatch } from 'react-redux'
import { setUser } from '../../../reducers/user'
import Spinner from '../../UI/Spinner/Spinner'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { COLORS } from '../../../constants/theme'
import { getSubscriptionData } from '../../../services/subscription-service'
import { setSubscriptionData } from '../../../reducers/subscription'

export default function SignupPage() {
  let history = useHistory()
  const isThirdPartyAuthUser = useSelector(state => state.user.isThirdPartyAuthUser)
  const firebaseToken = useSelector(state => state.user.firebaseToken)
  const [orgCode, setOrgCode] = useState('')
  const [orgName, setOrgName] = useState('')
  const [publicationUrl, setPublicationUrl] = useState('')
  const [name, setName] = useState(isThirdPartyAuthUser ? firebase.auth().currentUser.displayName : '')
  const [email, setEmail] = useState(isThirdPartyAuthUser ? firebase.auth().currentUser.email : '')
  const [errorMessage, setErrorMessage] = useState('')
  const [showSpinner, setShowSpinner] = useState(false)
  // const [loginProvider, setLoginProvider] = useState(isThirdPartyAuthUser ? firebase.auth().currentUser.providerData[0].providerId : '')
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    formContainer: {
      marginTop: '10%',
      marginBottom: '30px'
    },
    title: {
      fontWeight: 'bold',
      color: '#64E43C',
      fontSize: '50px'
    },
    textField: {
      width: '400px',
      marginLeft: '20px',
      marginRight: '20px',
      marginBottom: '20px',
      paddingBottom: 0,
      marginTop: '9px',
      fontWeight: 5200,
      color: 'red !important'
    },
    button: {
      width: '100px',
      margin: '30px',
      backgroundColor: '#64E43C',
      color: 'white',
      fontWeight: 'bolder',
      fontSize: '1em',
      borderRadius: '20px',
    },
    altButton: {
      backgroundColor: 'transparent',
      width: '100px',
      margin: '30px',
      color: 'black',
      fontWeight: 'bolder',
      fontSize: '1em',
      borderRadius: '20px',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: "column"
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      }
    },
    cssFocused: {},
    notchedOutline: {
      borderRadius: '0',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderColor: COLORS.primary + '!important',
    },
  }));

  const classes = useStyles();

  const handleBack = () => {
    history.push('/login')
  }

  const handleSignup = async () => {
    if (!name) {
      setErrorMessage('Please enter your user name')
      return
    } else if (!email) {
      setErrorMessage('Please enter your email')
      return
    } else if (!orgName) {
      setErrorMessage('Please enter your organization name')
      return
    }
    setShowSpinner(true)

    if(orgCode.length > 0){
      try{
        const organization = await backendService.checkIfOrgExists(orgCode)
        if (!organization) {
          setErrorMessage('Invalid Organization Code')   
          setShowSpinner(false)
          return
        }
      }catch(err){
        setErrorMessage(err.response.data)
        setShowSpinner(false)
        return
      }
    }

    const userData = {
      joinCode: orgCode,
      username: name,
      orgName: orgName,
      token: firebaseToken,
    }

    userData.email = firebase.auth().currentUser.email
    try {
      const signUpResponse = await backendService.signup(userData)
      dispatch(setUser(signUpResponse.data))
      const subData = await getSubscriptionData();
      dispatch(setSubscriptionData(subData.subscriptionData))
      history.push('/dashboard')
      /**@todo login and redirect to dashboard */
    } catch (error) {
      setShowSpinner(false)
      setErrorMessage(error.response.data)
    }
  }


  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.formContainer}>
        {errorMessage ? (<AppBar className='error-message-bar' style={{ background: 'red' }}>{errorMessage}</AppBar>) : null}
        <h2
          className={classes.title}
        >Create an account</h2>
        {showSpinner && <Spinner />}
        <div className={classes.inputContainer}>
          <div>
            <TextField
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              className={classes.textField}
              label="Publication Name"
              name="org name"
              autoFocus
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            <TextField
              value={publicationUrl}
              onChange={(e) => setPublicationUrl(e.target.value)}
              variant="outlined"
              margin="normal"
              className={classes.textField}
              label="Publication URL (optional)"
              name="publication url"
              autoFocus
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </div>
          <div>
            <TextField
              disabled={isThirdPartyAuthUser}
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              className={classes.textField}
              label="First & last name"
              name="name"
              autoFocus
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            <TextField
              disabled={isThirdPartyAuthUser}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              className={classes.textField}
              label="Email"
              name="email"
              autoFocus
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </div>
          <div>
            <TextField
              value={orgCode}
              onChange={(e) => setOrgCode(e.target.value.trim())}
              variant="outlined"
              margin="normal"
              className={classes.textField}
              label="Join Publication Code (optional)"
              name="email"
              autoFocus
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </div>
        </div>
      </div>
      <Button
        onClick={handleBack}
        className={classes.altButton}
        variant="contained" color='primary'>
        Back
              </Button>
      <Button
        onClick={handleSignup}
        className={classes.button}
        variant="contained" color='primary'>
        Sign Up
              </Button>
    </React.Fragment>
  );
}

