import { React } from 'react';
import TextField from '@material-ui/core/TextField';

export const TextFieldComponent = ({
  value,
  setValue,
  width,
  height,
  label,
  disabled,
}) => {
  return (
    <TextField
      style={{ width, border: '3px solid #e2e2e1', borderRadius: '5px', height }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      variant="outlined"
      margin="normal"
      required
      fullWidth
      disabled={disabled}
      className='textField'
      label={label}
      autoFocus
      test-id='upload-url-input'
      InputLabelProps={{
        style: {
          height: '100%',
          fontSize: '1em'
        }
      }}
      InputProps={{
        classes: {
          root: 'cssOutlinedInput',
          focused: 'cssFocused',
          notchedOutline: 'notchedOutline',
        },
        style: {
          height: '100%',
          fontSize: '1em'
        }
      }}
    />
  );
};

export default TextFieldComponent;