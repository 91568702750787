import * as React from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#76ff03",
		},
	},
});

export default function UseFormControl({ preFilledText, value, setValue }) {
	return (
		<ThemeProvider theme={theme}>
			<Box component="form" Validate autoComplete="off">
				<FormControl sx={{ width: 500 }}>
					<OutlinedInput placeholder={preFilledText} value={value} onChange={(e) => { setValue(e.target.value) }} color="primary" focused />
				</FormControl>
			</Box>
		</ThemeProvider>
	);
}
