import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {firebase}  from '../../../services/firebase-service';
import { useHistory } from 'react-router-dom'
import Spinner from '../../UI/Spinner/Spinner'
import AppBar from '@material-ui/core/AppBar'
import { COLORS } from '../../../constants/theme'
import './VerifyPage.css'
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © Ad Auris '}
      {new Date().getFullYear()}
      {''}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '95vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: 'theme.spacing(2)',
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  verifyTitle: {
    color: COLORS.primary
  },
  sendingTitle: {
    color: COLORS.primary
  },
  awaitingTitle: {
    color: COLORS.primary
  }
}));

export default function HomePage() {
  const classes = useStyles();
  let history = useHistory()
  const [emailSent, setEmailSent] = useState(false)
  const [sendingEmail, setSendingEmail] = useState(false)
  const [appBarMessage, setAppBarMessage] = useState('')

  const verifyEmail = () => {
    var user = firebase.auth().currentUser
    setSendingEmail(true)
    user.sendEmailVerification().then(function () {
      setAppBarMessage(`Email sent to ${user.email}! Please check your spam`)
      setSendingEmail(false)
      setEmailSent(true)
      // Email sent.
    }).catch(function (error) {
      console.log(error)
      setSendingEmail(false)
      setAppBarMessage(`Too many email requests, try again later or check your inbox`)
      setEmailSent(true)
      // An error happened.
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      firebase.auth().currentUser.reload()
      if (firebase.auth().currentUser.emailVerified) {
        console.log("Email Verified!");
        history.push('/signup')
      }
    }, 2000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      {appBarMessage ? (<AppBar className='error-message-bar' style={{ background: 'green' }}>{appBarMessage}</AppBar>) : null}
      <CssBaseline />
      <div id='homepage-content-container'>
        <div id='homepage-text'>
          <Container component="main" className={classes.main} maxWidth="md">
            <Typography className={classes.verifyTitle} variant="h2" component="h1" gutterBottom>
            Please verify your email by clicking below
          </Typography>
          </Container>
          {(!sendingEmail && !emailSent) && <button  className='verify-btn' onClick={verifyEmail}>Verify Email</button>}
        </div>
        {sendingEmail &&
        <div>
          <Spinner />
          <Typography classname={classes.sendingTitle} variant="h5" component="h2" gutterBottom>
              {"Sending verifcation"}
            </Typography>
        </div>
        }
        {emailSent && <div>
          <Spinner />
          <Typography classname={classes.awaitingTitle} variant="h5" component="h2" gutterBottom>
              {"Awaiting your verifcation"}
           
            </Typography>
        </div>}
      </div>
      <footer className={classes.footer}>
        <Container maxWidth="sm">

          <Copyright />
        </Container>
      </footer>

    </div>
  );
};

