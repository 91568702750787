import backendService, {
  createCategories,
  updateCategories,
  createCategoryVoices,
  deleteCategories,
  updateCategoryVoices
} from "../../../../../services/backend-service";
import { CUSTOM_VOICE_IDS } from "../../../../../constants/constants";

export const sortPriority = (a, b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0)

export const changeVoices = async ({ setPopupStatus, rssId, titleVoice, bodyVoice, organisationId }) => {
  setPopupStatus("querying");
  await backendService.updateRss({
    rssId,
    titleVoice,
    bodyVoice,
    categoryTitle: CUSTOM_VOICE_IDS[titleVoice],
    categoryBody: CUSTOM_VOICE_IDS[bodyVoice],
    organisationId
  });
  setPopupStatus("success");
};

export const deleteCategory = async ({ organisationId, rssId, selectedCategory, categories, setDeletePopupStatus, setCategories, openErrorPopup }) => {
  const updatedCategory = await updateCategories(organisationId, rssId, selectedCategory.id, {
    name: selectedCategory.name,
    priority: 0,
    narration_setting_id: selectedCategory.narration_setting_id,
    narrate: selectedCategory.narrate
  }).catch((err) => { console.log(err); setDeletePopupStatus(""); openErrorPopup('Sorry, we could not delete your category') });

  if (updatedCategory) {
    const filteredCategories = categories.filter((category) => {
      return category.id !== selectedCategory.id
    })

    for (let i = 0; i < filteredCategories.length; i++) {
      if (filteredCategories[i].priority > selectedCategory.priority) {
        filteredCategories[i].priority--

        console.log(filteredCategories[i])

        await updateCategories(organisationId, rssId, filteredCategories[i].id, {
          name: filteredCategories[i].name,
          priority: filteredCategories[i].priority,
          narration_setting_id: filteredCategories[i].narration_setting_id,
          narrate: filteredCategories[i].narrate
        }).catch((err) => { console.log(err.message) });
      }
    }

    setCategories(filteredCategories)
  }

  setDeletePopupStatus("");
}

export const createNewCategory = async ({ name, rssId, categories, categoryOptions, organisationId, setSelectedOption, setCategories, setShowCategoryWarning, openErrorPopup }) => {
  if (categories.length < 3) {
    const selectedCategory = categoryOptions.filter((cat) => { return cat.name === name })[0];

    const newCategory = await updateCategories(organisationId, rssId, selectedCategory.id, {
      name: selectedCategory.name,
      priority: categories.length + 1,
      narration_setting_id: selectedCategory.narration_setting_id,
      narrate: selectedCategory.narrate
    }).catch((err) => { console.log(err); openErrorPopup('Sorry, we could not add your category') });

    if (newCategory) {
      await createCategoryVoices(organisationId, rssId, newCategory.id, null)
        .catch((err) => {
          console.log(err)
          deleteCategories(organisationId, newCategory.id);
          openErrorPopup('Sorry, we could not add your category')
        });

      setSelectedOption('');
      setCategories([...categories, newCategory]);
    }

  } else {
    setShowCategoryWarning('warning');
  }
}

export const updateCategoryVoice = async ({ cat, voice, voiceType, categories, organisationId, rssId, setCategories, openErrorPopup }) => {
  const updatedCategories = [...categories]
  let updatedCategory;

  for (let i = 0; i <= updatedCategories.length; i++) {
    const category = updatedCategories[i]

    if (category && category.id === cat.id) {
      if (CUSTOM_VOICE_IDS[voice]) {
        // Api call to update voice
        console.log(CUSTOM_VOICE_IDS[voice])
        updatedCategories[i][voiceType] = voice;

        updatedCategory = await updateCategoryVoices(organisationId, cat.id, rssId, {
          body_voice_id: CUSTOM_VOICE_IDS[updatedCategories[i].bodyVoice] || null,
          title_voice_id: CUSTOM_VOICE_IDS[updatedCategories[i].titleVoice] || null
        }).catch((err) => {
          console.log(err);
          openErrorPopup('Sorry, we could not update your category')
        })
      } else if (voice === '') {
        // Update voice id to null
        updatedCategories[i][voiceType] = voice;

        updatedCategory = await updateCategoryVoices(organisationId, cat.id, rssId, {
          body_voice_id: CUSTOM_VOICE_IDS[updatedCategories[i].bodyVoice] || null,
          title_voice_id: CUSTOM_VOICE_IDS[updatedCategories[i].titleVoice] || null
        }).catch((err) => {
          console.log(err);
          openErrorPopup('Sorry, we could not update your category')
        })
      } else {
        // Nothing
        updatedCategories[i][voiceType] = voice;
      }
    }
  }

  if (updatedCategory) {
    setCategories(updatedCategories)
    console.log(`Category ${cat.id}'s ${voiceType} voice updated, go away now...`)
  }
}

export const updateCategoryNarrate = async ({ cat, rssId, narrate, organisationId, categories, setCategories, openErrorPopup }) => {
  const updatedCategory = await updateCategories(organisationId, rssId, cat.id, {
    name: cat.name,
    priority: cat.priority,
    narration_setting_id: cat.narration_setting_id,
    narrate
  }).catch((err) => {
    console.log(err);
    openErrorPopup('Sorry, we could not update your category');
  });

  if (updatedCategory) {
    const updatedCategories = [...categories]

    for (let i = 0; i <= updatedCategories.length; i++) {
      const category = updatedCategories[i]

      if (category && category.id === cat.id) {
        updatedCategories[i].narrate = narrate;
      }
    }

    setCategories(updatedCategories)
    console.log(`Category ${cat.id}'s narrate updated, go away now...`)
  }
}

export const updateCategoryPriority = async ({ index, rssId, move, categories, organisationId, setCategories, openErrorPopup }) => {
  let updatedCategories = [...categories];

  if (move === 'up') {
    if (updatedCategories[index - 1]) {
      updatedCategories[index].priority--;
      updatedCategories[index - 1].priority++;

      const catOne = updatedCategories[index];
      const catTwo = updatedCategories[index - 1];

      // Set the first priority to null
      const updatedCategory = await updateCategories(organisationId, rssId, catOne.id, {
        name: catOne.name,
        priority: 0,
        narration_setting_id: catOne.narration_setting_id,
        narrate: catOne.narrate
      }).catch((err) => {
        console.log(err);
        openErrorPopup('Sorry, we could not update your category');
      });

      if (updatedCategory) {
        // Update the second priority
        await updateCategories(organisationId, rssId, catTwo.id, {
          name: catTwo.name,
          priority: catTwo.priority,
          narration_setting_id: catTwo.narration_setting_id,
          narrate: catTwo.narrate
        }).catch((err) => { console.log(err.message) });

        // Update the first priority
        await updateCategories(organisationId, rssId, catOne.id, {
          name: catOne.name,
          priority: catOne.priority,
          narration_setting_id: catOne.narration_setting_id,
          narrate: catOne.narrate
        }).catch((err) => { console.log(err.message) });

        updatedCategories = updatedCategories.sort(sortPriority)
        setCategories([])
        setCategories(updatedCategories)
      }
    }
  } else if (move === 'down') {
    if (updatedCategories[index + 1]) {
      updatedCategories[index].priority++;
      updatedCategories[index + 1].priority--;

      const catOne = updatedCategories[index];
      const catTwo = updatedCategories[index + 1];

      // Set the first priority to null
      const updatedCategory = await updateCategories(organisationId, rssId, catOne.id, {
        name: catOne.name,
        priority: 0,
        narration_setting_id: catOne.narration_setting_id,
        narrate: catOne.narrate
      }).catch((err) => {
        console.log(err);
        openErrorPopup('Sorry, we could not update your category');
      });

      if (updatedCategory) {
        // Update the second priority
        await updateCategories(organisationId, rssId, catTwo.id, {
          name: catTwo.name,
          priority: catTwo.priority,
          narration_setting_id: catTwo.narration_setting_id,
          narrate: catTwo.narrate
        }).catch((err) => { console.log(err.message) });

        // Update the first priority
        await updateCategories(organisationId, rssId, catOne.id, {
          name: catOne.name,
          priority: catOne.priority,
          narration_setting_id: catOne.narration_setting_id,
          narrate: catOne.narrate
        }).catch((err) => { console.log(err.message) });

        updatedCategories = updatedCategories.sort(sortPriority)
        setCategories([])
        setCategories(updatedCategories)
      }
    }
  }
}