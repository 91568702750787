import React, { Fragment } from "react";
import "./NarrationRow.css";

const NarrationRow = ({ columns }) => {
  const sortedColumns = columns.map((column) => {
    return column !== null ? (
      <td className={`${column.double && "table-double"} ${column.float === 'bottom' && 'row-float-bottom'}`}>{column.content}</td>
    ) : (
      <Fragment></Fragment>
    );
  });
  return <tr className="pointer">{sortedColumns}</tr>;
};

export default NarrationRow;
