import { Chip } from "@mui/material";
import { EmojiEvents } from "@material-ui/icons";
import "./QuartileChip.css";

const quantiles = require("./Quantile.json");

const QuartileChip = ({days, quartile}) => {
	let QuartileLabel = quantiles[quartile];
	return (
		<div>
			<Chip
				icon={<EmojiEvents />}
				label={
					QuartileLabel
						? `${QuartileLabel} Quartile`
						: "The data for quartiles isn’t available currently. Please try again later!"
				}
				variant="outlined"
				color="primary"
				style={{
					height: "70%",
					justifyContent: "flex-start",
					fontSize: "1.2em",
					marginRight: "10px",
				}}
			/>
		</div>
	);
};

export default QuartileChip;
