/** @description - analytics.js
 * 
 *  - Provides a central store of state for analytics data and the functions to set that state.
 * 
 *  @author Keith Ong
 */

/**@todo rss state is using the analytics reducer? */
export const setListenerProgressData = data => {
    return ({
        type: 'SET_LISTENER_PROGRESS',
        data
    })
}

export const setCurrentNarrationData = data => {
    return ({
        type: 'SET_CURRENT_NARRATION_DATA',
        data
    })
}

export const setAggregateData = data => {
    return ({
        type: 'SET_AGGREGATE_DATA',
        data
    })
}

export const setAggregateProgress = data => {
    return ({
        type: 'SET_AGGREGATE_PROGRESS',
        data
    })
}

export const clearAnalyticsState = () => {
    return ({
        type: 'RESET_STATE'
    })
}

export const setPackages = data => {
    return ({
        type: 'SET_TTS_PACKAGES',
        data
    })
}
export const setRss = data => {
    return ({
        type: 'SET_RSS_FEEDS',
        data
    })
}
export const setCurrentRss = data => {
    return ({
        type: 'SET_CURRENT_RSS_FEEDS',
        data
    })
}
export const setPodcast = data => {
    return ({
        type: 'SET_PODCAST',
        data
    })
}
export const setListenerDeviceData = data => {
    return ({
        type: 'SET_LISTENER_DEVICE_DATA',
        data
    })
}
export const setCategories = data => {
  return ({
      type: 'SET_CATEGORIES',
      data
  })
}

const INITIAL_STATE = {
    listenerProgress: null,
    currentNarration: null,
    listenerDevice: null,
    aggregateData: null,
    aggregateProgress: null,
    ttsPackages: null,
    rssFeeds: null,
    currentRss: {title_voice: '', body_voice: ''},
    podcast: { id: null, episodes: [] },
    categories: []
}

const analyticsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_LISTENER_PROGRESS':
            return { ...state, listenerProgress: action.data }
        case 'SET_LISTENER_DEVICE_DATA':
            return { ...state, listenerDevice: action.data }
        case 'SET_CURRENT_NARRATION_DATA':
            return { ...state, currentNarration: action.data }
        case 'SET_AGGREGATE_DATA':
            return { ...state, aggregateData: action.data }
        case 'SET_AGGREGATE_PROGRESS':
            return { ...state, aggregateProgress: action.data }
        case 'SET_TTS_PACKAGES':
            return { ...state, ttsPackages: action.data }
        case 'SET_RSS_FEEDS':
            return { ...state, rssFeeds: action.data }
        case 'SET_CURRENT_RSS_FEEDS':
            return { ...state, currentRss: action.data }
        case 'SET_PODCAST':
            return { ...state, podcast: action.data }
        case 'SET_CATEGORIES':
          return { ...state, categories: action.data }
        case 'RESET_STATE':
            return { INITIAL_STATE }
        default:
            return state
    }
}

export default analyticsReducer