import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import { COLORS } from "../../constants/theme";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "red",
  },
  navbar: {
    background: COLORS.primary,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "white",
    fontColor: "white",
  },
  title: {
    flexGrow: 1,
  },
  navButton: {
    color: "white",
    fontColor: "white",
    textDecoration: "none",
  },
}));

export default function Navbar() {
  const classes = useStyles();

  return (
    <div test-id="navbar" className={classes.root}>
      <AppBar position="static" className={classes.navbar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
          >
            Ad Auris
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {/* News */}
          </Typography>
          <a
            href="https://www.ad-auris.com"
            className={classes.navButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button color="inherit" className={classes.navButton}>
              Home
            </Button>
          </a>
          <a
            href="https://www.ad-auris.com/how-it-works"
            className={classes.navButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button color="inherit" className={classes.navButton}>
              How it works
            </Button>
          </a>
          <a
            href="https://www.ad-auris.com/pricing"
            className={classes.navButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button color="inherit" className={classes.navButton}>
              Pricing
            </Button>
          </a>
          <Link to="/login" className={classes.navButton}>
            <Button color="inherit" className={classes.navButton}>
              Login
            </Button>
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  );
}
