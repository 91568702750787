import React from 'react';
import { useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from '@material-ui/icons/AddBox';
import './SideBarItems.css'
// import UserInfo from '../../../../UI/UserInfo/UserInfo'
// import RssFeedIcon from '@material-ui/icons/RssFeed';
// import MicIcon from '@material-ui/icons/Mic';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
// import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
// import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import AnalyticsIcon from '@material-ui/icons/BarChart';
import logo from './Logo.png';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import StarIcon from '@material-ui/icons/Star';

const SideBarItems = ({ onFilesButtonClicked, onUploadMenuButtonClicked, onSettingsClicked, onHomeClicked, mode, open, handleDrawerOpen, onProfessionalButtonClicked, onAnalyticsClicked }) => {
  const hasLegacyCustom = useSelector(state => state.subscription.legacy_custom_active);

  return (
    <div id='side-bar-content'>
      <ListItem button>
        {/* <UserInfo onUserClicked={onHomeClicked} /> */}
        <img onClick={handleDrawerOpen} id='side-bar-logo' src={logo} />
        {open && <h1 id='sidebar-title' className={mode === 'showHomePage' && 'icon'} onClick={onHomeClicked} >Ad Auris</h1>}
      </ListItem>

      <ListItem test-id='upload-button-sidebar' button onClick={onUploadMenuButtonClicked}>
        <ListItemIcon>
          <AddIcon className={`sidebar-icon ${mode === 'showInstantNarration' ? 'icon' : ''}`} id='add-icon' />
        </ListItemIcon>
        {open && <ListItemText className={`side-bar-button-text ${mode === 'showInstantNarration' ? 'icon' : ''}`} primary="New Narration" />}
      </ListItem>
      <ListItem button onClick={onFilesButtonClicked}>
        <ListItemIcon>
          <PlaylistPlayIcon className={`sidebar-icon ${mode === 'showFiles' || mode === 'showAudio' ? 'icon' : ''}`} id='file-icon' />
        </ListItemIcon>
        {open && <ListItemText className={`side-bar-button-text ${mode === 'showFiles' || mode === 'showAudio' ? 'icon' : ''}`} primary="My Narrations" />}
      </ListItem>
      <ListItem test-id='upload-button-sidebar' button onClick={onSettingsClicked}>
        <ListItemIcon>
          <SettingsIcon className={`sidebar-icon ${mode === 'showSettings' || mode === 'showRss' || mode === 'showNewRss' || mode === 'showRssView' || mode === 'showPodcast' || mode === 'showNewPodcast' ? 'icon' : ''}`} id='settings-icon' />
        </ListItemIcon>
        {open && <ListItemText className={`side-bar-button-text ${mode === 'showSettings' || mode === 'showRss' || mode === 'showNewRss' || mode === 'showRssView' || mode === 'showPodcast' || mode === 'showNewPodcast' ? 'icon' : ''}`} primary="Settings" />}
      </ListItem>
      <ListItem test-id='upload-button-sidebar' button onClick={onAnalyticsClicked}>
        <ListItemIcon>
          <AnalyticsIcon className={`sidebar-icon ${mode === 'showAnalytics' ? 'icon' : ''}`} id='analytics-icon' />
        </ListItemIcon>
        {open && <ListItemText className={`side-bar-button-text ${mode === 'showAnalytics' ? 'icon' : ''}`} primary="Analytics" />}
      </ListItem>

      {
        hasLegacyCustom &&
        <ListItem test-id='upload-button-sidebar' button onClick={onProfessionalButtonClicked}>
          <ListItemIcon>
            <StarIcon className={`sidebar-icon ${mode === 'showProfessional' ? 'icon' : ''}`} id='settings-icon' />
          </ListItemIcon>
          {open && <ListItemText className={`side-bar-button-text ${mode === 'showProfessional' ? 'icon' : ''}`} primary="Professional Editing" />}
        </ListItem>
      }

      <ListItem test-id='upload-button-sidebar' className='side-bar-help-button'>
        <a href='mailto:logan.underwood@ad-auris.com'>
          <ListItemIcon>
            <IconButton
              size="md"
            >
              <HelpOutlineIcon />
            </IconButton>
          </ListItemIcon>
          {open && <ListItemText className='side-bar-button-text' primary="Help" />}
        </a>
      </ListItem>
    </div>
  );
};

export default SideBarItems;