import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import YouTube from 'react-youtube';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  viewDemoButton: ({ chubby }) => ({
    minWidth: 50,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    margin: theme.spacing(2),
  //   background:
  //     /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  //     'linear-gradient(to right, white, white)',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    ...(chubby && {
      borderRadius: 50,
    }),
  }),
  label: {
    color: 'white',
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 700,
  },
  contained: {
    minHeight: 30,
    boxShadow: '0',
    '&:active': {
      boxShadow: '0',
    },
  },
}));

const VideoModal = ({showVideo, setShowVideo, videoId}) => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };
      const classes = useStyles();
    return (
        <Dialog
          open={showVideo}
          onClose={() => setShowVideo(false)}
          aria-labelledby="invite-dialog-title"
          aria-describedby="invite-dialog-description"
        >
          <div id="video-modal">
            <YouTube videoId={videoId} opts={opts} />
            <DialogActions>
              <Button className={classes.viewDemoButton} onClick={() => setShowVideo(false)} autoFocus>
                Close
          </Button>
            </DialogActions>
          </div>
        </Dialog>
    );
};

export default VideoModal;