export const cleanMongoData = (value, dataKey) => {
	let cleanedValue;

	if (value) {
		// Average and Total values need to be rounded
		if (dataKey === "average") {
			cleanedValue = Math.round(value.data[dataKey] * 100) / 100;
		} else if (dataKey === "total") {
			cleanedValue = Math.floor(value.data[dataKey] / 127);
		} else {
			cleanedValue = value.data[dataKey];
		}
	} else {
		if (dataKey === "percentage_change") {
			cleanedValue = null;
		} else {
			cleanedValue = 0;
		}
	}

	return cleanedValue;
};
