import Skeleton from "@mui/material/Skeleton";

const skeletonQuartileLoading = () => {
	return (
		<Skeleton
			animation="wave"
			variant="rectangular"
			width="180px"
			height="10%"
			style={{ margin: "auto" }}
      data-testid="quartile-skeleton"
		/>
	);
};

export default skeletonQuartileLoading;
