import React, { useRef, useCallback } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import chartIds from "../charts.json";

const sdk = new ChartsEmbedSDK({
	baseUrl: "https://charts.mongodb.com/charts-events-structure-eiwnq",
});

const quantiles = {
	1: "Top",
	2: "2nd",
	3: "3rd",
	4: "4th",
};

const DataVisualChart = ({ user, chartId, width, lastDaysAmount, customFilter }) => {

	let filter = { publisher: user.routeIdentifier };

	if (customFilter) {
		filter = customFilter;
	}

	const chart = sdk.createChart({
		chartId,
		filter,
		width,
		showAttribution: false,
	});

	const refChart = useRef(null);
	const renderChart = useCallback(
		async (ref) => {
			try {
				await chart.render(ref);
			} catch (e) {
				console.error(e);
			}
		},
		[lastDaysAmount]
	);

	const setRefChart = useCallback(
		(ref) => {
			if (ref) {
				renderChart(ref);
			}
			// Save a reference to the node
			refChart.current = ref;
		},
		[renderChart]
	);

	return <div className="dataVisualChart" ref={setRefChart}></div>;
};

export default DataVisualChart;
