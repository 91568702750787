import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import backendService from "../../../../services/backend-service";
// import Spinner from '../../../UI/Spinner/Spinner'
import HistoryBar from "../../../UI/HistoryBar/HistoryBar";
// import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import "react-dropzone-uploader/dist/styles.css";
import firebase from "firebase";
import AudioSample from "../../../UI/AudioSample/AudioSample";
import AutoCompleteField from "../../../UI/AutoCompleteField/AutoCompleteField";
import PopupModal from "../../../UI/PopupModal/PopupModal";
import TextField from "../../../UI/TextField/TextField";
import CustomTooltip from "../../../UI/CustomTooltip/CustomTooltip";
import FileUploadField from "../../../UI/FileUploadField/FileUploadField";
// import TextField from '@material-ui/core/TextField';
import { registerdEvents, logEvent } from "../../../../logging/eventLogger";
import NewNarrationButton from "../../../UI/NewNarrationButton/NewNarrationButton";
import "./InstantNarration.css";
import { useWindowSize } from "../../../../helpers/get-window-size";

//Todo: restrict the types and size of files allowed.

import { VOICE_TYPES } from "../../../../constants/constants";

const InstantNarration = ({ setShowPricing, onHomeClicked }) => {
  const [title, setTitle] = useState("");
  //   const [footer, setFooter] = useState('');
  //   const [comments, setComments] = useState('');
  const [limitReached, setLimitReached] = useState(false);
  const [narrationType, setNarrationType] = useState("");
  const [titleVoice, setTitleVoice] = useState("");
  const [errorBarMessage, setErrorBarMessage] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [errorModalTitle, setErrorModalTitle] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [modalStatus, setModalStatus] = useState("success");
  const user = useSelector((state) => state.user);
  console.log(user);
  const handleSubmit = async (files, allFiles) => {
    setTimeout(() => setEnabled(true), 3000);

    const formData = new FormData();
    if (url !== "") {
      formData.append("url", url);
    } else if (files) {
      formData.append("file", files[0].file);
    }
    formData.append("id", user.id);
    formData.append("organisationId", user.organisationId);
    formData.append("orgName", user.organisation);
    formData.append("email", user.email);
    formData.append("username", user.username);
    formData.append("title", title);
    formData.append("narrationType", narrationType);
    formData.append("titleVoice", titleVoice);
    // formData.append('comments', comments)
    // formData.append('footer', footer)
    formData.append("user", JSON.stringify(user));
    const token = await firebase.auth().currentUser.getIdToken();
    formData.append("token", token);

    allFiles ? allFiles.forEach((f) => f.remove()) : console.log("");
    try {
      setOpen(true);
      setModalStatus("success");
      const newTTSP = await backendService.instantNarration(formData);
    } catch (error) {
      setLimitReached(false);
      if (error.response) {
        console.log(error.response);
        let errorResponseMessage = error.response.data;
        setErrorBarMessage(errorResponseMessage);
        if (errorResponseMessage.includes("used up")) {
          setLimitReached(true);
          setErrorModalTitle(
            "You’ve hit your instant narration limit for this month"
          );
          setModalMessage(
            "Upgrade your account to make more instant narrations."
          );
          setModalStatus("error");
          setOpen(true);
        } else {
          setLimitReached(false);
          setErrorModalTitle(
            "Sorry, we could could not make your instant narration"
          );
          setModalMessage("Please try again later or contact us");
          setModalStatus("error");
          setOpen(true);
          console.log(error);
        }
      } else {
        setErrorModalTitle("Sorry, we are having server issues");
        setModalMessage("Please try again later or contact us");
        setModalStatus("error");
        setOpen(true);
      }
      setTimeout(() => {
        setErrorBarMessage("");
      }, 10000);
    }
  };
  const size = useWindowSize();

  return (
    <div className="page">
      {errorBarMessage ? (
        <AppBar className="error-message-bar" style={{ background: "red" }}>
          {errorBarMessage}
        </AppBar>
      ) : null}
      <PopupModal
        open={open}
        status={modalStatus}
        successContent={{
          title: "Narration being proccessed",
          body: (
            <div>
              <div>
                Narrations can take up to 5 minutes to process. You will receive
                an email notification when it’s ready. Go to the “Narrations”
                page to find your narration.
              </div>
              <button className="upgrade-button" onClick={() => setOpen(false)}>
                Got it
              </button>
            </div>
          ),
        }}
        errorContent={{
          title: <div>{errorModalTitle}</div>,
          body: (
            <Fragment>
              <p>{modalMessage}</p>
              {limitReached && (
                <button
                  className="upgrade-button"
                  onClick={() => {
                    setShowPricing(true);
                    setOpen(false);
                  }}
                >
                  Upgrade
                </button>
              )}
              {!limitReached && (
                <button
                  className="upgrade-button"
                  onClick={() => setOpen(false)}
                >
                  Got it
                </button>
              )}
            </Fragment>
          ),
        }}
        handleClose={() => {
          setOpen(false);
          setTitleVoice("");
          setNarrationType("");
          setTitle("");
          setUrl("");
        }}
      />

      <HistoryBar
        items={[
          {
            text: "Home",
            click: onHomeClicked,
          },
        ]}
        currentItem="Create Narration"
      />
      {/* Dropzone occasional does not display properly on safari, div added to attempt fix */}
      <div className={size.width <= 1000 ? "column" : "row-container"}>
        {/* Step 1 */}

        <div className="instant-column">
          <h1 className="section-title">1. Upload articles</h1>
          <TextField
            value={title}
            setValue={setTitle}
            label="Title"
            width={
              size.width <= 1440 ? (size.width <= 725 ? 260 : "90%") : "100%"
            }
          />

          <FileUploadField
            handleSubmit={handleSubmit}
            width={size.width <= 725 ? "210%" : "60%"}
          />

          <i
            style={{
              textAlign: "left",
              marginLeft: size.width <= 725 ? "0" : "50%",
              fontSize: size.width <= 725 ? "0.5em" : "1em",
            }}
          >
            For the best results, follow this{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ad-auris.com/post/formatting-your-article-for-narration"
            >
              guide
            </a>{" "}
            to structure your article.
          </i>

          <h1
            className="row-container"
            style={{ fontSize: "2em", fontWeight: "bolder", marginLeft: "25%" }}
          >
            or
          </h1>
          <i>beta feature 🔨</i>
          <TextField
            value={url}
            setValue={setUrl}
            label="Paste article url here"
            width={
              size.width <= 1440 ? (size.width <= 725 ? 260 : "90%") : "100%"
            }
          />
        </div>

        <div className="instant-column">
          <h1 className="section-title">2. Choose narrator voice</h1>

          <div className="input-with-sample audio-select">
            <AutoCompleteField
              value={narrationType}
              setValue={setNarrationType}
              label="Narrator Voice"
              width={size.width <= 1440 ? (size.width <= 725 ? 200 : 450) : 600}
              options={VOICE_TYPES}
            />
            <AudioSample audioName={narrationType} />
          </div>
          <div className="input-with-sample audio-select">
            <AutoCompleteField
              value={titleVoice}
              setValue={setTitleVoice}
              label="Optional Title Voice"
              width={size.width <= 1440 ? (size.width <= 725 ? 200 : 450) : 600}
              options={VOICE_TYPES}
            />
            <AudioSample audioName={titleVoice} />
            <CustomTooltip message="Choose a different voice to read only the title and byline." />
          </div>

          <div className="empty-space"></div>
          <NewNarrationButton
            onClick={() => {
              const submitButtons =
                document.getElementsByClassName("dzu-submitButton");

              if (submitButtons[1]) {
                submitButtons[1].dispatchEvent(
                  new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1,
                  })
                );
              } else if (submitButtons[0]) {
                submitButtons[0].dispatchEvent(
                  new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1,
                  })
                );
              } else {
                handleSubmit();
              }
            }}
            disabled={!(narrationType !== "" && (title !== "" || url !== ""))}
            label="Create"
          />
        </div>
      </div>
      {/* Button seems to float on top of form in safari, added spacing div to attempt fix */}
      <div className="empty-space">_</div>
    </div>
  );
};

export default InstantNarration;
