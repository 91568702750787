// Description: A page that shows an organisation's podcast details if they exist

import { React, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { useSelector } from "react-redux";
import backendService from "../../../../services/backend-service";
import "./PodcastDetails.css";
import HistoryBar from "../../../UI/HistoryBar/HistoryBar";
import PopupModal from "../../../UI/PopupModal/PopupModal";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import NarrationRow from "../../../UI/NarrationRow/NarrationRow";
import { mdiPlayCircle } from "@mdi/js";
import Icon from "@mdi/react";

const PodcastDetails = ({
	onHomeClicked,
	onSettingsClicked,
	onPodcastClicked,
}) => {
	const user = useSelector((state) => state.user);
	let podcast = useSelector((state) => state.analytics.podcast);
	const [currentTTSP, setCurrentTTSP] = useState({});
	const [deleteState, setDeleteState] = useState("deciding");
	const [deleteOpen, setDeleteOpen] = useState(false);

	const handleEpisodeDelete = async () => {
		setDeleteState("deleting");
		await backendService.deleteEpisode({
			ttsId: currentTTSP.tts_package_id,
		});
		setDeleteState("deciding");
		setDeleteOpen(false);
		onPodcastClicked();
	};

	const episodes = [];
	if (!podcast) {
		podcast = {
			cover_art: "",
			title: "",
			description: "",
			episodes: [],
		};
	}
	podcast.episodes.forEach((episode) => {
		episodes.push(
			<NarrationRow
				columns={[
					{
						content: (
							<span title={episode.title} className="narration-play-icon">
								<Icon path={mdiPlayCircle} color="#88D841" size={2} />
								{episode.title}
							</span>
						),
						double: true,
					},
					{
						content: <span></span>,
					},
					{
						content: <span></span>,
					},
					{
						content: <span></span>,
					},
					{
						content: <span>{episode.formatted_date}</span>,
					},
					{
						content: (
							<button
								className="delete-button"
								onClick={() => {
									setCurrentTTSP(episode);
									setDeleteOpen(true);
								}}
							>
								<DeleteForeverIcon fontSize={"inherit"} />
							</button>
						),
					},
				]}
			/>
		);
	});

	return (
		<div id="podcast-page" className="page">
			<PopupModal
				open={deleteOpen}
				status={deleteState === "deciding" ? "error" : ""}
				errorContent={{
					title: `Remove ${currentTTSP.title}?`,
					body: (
						<button
							onClick={handleEpisodeDelete}
							className="delete-button-popup"
						>
							delete
						</button>
					),
				}}
				handleClose={() => {
					setDeleteOpen(false);
				}}
			/>

			<HistoryBar
				items={[
					{
						text: "Home",
						click: onHomeClicked,
					},
					{
						text: "Settings",
						click: onSettingsClicked,
					},
				]}
				currentItem="My Podcast Channel"
			/>
			<div
				className="row-container justify-start horizontal-center"
				style={{ width: "75vw" }}
			>
				<div className="cover-art">
					<img src={podcast.cover_art} />
				</div>
				<div className="podcast-details">
					{/* <div className='row-container'> */}
					<h3>{podcast.title}</h3>
					{/* <div className='row-container' onClick={onEditClicked}>
              <EditIcon />
              <button >Edit</button>
            </div> */}
					{/* </div> */}
					<p id="podcast-description">{podcast.description}</p>
					<h3>Podcast RSS Feed URL</h3>
					<a
						href={`https://podcasts.ad-auris.com/podcast/rss/${user.routeIdentifier}/feed`}
						target="_blank"
						rel="noopener noreferrer"
						id="feed-url"
					>{`https://podcasts.ad-auris.com/podcast/feed/${user.routeIdentifier}/feed`}</a>
					<div id="podcast-episodes-header">
						<i>
							<a
								href="https://podcasters.spotify.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								Click here
							</a>
							for instructions on how to submit to podcasting platforms like
							Apple Podcasts, Spotify, Google Podcasts and others.
						</i>
					</div>
				</div>
			</div>
			<div id="podcast-episodes-header">
				<h1>Podcast Episodes</h1>
				<h4>
					<i>
						To add an episode, go to “My Narrations” and choose “podcast” under
						distribution options for your chosen narration.
					</i>
				</h4>
			</div>
			<table className="narrations-table">
				<thead>
					<NarrationRow
						columns={[
							{
								content: <span>Episode Title</span>,
								double: true,
							},
							{
								content: <span></span>,
							},
							{
								content: <span></span>,
							},
							{
								content: <span></span>,
							},
							{
								content: <span>Date</span>,
							},
							{
								content: (
									<div className="column">
										<span>Delete from</span>
										<br />
										<span>Channel</span>
									</div>
								),
							},
						]}
					/>
				</thead>
				<tbody>{episodes}</tbody>
			</table>
			<div className="empty-space">.</div>
		</div>
	);
};

export default PodcastDetails;
