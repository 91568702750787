import React, { useState, useEffect } from 'react';
import StripeContainer from '../../../Stripe/StripeContainer'
import { TextField, Grid, makeStyles, Paper, Button } from '@material-ui/core';

import './Checkout.css'
import ProductInfo from './ProductInfo'
const Checkout = ({ selectedProduct }) => {
    const [price, setPrice] = useState('')
    const [discount, setDiscount] = useState('')
    const [coupon, setCoupon] = useState(null)
    const [attemptingPayment, setAttemptingPayment] = useState(false)
    return (
        <div className='checkout-container'>
            <div className='item-section'>
                <ProductInfo
                    selectedProduct={selectedProduct}
                    discount={discount}
                    price={price}
                    coupon={coupon} setCoupon={setCoupon}
                    attemptingPayment={attemptingPayment}
                />
            </div>
            <div className='form-section'>
                <StripeContainer
                    attemptingPayment={attemptingPayment}
                    setAttemptingPayment={setAttemptingPayment}
                    coupon={coupon}
                    setCoupon={setCoupon}
                    setDiscount={setDiscount}
                    setPrice={setPrice}
                    selectedProduct={selectedProduct} />
            </div>
        </div>
    );
};

export default Checkout;