import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { CopyToClipboard } from "react-copy-to-clipboard";
import backendService from "../../../../services/backend-service";
import { HistoryBar } from "../../../UI/HistoryBar/HistoryBar";
import { TextFieldComponent } from "../../../UI/TextField/TextField";
import "./FileInfo.css";
import PopupModal from "../../../UI/PopupModal/PopupModal";
import firebase from "firebase";
import { useWindowSize } from "../../../../helpers/get-window-size";
import { registerdEvents, logEvent } from "../../../../logging/eventLogger";
import PropTypes from "prop-types";

const FileInfo = ({
  onHomeClicked,
  onMyNarrationsClicked,
  setShowPricing,
  onNewPodcastClicked,
}) => {
  const [distribute, setDistribute] = useState("iframe");
  const file = useSelector((state) => state.file);
  const podcast = useSelector((state) => state.analytics.podcast);
  const [multipleVoices, setMultipleVoices] = useState(false);
  const [add30secIntro, setAdd30secIntro] = useState(false);
  const [report, setReport] = useState(false);
  const [backgroundMusic, setBackgroundMusic] = useState("");
  const [titleVoice, setTitleVoice] = useState("");
  const [bodyVoice, setBodyVoice] = useState("");
  const [quoteVoice, setQuoteVoice] = useState("");
  const [currentBackgroundMusic, setCurrentBackgroundMusic] = useState(
    file.music || ""
  );
  const [currentTitleVoice, setCurrentTitleVoice] = useState(
    file.titleVoice || file.narration_type
  );
  const [currentBodyVoice, setCurrentBodyVoice] = useState(file.narration_type);
  const [tone, setTone] = useState("");
  const [pronunciation, setPronunciation] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [article, setArticle] = useState("");
  const [option, setOption] = useState("publish");
  const [podState, setPodState] = useState(
    file.episode_count > 0 ? "episode" : "empty"
  );
  const [editStatus, setEditStatus] = useState("");
  const [customStatus, setCustomStatus] = useState("");

  if (!file.iframe) {
    file.iframe = "";
  }

  const user = useSelector((state) => state.user);
  const size = useWindowSize();

  useEffect(() => {
    setCurrentBodyVoice(file.narration_type);
    setCurrentTitleVoice(file.title_voice || file.narration_type);
    setCurrentBackgroundMusic(file.music);
  }, [file]);

  const handleEpisode = async () => {
    setPodState("querying");
    await backendService.newEpisode({
      podcastId: podcast.id,
      ttsId: file.id,
      subtitle,
      description,
    });
    setPodState("episode");
  };

  const requestEdit = async () => {
    const token = await firebase.auth().currentUser.getIdToken();
    const x = {
      tts_package_id: file.id,
      id: user.id,
      organisationId: user.organisationId,
      email: user.email,
      username: user.username,
      narrationType: currentBodyVoice,
      titleVoice: currentTitleVoice,
      token: token,
      music: currentBackgroundMusic,
      custom: file.narration_method === "Custom",
      user: user,
    };

    if (x.custom) {
      backendService.requestEditCustom(x);
    } else {
      backendService.requestEdit(x);
    }
    setEditStatus("success");
  };

  const requestCustom = async () => {
    const formData = new FormData();
    const token = await firebase.auth().currentUser.getIdToken();
    const x = {
      tts_package_id: file.id,
      id: user.id,
      organisationId: user.organisationId,
      email: user.email,
      username: user.username,
      narrationType: bodyVoice,
      quoteVoice: quoteVoice,
      titleVoice: titleVoice,
      token: token,
      music: backgroundMusic,
      user: user,
      intro: add30secIntro,
      multipleVoices: multipleVoices,
      tone: tone,
      comments: pronunciation,
    };
    setCustomStatus("querying");
    const response = await backendService.customNarrationUpgrade(x);
    setCustomStatus(response.status === 200 ? "success" : "error");
  };

  const LogDistributionOption = (choosen, previous) => {
    const loggingData = {
      option_choosen: choosen,
      previous_choosen: previous,
      file: file,
      // formData: formData
    };

    let eventType = null;

    switch (choosen) {
      case "iframe":
        eventType = registerdEvents.iframeDistributionClicked;
        break;
      case "url":
        eventType = registerdEvents.urlDistributionClicked;
        break;
      case "download":
        eventType = registerdEvents.downloadDistributionClicked;
        break;
      case "podcast":
        eventType = registerdEvents.podcastDistributionClicked;
        break;
      default:
        return;
    }

    logEvent(eventType, user, loggingData);
  };

  const chooseDistributionOption = (distributeOption) => {
    const prevDistribure = distribute;

    setDistribute(distributeOption);

    LogDistributionOption(distributeOption, prevDistribure);
  };

  return (
    <div className="page file-info-page">
      <HistoryBar
        items={[
          {
            text: "Home",
            click: onHomeClicked,
          },
          {
            text: "My Narrations",
            click: onMyNarrationsClicked,
          },
        ]}
        currentItem={file.title}
        custom={file.narration_method === "Custom"}
      />

      {/* Request Edit Popup */}
      <PopupModal
        open={editStatus}
        status="success"
        successContent={{
          title: "Voice changed",
          body: (
            <div>
              <p>
                Your edits have been registered and will render over the next
                few minutes.
              </p>
              <button
                className="upgrade-button"
                onClick={() => setEditStatus("")}
              >
                Got it
              </button>
            </div>
          ),
        }}
        handleClose={() => {
          setEditStatus("");
        }}
      />

      {file.audioExists ? (
        <div
          className="iframe-container"
          dangerouslySetInnerHTML={{ __html: file.iframe }}
        ></div>
      ) : (
        <div id="no-audio-text">This audio narration is still in progress</div>
      )}
      <div className="file-info-distribution-options">
        <div className="file-options-container">
          <div className="column publish-container">
            <div className="row-container justify-start">
              <div className="upload-icon">1</div>
              <h1>Choose distribution method:</h1>
            </div>

            <div
              className="row-container horizontal-center"
              style={{ justifyContent: "space-evenly", width: "40%" }}
            >
              <h2
                className={distribute === "iframe" ? "icon" : "pointer"}
                onClick={() => {
                  chooseDistributionOption("iframe");
                }}
              >
                embed
              </h2>
              <h2 className="icon">|</h2>
              <h2
                className={distribute === "url" ? "icon" : "pointer"}
                onClick={() => {
                  chooseDistributionOption("url");
                }}
              >
                URL
              </h2>
              <h2 className="icon">|</h2>
              <h2
                className={distribute === "download" ? "icon" : "pointer"}
                onClick={() => {
                  chooseDistributionOption("download");
                }}
              >
                download
              </h2>
              <h2 className="icon">|</h2>
              <h2
                className={distribute === "podcast" ? "icon" : "pointer"}
                onClick={() => {
                  chooseDistributionOption("podcast");
                }}
              >
                podcast
              </h2>
            </div>

            {distribute === "iframe" ? (
              <div className="row-container justify-center horizontal-center">
                <span className="iframe-code-container">{file.iframe}</span>
                <CopyToClipboard
                  text={file.iframe}
                  onCopy={() => alert("Your Iframe Code has been Copied!")}
                >
                  <div>
                    <Button
                      className="copy-button"
                      id="iframe-copy-button"
                      scolor="primary"
                      autoFocus
                    >
                      copy code
                    </Button>
                  </div>
                </CopyToClipboard>
              </div>
            ) : distribute === "url" ? (
              <div className="row-container justify-center horizontal-center">
                <span className="iframe-code-container">
                  {file.iframe.split('src="')[1].split('">')[0]}
                </span>
                <CopyToClipboard
                  text={file.iframe.split('src="')[1].split('">')[0]}
                  onCopy={() => alert("Your narration URL has been Copied!")}
                >
                  <div>
                    <Button id="iframe-copy-button" color="primary" autoFocus>
                      copy url
                    </Button>
                  </div>
                </CopyToClipboard>
              </div>
            ) : (
              <Fragment></Fragment>
            )}

            {distribute !== "download" ? (
              <div className="row-container justify-start">
                <div className="upload-icon">2</div>
                <h1>
                  {distribute === "iframe"
                    ? "Instructions"
                    : distribute === "url"
                    ? "Examples of URL use"
                    : distribute === "podcast"
                    ? "Fill out the following:"
                    : ""}
                </h1>
              </div>
            ) : (
              <Fragment></Fragment>
            )}

            {distribute === "iframe" ? (
              <div
                className="column iframe-instructions"
                style={{ textAlign: "left", marginLeft: "60px" }}
              >
                <div style={{ fontWeight: "bolder" }}>
                  To embed your narration follow these steps:
                </div>
                <div>1. Copy the iframe code</div>
                <div>
                  2. On your publication website, paste iframe in the HTML
                  editor
                </div>
                <div>
                  3. Publish your article and the audio player will appear!
                </div>
              </div>
            ) : distribute === "url" ? (
              <div
                className="column iframe-instructions"
                style={{ textAlign: "left", marginLeft: "60px" }}
              >
                <div style={{ fontWeight: "bolder" }}>Examples of URL use</div>
                <div>• Share the narration on social media posts</div>
                <div>• Hyperlink the narration on email newsletters</div>
              </div>
            ) : distribute === "podcast" ? (
              <Fragment>
                {podcast.id ? (
                  podState !== "episode" ? (
                    <div
                      className="column"
                      style={{ textAlign: "left", marginLeft: "50px" }}
                    >
                      <h1>Subtitle*</h1>
                      <TextFieldComponent
                        value={subtitle}
                        setValue={setSubtitle}
                        label="Type here"
                        width="50%"
                      />
                      <h1>Description*</h1>
                      <TextareaAutosize
                        className="comment-box"
                        value={description}
                        placeholder="Type a couple sentences that describes what your episode is about."
                        onChange={(e) => setDescription(e.target.value)}
                        rowsMin="10"
                        id="description"
                        label="Description"
                        name="description"
                        multiline="true"
                        test-id="upload-description-box"
                      />
                      {podState === "empty" ? (
                        <button
                          style={{
                            marginLeft: "auto",
                            marginRight: "30%",
                            zIndex: 9999,
                          }}
                          onClick={handleEpisode}
                          className="download-button"
                        >
                          add to podcast
                        </button>
                      ) : (
                        <button
                          style={{
                            marginLeft: "auto",
                            marginRight: "30%",
                            zIndex: 9999,
                            borderColor: "transparent",
                            cursor: "wait",
                          }}
                          className="download-button"
                        >
                          add to podcast
                        </button>
                      )}
                    </div>
                  ) : (
                    <h1>This narration has been added to your podcast!</h1>
                  )
                ) : (
                  <h1>
                    Click{" "}
                    <span
                      onClick={onNewPodcastClicked}
                      className="icon pointer"
                    >
                      here
                    </span>{" "}
                    to create a podcast
                  </h1>
                )}
              </Fragment>
            ) : (
              <a
                className="download-button"
                href={file.audioFile}
                rel="noreferrer"
                target="_blank"
              >
                <GetAppIcon className="icon" style={{ fontSize: "3em" }} />
                download mp3
              </a>
            )}
            <div className="empty-space">.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

FileInfo.propTypes = {
  onHomeClicked: PropTypes.func,
  onMyNarrationsClicked: PropTypes.func,
  setShowPricing: PropTypes.func,
  onNewPodcastClicked: PropTypes.func,
};

export default FileInfo;
