import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {firebase} from './services/firebase-service';
import "firebase/auth";
import { FirebaseAuthProvider } from "react-firebase-auth-provider";
import { createStore, combineReducers } from 'redux'
import analyticsReducer from './reducers/analytics'
import userReducer from './reducers/user'
import fileReducer from './reducers/file'
import subscriptionReducer from './reducers/subscription'
import { Provider } from 'react-redux'
import config from './config/firebase-config'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

const persistUserConfig = {
  key: 'user',
  storage,
}
const persistSubscriptionConfig = {
  key: 'subscription',
  storage,
}
const reducer = combineReducers({
  analytics: analyticsReducer,
  user:  persistReducer(persistUserConfig, userReducer),
  subscription: persistReducer(persistSubscriptionConfig,subscriptionReducer),
  file: fileReducer,
})

let store = createStore(reducer)
let persistor = persistStore(store)

//A store of state for the app that can be accessed directly from any component (user and analytics data)
// const store = createStore(reducer)




ReactDOM.render(
  <React.StrictMode>
    <FirebaseAuthProvider {...config} firebase={firebase}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </FirebaseAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
