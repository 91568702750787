import React from 'react';
import TierCard from './TierCard/TierCard'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector } from 'react-redux'
import './PricingModal.css'

const PricingModal = ({ open, setOpen, setDashboardMode, setSelectedProduct }) => {
    const handleClose = () => setOpen(false)
    const [subscriptionInterval, setSubscriptionInterval] = React.useState('monthly');
    const subscription = useSelector(state => state.subscription)

    return (
        <Dialog
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            aria-labelledby="invite-dialog-title"
            aria-describedby="invite-dialog-description"
        >
            <div >
                <div className="pricing-close-button" onClick={handleClose} color="primary" autoFocus>
                    <ClearIcon />
                </div>
                <DialogContent>
                    <div className="pricing-modal">
                        <div className="form-text-large"> Pricing Guide </div>
                        <div className="form-text-medium-italic" > Please contact tina.haertel @ad-auris.com for discount eligibility. </div>
                        <div className="tiers-container" >
                            <div className="flex-column free-tier-color" >
                                <TierCard setSelectedProduct={setSelectedProduct} handleClose={handleClose} setDashboardMode={setDashboardMode} usersCurrentTier={subscription.tier} label="Advertising" tier="Advertising" />
                                <div className="text-align-left-pricing-info">
                                    <div className="tier-list-title free-tier-color" > Advertising Includes: </div>
                                </div>
                                <ul className="tier-list" >
                                    <li> participation in advertisement beta feature </li>
                                    <li> for more details please contact tina.haertel@ad-auris.com </li>
                                </ul>
                            </div>
                            <div className="flex-column pro-tier-color" >
                                <TierCard setSelectedProduct={setSelectedProduct} handleClose={handleClose} setDashboardMode={setDashboardMode} usersCurrentTier={subscription.tier} label="Starter" tier="Starter" subDuration={subscriptionInterval} />
                                <div className="text-align-left-pricing-info">
                                    <div className="tier-list-title pro-tier-color" > Free Includes: </div>
                                </div>
                                <ul className="tier-list" >
                                    <li> up to 50 narrations/month </li>
                                    <li> up to 500 listens/month </li>
                                </ul>
                            </div>
                            <div className="flex-column corporate-tier-color" >
                                <TierCard setSelectedProduct={setSelectedProduct} handleClose={handleClose} setDashboardMode={setDashboardMode} usersCurrentTier={subscription.tier} label="Business" tier="Business" subDuration={subscriptionInterval} />
                                <div className="text-align-left-pricing-info">
                                    <div className="tier-list-title corporate-tier-color" > Corporate Includes: </div>
                                </div>
                                <ul className="tier-list  corporate-tier-color" >
                                    <li> 200 narrations/month </li>
                                    <li> 4000 listens/month </li>
                                </ul>
                            </div>
                            <div className="flex-column enterprise-tier-color" >
                                <TierCard setSelectedProduct={setSelectedProduct} handleClose={handleClose} setDashboardMode={setDashboardMode} usersCurrentTier={subscription.tier} label="Enterprise" tier="Enterprise" subDuration={subscriptionInterval} />
                                <div className="text-align-left-pricing-info">
                                    <div className="tier-list-title enterprise-tier-color" > Enterprise Includes: </div>
                                </div>
                                <ul className="tier-list  enterprise-tier-color" >
                                    <li> 800 narrations/month </li>
                                    <li> 20,000 listens/month </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </div>
        </Dialog>

    );
};

export default PricingModal;