import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyCzdSHETxm3V44Remxj9j663Tl-JdUVFLQ",
    authDomain: "ad-auris-tts-app.firebaseapp.com",
    databaseURL: "https://ad-auris-tts-app.firebaseio.com",
    projectId: "ad-auris-tts-app",
    storageBucket: "ad-auris-tts-app.appspot.com",
    messagingSenderId: "557045542338",
    appId: "1:557045542338:web:ccf9662400d5a9ae007a5b",
    measurementId: "G-N3HHH9RSPB"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

export { firebase }
