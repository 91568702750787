import React from 'react';
import Button from '@material-ui/core/Button';
import './NewNarrationButton.css'

const NewNarrationButton = ({ disabled, onClick, label }) => {
  return (
    <Button id={`new-button${disabled ? '-disabled' : ''}`} size="large" className='pointer' variant="contained" onClick={!disabled ? onClick : () => {}} >{label}</Button>
  );
};

export default NewNarrationButton;