export const setFile = data => {
    return ({
        type: 'SET_FILE',
        data
    })
}


const INITIAL_STATE = {
    iframe: null,
    iframeString:null,
    tile: null,
    date: null,
    audioExists: false,
}

const fileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_FILE':
            const newState = {
                ...state,
                ...action.data
            }
            return newState
        case 'RESET_STATE':
            return { INITIAL_STATE }
        default:
            return state
    }
}

export default fileReducer