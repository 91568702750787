//Subscriptions
const MONTHLY_PRO_PRICE_ID = "price_1IoD4IJwn4ANsYedydSStVMT"
// const MONTHLY_CORPORATE_PRICE_ID = "price_1IoD4QJwn4ANsYedRlFybL45" // $75 Version
const MONTHLY_CORPORATE_PRICE_ID = "price_1IsWO8Jwn4ANsYedE6vjsIvq" // $150 Version

const ADVERTISING_PRICE_ID = "price_1JemXPJwn4ANsYedZIYLQaxY"
const STARTER_PRICE_ID = "price_1JemXrJwn4ANsYedzJmgZvdy"
const BUSINESS_PRICE_ID = "price_1JemYCJwn4ANsYedAFK6ZZML"
const ENTERPRISE_PRICE_ID = "price_1JemYWJwn4ANsYed38SBDPUq"

//Products
const INSTANT_NARRATION_PRICE_ID = 'price_1IoDvlJwn4ANsYedsSGW906o'
const CUSTOM_NARRATION_PRICE_ID = 'price_1IoDvtJwn4ANsYedIg4owVLb'
const RSS_SERVICE_PRICE_ID = 'price_1IoDvyJwn4ANsYedTG32ElRX'
const PODCAST_SERVICE_PRICE_ID = 'price_1IoDw2Jwn4ANsYedWNoMpmJp'

export const PUBLISABLE_STRIPE_KEY = 'pk_live_51HjDpWJwn4ANsYedJt5h4FDFBDUXZGYlwU3MjvYdcnEv1Z7MpmNVABIHDyrU3yqf0NqcvONvzxA1CPA20tcR6xvS002p0FNVab'

export const PRO_TIER_SUBSCRIPTION = [
    {
        price: MONTHLY_PRO_PRICE_ID,
        quantity: 1
    },
    {
        price: PODCAST_SERVICE_PRICE_ID,
        quantity: 1
    },
    {
        price: INSTANT_NARRATION_PRICE_ID,
        quantity: 999
    },
    {
        price: CUSTOM_NARRATION_PRICE_ID,
        quantity: 10
    }
]
export const CORPORATE_TIER_SUBSCRIPTION = [
    {
        price: MONTHLY_CORPORATE_PRICE_ID,
        quantity: 1
    },
    {
        price: PODCAST_SERVICE_PRICE_ID,
        quantity: 1
    },
    {
        price: RSS_SERVICE_PRICE_ID,
        quantity: 1
    },
    {
        price: INSTANT_NARRATION_PRICE_ID,
        quantity: 999
    },
    {
        price: CUSTOM_NARRATION_PRICE_ID,
        quantity: 10
    }
]
export const ADVERTISING_TIER_SUBSCRIPTION = [
    {
        price: ADVERTISING_PRICE_ID,
        quantity: 1
    },
    {
        price: PODCAST_SERVICE_PRICE_ID,
        quantity: 1
    },
    {
        price: INSTANT_NARRATION_PRICE_ID,
        quantity: 999
    },
    {
        price: CUSTOM_NARRATION_PRICE_ID,
        quantity: 10
    }
]
export const STARTER_TIER_SUBSCRIPTION = [
    {
        price: STARTER_PRICE_ID,
        quantity: 1
    },
    {
        price: PODCAST_SERVICE_PRICE_ID,
        quantity: 1
    },
    {
        price: RSS_SERVICE_PRICE_ID,
        quantity: 1
    },
    {
        price: INSTANT_NARRATION_PRICE_ID,
        quantity: 999
    },
    {
        price: CUSTOM_NARRATION_PRICE_ID,
        quantity: 10
    }
]
export const BUSINESS_TIER_SUBSCRIPTION = [
    {
        price: BUSINESS_PRICE_ID,
        quantity: 1
    },
    {
        price: PODCAST_SERVICE_PRICE_ID,
        quantity: 1
    },
    {
        price: RSS_SERVICE_PRICE_ID,
        quantity: 1
    },
    {
        price: INSTANT_NARRATION_PRICE_ID,
        quantity: 999
    },
    {
        price: CUSTOM_NARRATION_PRICE_ID,
        quantity: 10
    }
]
export const ENTERPRISE_TIER_SUBSCRIPTION = [
    {
        price: ENTERPRISE_PRICE_ID,
        quantity: 1
    },
    {
        price: PODCAST_SERVICE_PRICE_ID,
        quantity: 1
    },
    {
        price: RSS_SERVICE_PRICE_ID,
        quantity: 1
    },
    {
        price: INSTANT_NARRATION_PRICE_ID,
        quantity: 999
    },
    {
        price: CUSTOM_NARRATION_PRICE_ID,
        quantity: 10
    }
]

// TEST PRICES
// const INSTANT_NARRATION_PRICE_ID = 'price_1Ij65cJwn4ANsYedEWc5yeTm'
// const CUSTOM_NARRATION_PRICE_ID = 'price_1IjAZbJwn4ANsYedwamd2i2Q'
// const RSS_SERVICE_PRICE_ID = 'price_1IjT90Jwn4ANsYedceJOo4Lf'
// const PODCAST_SERVICE_PRICE_ID = 'price_1IjT9PJwn4ANsYedG7J1sbNU'
// const MONTHLY_PRO_PRICE_ID = "price_1IjBMsJwn4ANsYedIwAxyMzg"//Pro Tier Test Daily
// const MONTHLY_CORPORATE_PRICE_ID = "price_1IjWBiJwn4ANsYedb55KEjKQ" //Corporate Tier Test Daily

//TEST VERSION
// export const PUBLISABLE_STRIPE_KEY = 'pk_test_51HjDpWJwn4ANsYed1aswzLhRIK39WPSpkmh2wFlOgTi7pQ5A0Du87dsTL7p8GuRkwbPx9aF2kTQV06kXo9E6rNdo00VeALYtby'
