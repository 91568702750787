/* Description: Creates or edits a new podcast for an organisation.*/

import { React, useState } from "react";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dropzone from "react-dropzone-uploader";
import { makeStyles } from "@material-ui/core/styles";
import backendService from "../../../../services/backend-service";
import { firebase } from "../../../../services/firebase-service";
import "./NewPodcast.css";
import categories from "./categories.json";
import languages from "./languages.json";
import AppBar from "@material-ui/core/AppBar";
import HistoryBar from "../../../UI/HistoryBar/HistoryBar";
import PopupModal from "../../../UI/PopupModal/PopupModal";
import { TextFieldComponent } from "../../../UI/TextField/TextField";
import CheckBox from "../../../UI/CheckBox/CheckBox";

const sortedCategories = [];
const sortedLanguages = [];

for (const category of categories) {
	sortedCategories.push({ name: category.mainCategory });

	category.subcategories.forEach((sub) => {
		sortedCategories.push({ name: `${category.mainCategory}-${sub}` });
	});
}

for (const language in languages) {
	sortedLanguages.push({ value: language, name: languages[language].name });
}

const useStyles = makeStyles((theme) => ({
	paper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	textField: {
		width: "85%",
		marginLeft: "auto",
		marginRight: "10px",
		paddingBottom: 0,
		marginTop: "9px",
		fontWeight: 500,
	},
}));

export default function NewPodcast({
	onPodcastClicked,
	onHomeClicked,
	onSettingsClicked,
}) {
	const user = useSelector((state) => state.user);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [explicit, setExplicit] = useState(false);
	const [language, setLanguage] = useState("");
	const [primaryCategory, setPrimaryCategory] = useState("");
	const [secondaryCategory, setSecondaryCategory] = useState("");
	const [imagePreview, setImagePreview] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [siteUrl, setSiteUrl] = useState("");
	const [copyright, setCopyright] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [open, setOpen] = useState(false);
	const [popupState, setPopupState] = useState("creating");

	const handleClose = () => {
		onPodcastClicked();
		setOpen(false);
	};

	const handleSubmit = async (files, allFiles) => {
		if (
			title !== "" &&
			language !== "" &&
			primaryCategory !== "" &&
			secondaryCategory !== "" &&
			name !== "" &&
			email !== "" &&
			copyright !== "" &&
			siteUrl !== ""
		) {
			setPopupState("creating");
			setOpen(true);

			let imageUrl = "";
			const token = await firebase.auth().currentUser.getIdToken();

			if (files) {
				const imageData = new FormData();
				imageData.append("file", files[0].file);

				imageData.append("token", token);

				imageUrl = await backendService.uploadPodcastImage(imageData);
			}

			const formData = {};
			formData["organisationId"] = user.organisationId;
			formData["title"] = title;
			formData["description"] = description;
			formData["explicit"] = explicit;
			formData["language"] = language;
			formData["primaryCategory"] = primaryCategory;
			formData["secondaryCategory"] = secondaryCategory;
			formData["name"] = name;
			formData["email"] = email;
			formData["copyright"] = copyright;
			formData["image"] = imageUrl;
			formData["site_url"] = siteUrl;
			formData["token"] = token;

			try {
				await backendService.newPodcast(formData);
				setPopupState("success");
			} catch (err) {
				setPopupState("error");
			}
		} else {
			setErrorMessage("Please fill in all entries before submitting");
			setTimeout(() => {
				setErrorMessage("");
			}, 2000);
		}
	};

	const classes = useStyles();
	return (
		<div id="new-podcast-page" className="page">
			<HistoryBar
				items={[
					{
						text: "Home",
						click: onHomeClicked,
					},
					{
						text: "Settings",
						click: onSettingsClicked,
					},
				]}
				currentItem="Create Podcast Channel"
			/>
			{errorMessage ? (
				<AppBar className="error-message-bar" style={{ background: "red" }}>
					{errorMessage}
				</AppBar>
			) : null}
			<PopupModal
				open={open}
				status={popupState}
				handleClose={handleClose}
				successContent={{
					title: "Success",
					body: <p>Your podcast feed has been created!</p>,
				}}
				errorContent={{
					title: "Error",
					body: (
						<p>An error has occurred, please check your inputs and try again</p>
					),
				}}
			/>

			<form
				className={classes.form}
				noValidate
				onSubmit={async (e) => {
					e.preventDefault();
					const submitButtons =
						document.getElementsByClassName("dzu-submitButton");

					if (submitButtons[1]) {
						submitButtons[1].dispatchEvent(
							new MouseEvent("click", {
								view: window,
								bubbles: true,
								cancelable: true,
								buttons: 1,
							})
						);
					} else if (submitButtons[0]) {
						submitButtons[0].dispatchEvent(
							new MouseEvent("click", {
								view: window,
								bubbles: true,
								cancelable: true,
								buttons: 1,
							})
						);
					} else {
						handleSubmit();
					}
				}}
			>
				<div className="row-container">
					<div className="podcast-form">
						<h2 className="podcast-title">Podcast Details</h2>
						<h3>Title*</h3>
						<TextFieldComponent
							value={title}
							setValue={setTitle}
							width="75%"
							label="Title"
						/>

						<h3>Description*</h3>
						<TextareaAutosize
							className="podcast-description"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							rowsMin="10"
							variant="outlined"
							margin="normal"
							required
							id="description"
							label="Description"
							name="description"
							autoFocus
							multiline="true"
							test-id="upload-description-box"
						/>

						<div
							className="row-container justify-start"
							style={{ marginLeft: "-6px" }}
						>
							<div>
								<h3>Language:</h3>
								<Autocomplete
									className={classes.dropDown}
									onChange={(e, value) =>
										value ? setLanguage(value.value) : setLanguage("")
									}
									id="tone"
									margin="normal"
									options={sortedLanguages}
									getOptionLabel={(option) => option.name}
									style={{ width: 190 }}
									renderInput={(params) => (
										<TextField
											value={language}
											onChange={(e) => setLanguage(e.target.value)}
											{...params}
											label="Language"
											variant="outlined"
										/>
									)}
									test-id="upload-voice-select"
								/>
							</div>
							<div className="row-container justify-start align-center">
								<CheckBox value={explicit} setValue={setExplicit} label="" />
								<span style={{ marginTop: "30px" }}>
									This post includes explicit content
								</span>
							</div>
						</div>

						<div
							className="row-container justify-start"
							style={{ marginLeft: "-6px" }}
						>
							<div>
								<h4>Primary Category:</h4>
								<Autocomplete
									className={classes.dropDown}
									onChange={(e, value) =>
										value
											? setPrimaryCategory(value.name)
											: setPrimaryCategory("")
									}
									id="tone"
									margin="normal"
									options={sortedCategories}
									getOptionLabel={(option) => option.name}
									style={{ width: 190 }}
									renderInput={(params) => (
										<TextField
											value={primaryCategory}
											onChange={(e) => setPrimaryCategory(e.target.value)}
											{...params}
											label="Primary Category"
											variant="outlined"
										/>
									)}
									test-id="upload-voice-select"
								/>
							</div>
							<div style={{ marginLeft: "20px" }}>
								<h4>Secondary Category:</h4>
								<Autocomplete
									className={classes.dropDown}
									onChange={(e, value) =>
										value
											? setSecondaryCategory(value.name)
											: setSecondaryCategory("")
									}
									id="tone"
									margin="normal"
									options={sortedCategories}
									getOptionLabel={(option) => option.name}
									style={{ width: 190 }}
									renderInput={(params) => (
										<TextField
											value={secondaryCategory}
											onChange={(e) => setSecondaryCategory(e.target.value)}
											{...params}
											label="Secondary Category"
											variant="outlined"
										/>
									)}
									test-id="upload-voice-select"
								/>
							</div>
						</div>

						<h2 className="podcast-title">Author Details</h2>
						<h3>Name*</h3>
						<TextFieldComponent
							value={name}
							setValue={setName}
							width="75%"
							label="Name"
						/>

						<h3>Email*</h3>
						<TextFieldComponent
							value={email}
							setValue={setEmail}
							width="75%"
							label="Email"
						/>

						<h3>SiteUrl*</h3>
						<TextFieldComponent
							value={siteUrl}
							setValue={setSiteUrl}
							width="75%"
							label="Email"
						/>

						<h3>Copyright*</h3>
						<TextFieldComponent
							value={copyright}
							setValue={setCopyright}
							width="75%"
							label="Copyright"
						/>
						<p style={{ textAlign: "right", width: "75%" }}>
							Ex. Jane Doe 2021
						</p>
					</div>

					{/*Cover Art*/}
					<div className="podcast-form">
						<br></br>
						<br></br>
						<h3>Cover Art*</h3>
						<div className="image-dropzone">
							{/* <img src={imagePreview} /> */}
							<Dropzone
								id="image-zone"
								test-id="image-zone"
								data-cy="drag-and-drop"
								//Limit file size to 1mb
								maxSizeBytes={1024 * 1024 * 5}
								accept="
              .png,
              .jpg,
              .jpeg
              "
								onChangeStatus={(e) => {
									setImagePreview(e.meta.previewUrl);
									if (e.meta.status === "removed") {
										setImagePreview("");
									}
								}}
								onSubmit={handleSubmit}
								validate={function (e) {
									//Returns an error if the image dimensions are incorrect
									if (e.meta.width < 1400 || e.meta.height < 1400) {
										return new Error("Image dimensions are to small");
									}
									if (e.meta.width > 3000 || e.meta.height > 3000) {
										return new Error("Image dimensions are to large");
									}
								}}
								inputContent={(files, extra) =>
									extra.reject
										? "Image files only"
										: "Drag a File or Click to Browse (png, jpg, jpeg)"
								}
								maxFiles={1}
								styles={{
									dropzone: {
										height: "21vw",
										width: "21vw",
										overflow: "auto",
										backgroundColor: "transparent",
										margin: 0,
									},
								}}
							></Dropzone>
							<i>*Image dimensions must be between 1400 and 3000 pixels</i>
						</div>
					</div>
				</div>
				<br></br>
				<br></br>
				<button
					id="new-rss-button"
					type="submit"
					variant="contained"
					color="primary"
				>
					create podcast
				</button>
			</form>
			<div className="empty-space">_</div>
		</div>
	);
}
