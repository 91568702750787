import React, { Fragment } from "react";
import "./HistoryBar.css";

export const HistoryBar = ({ items, currentItem, custom }) => {
  const history = items.map((item) => {
    return (
      <div className="history-item" onClick={item.click}>
        {item.text}
        {" >"}
      </div>
    );
  });

  return (
    <div className="history-bar">
      {history}
      <div
        className={`history-item ${
          currentItem !== "Create Podcast Channel"
            ? "current-item"
            : "current-item-podcast"
        }`}
      >
        {currentItem}
      </div>
      {custom ? (
        <div className="narration-tags home-narration-tags">
          <i className="custom-narration" style={{ width: "fit-content" }}>
            custom
          </i>
        </div>
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
};

export default HistoryBar;
