import React from 'react';
import { firebase } from '../../../services/firebase-service';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../constants/theme'

const useStyles = makeStyles((theme) => ({
  signOutButton: ({ chubby }) => ({
    minWidth: 50,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    margin: theme.spacing(2),
    backgroundColor: 'transparent',
    color: COLORS.primary,
    fontWeight: 'bolder',
    //   background:
    //     /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    //     'linear-gradient(to right, white, white)',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    ...(chubby && {
      borderRadius: 50,
    }),
  }),
  label: {
    color: 'white',
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 700,
  },
  contained: {
    minHeight: 30,
    boxShadow: '0',
    '&:active': {
      boxShadow: '0',
    },
  },
}));
const SignoutButton = ({ handleLogout }) => {
  const classes = useStyles();
  return (
    <div>
      <Button data-testid="signoutbutton" className={classes.signOutButton}
        onClick={() => {
          firebase.auth().signOut().then(() => {
            handleLogout()
          }).catch(error => {
            console.log(error)
          })
        }}
      >
        Sign Out
        </Button>
    </div>)
};

export default SignoutButton;
