import React from 'react';
import './CheckBox.css'
const CheckBox = ({ value, setValue, label }) => {
    return (
        <div>
            <label class="container input-label">{label}
            <input type="checkbox" checked={value} onClick={() => setValue(!value)} />
                <span class="checkmark"></span>
            </label>
        </div>
    );
};

export default CheckBox;