export const generateRssIframe = (organisationId, org_name) => {
    
    const styleAttributes = "width: 100%; height: 100px; border: none; display: none";
    const scriptSrc = "https://cdn.jsdelivr.net/npm/ad-auris-iframe-distribution@latest/script.js";

    const preloadScriptSrc = "https://cdn.jsdelivr.net/npm/ad-auris-iframe-distribution@latest/preload-script.js"

    if (org_name === "India Spend") {
        return `<script data-org=${organisationId} id=ad-auris-script src="${preloadScriptSrc}"></script>
                <iframe onload=iframeFunctionToCall() style="${styleAttributes}" data-org=${organisationId} allowtransparency allowtransparency allow="clipboard-read; clipboard-write" allowfullscreen="false" frameborder="0" id="ad-auris-iframe" scrolling="no"></iframe>`
    }

    return `<iframe style="${styleAttributes}" data-org=${organisationId} allowfullscreen="false" allowtransparency allow="clipboard-read; clipboard-write" frameborder="0" id="ad-auris-iframe" scrolling="no"></iframe><script src="${scriptSrc}"></script>`
}

// `<iframe style="width: 100%; height: 250px; border: none; display: none" data-org=${organisationId} allowfullscreen="false" frameborder="0" id="ad-auris-iframe" scrolling="no"></iframe><script src="https://cdn.jsdelivr.net/npm/ad-auris-distribution@latest/script.js"></script>`;
