const axios = require("axios");

const LoggingUri = "https://ad-auris-logging-db-l72twop3ra-uc.a.run.app/reg-dashboard-interaction"

export const registerdEvents = {
    createInstantNarration: "instant narration creation",
    fileUploadNarration: "file upload instant narration creation",
    createCustomNarration: "custom narration creation", 
    customNarrationUpgrade: "custom narration upgrade",
    requestEdit: "request Edit",
    rssNarrationCreation: "rss narration created",
    listenClick: "listen button clicked",
    deleteNarrationClick: "delete button clicked",

    
    iframeCopied: "iframe distribute option copied",
    iframeDistributionClicked: "iframe distribution option clicked",
    urlDistributionClicked: "url distribution option clicked",
    downloadDistributionClicked: "download distribution option clicked",
    podcastDistributionClicked: "podcast distribution option clicked",
    mp3DistributionClicked: "mp3 distribution option clicked",
    mp3DownloadClicked: "mp3 downloaded",
}

/**
 * 
 * @param {string} event 
 * @param {string} org human readable string to denote organization
 * @param {string} userId some identifier of an user UUID?
 * @param {string} email email of user triggering event
 * @param {object} data data specific to an event based on parameters of those events
 */

export const logEvent = (
    event,
    user,
    data
) => {
    assertWellFormed(event, data, user);
    const dateNow = new Date();
    const requestBody = generateRequestBody(event, user, data, dateNow);
    
    console.log(`${event} event logged`)
    console.log(requestBody)

    axios.post(LoggingUri, requestBody)
    .then((response) => {
        console.log(response)
    }).catch((error) => {
        console.log(error)
    })

}

const generateRequestBody = (event, user, data, date) => {
    const requestBody = {
        event: event,
        identifiers: user,
        dateTime: date,
        data: data
    }
    return requestBody;
}

const assertWellFormed = (event, data, user) => {
    assertEventExists(event);
    assertDataWellFormed(data);
    assertUserWellFormed(user);
}

const assertEventExists = (event) => {
    const possibleEvents = Object.values(registerdEvents)
    if (!possibleEvents.includes(event)) {
        throw Error(`Event ${event} is not registered`)
    }
}

const assertDataWellFormed = (data) => {
    if (typeof(data) !== "object") {
        throw Error (`data ${data} is not of type object`)
    }
}

const assertUserWellFormed = (user) => {
    console.log(user)
    console.log(typeof(user));
    if (typeof(user) !== "object") {
        throw Error (`data ${user} is not of type object`)
    }
    const neededProperties = ["organisation", "role", "email"];
    assertPropertyList(neededProperties, user);
}

const assertPropertyList = (neededProperties, object) => {
    for (const property of neededProperties) {
        if (!object.hasOwnProperty(property)) {
            throw new Error(`needed property ${property} is not in user object`)
        }
    }
}