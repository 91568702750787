import { React, useState, Fragment } from "react";
import "react-dropzone-uploader/dist/styles.css";
import "./RssFeeds.css";
import { useSelector } from "react-redux";
import backendService from "../../../../services/backend-service";
import HistoryBar from "../../../UI/HistoryBar/HistoryBar";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useWindowSize } from "../../../../helpers/get-window-size";
import NewNarrationButton from "../../../UI/NewNarrationButton/NewNarrationButton";
import PopupModal from "../../../UI/PopupModal/PopupModal";

const RssFeeds = ({
	onNewRssClicked,
	onIndividualRssClicked,
	onRssClicked,
	onSettingsClicked,
	testData,
	onHomeClicked,
}) => {
	const size = useWindowSize();
	let feeds = useSelector((state) => state.analytics.rssFeeds);
	let user = useSelector((state) => state.user);
	const [feedId, setFeedId] = useState(null);
	const [openDelete, setOpenDelete] = useState(false);
	const [deleteState, setDeleteState] = useState("warning");

	if (testData) {
		feeds = testData.feeds;
		user = testData.user;
	}

	const handleClose = () => {
		setOpenDelete(false);
	};

	const handleDelete = async () => {
		setDeleteState("deleting");
		await backendService.deleteRssFeed(feedId);
		setDeleteState("warning");
		setOpenDelete(false);
		onRssClicked();
	};

	let articles = null;
	if (feeds && Array.isArray(feeds)) {
		articles = feeds.map((feed) => {
			return (
				<tr key={feed.id} className="pointer">
					<td
						onClick={() => {
							onIndividualRssClicked(feed.id, feed);
						}}
						className="table-double"
						title={feed.name}
					>
						{feed.name}
					</td>
					<td></td>
					<td></td>
					<td>{feed.narration_count}</td>
					<td>
						<button
							className="delete-button"
							onClick={() => {
								setFeedId(feed.id);
								setOpenDelete(true);
							}}
						>
							<DeleteForeverIcon />
						</button>
					</td>
				</tr>
			);
		});
	}

	return (
		<div id="rss-feeds" className=" page" data-testid="rss-feeds">
			<HistoryBar
				items={[
					{
						text: "Home",
						click: onHomeClicked,
					},
					{
						text: "Settings",
						click: onSettingsClicked,
					},
				]}
				currentItem="My Feeds"
			/>

			<PopupModal
				open={openDelete}
				status={deleteState}
				handleClose={handleClose}
				warningContent={{
					title: "Delete Feed",
					body: (
						<div>
							<p>This action is cannot be undone, are you sure?</p>
							<button
								style={{ zIndex: 99999 }}
								onClick={handleDelete}
								className="delete-button-popup"
							>
								delete
							</button>
						</div>
					),
				}}
			/>

			<div className="rss-title " data-testid="rss-title">
				{feeds && feeds.length > 0 ? (
					<Fragment>
						{/*<div className='sub-title'>Click on your feed to distribute.</div>*/}
					</Fragment>
				) : (
					<h1>You have not uploaded any feeds</h1>
				)}
				<NewNarrationButton label="+ feed" onClick={onNewRssClicked} />
			</div>
			{feeds ? (
				<table className="narrations-table">
					<thead>
						<tr>
							<th>Title</th>
							<th></th>
							<th></th>
							<th></th>
							<th
								className="articles-narrated"
								style={size.width <= 1440 ? { fontSize: "1.2em" } : {}}
							>
								Articles Narrated
							</th>
							<th>Delete</th>
						</tr>
					</thead>
					<tbody data-testid="rss-feeds-tble">{articles}</tbody>
				</table>
			) : (
				<div data-testid="rss-feeds-div" className="title">
					Pretty empty in here...
				</div>
			)}
		</div>
	);
};

export default RssFeeds;
