import { React } from "react";
import axios from "axios";

export const percentCall = async (dimension, baseUrl, days) => {
	return await axios
		.get(
			`${baseUrl}/${dimension}/percentage-change${
				days ? `?days_ago=${days}` : ""
			}`
		)
		.catch((err) => {
			console.log(err);
		});
};
export const quartileCall = async (dimension, baseUrl, days) => {
	return await axios
		.get(
			`${baseUrl}/${dimension}/percentile?num_bucket=4${
				days ? `?days_ago=${days}` : ""
			}`
		)
		.catch((err) => {
			console.log(err);
		});
};
export const avgPercentCall = async (dimension, baseUrl, days) => {
	return await axios
		.get(
			`${baseUrl}/${dimension}/per/narration${days ? `?days_ago=${days}` : ""}`
		)
		.catch((err) => {
			console.log(err);
		});
};

export const avgQuartileCall = async (dimension, baseUrl, days) => {
	return await axios
		.get(
			`${baseUrl}/${dimension}/per/narration/percentile?num_bucket=4${
				days ? `?days_ago=${days}` : ""
			}`
		)
		.catch((err) => {
			console.log(err);
		});
};
