// can store all components and call at once to make project cleaner.
// used as : import * as Constants from './constants' or 
// used as : import * as Constants from './componentConstants' or 
// used as : import * as Constants from './environementConstants'. . . etc

export const baseUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5500';

export const IFRAME_URL = 'https://narrations.ad-auris.com'

export const uploadUrl = 'http://localhost:5500'

export const podcastBaseUrl = 'https://podcasts.ad-auris.com';

export const internalApiUrl = 'https://internal-api-service-staging-l72twop3ra-uc.a.run.app';

export default baseUrl

export const VOICE_TYPES = [
  { name: 'US Female' },
  { name: 'US Male' },
  { name: 'US Eng - Christopher' },
  { name: 'Spanish Female' },
  { name: 'Spanish Male' },
  { name: 'Indian Female' },
  { name: 'Indian Male' },
  { name: 'British Male 2' },
  { name: 'British Female' },
  { name: 'British Female 2' },
  { name: 'Brazilian Male (PT)' },
  { name: 'Brazilian Female (PT)' },
  { name: 'French Female(FR)' },
  { name: 'French Male(FR)' },
  { name: 'French Male(Can)' },
  { name: 'French Female(Can)' },
  { name: 'Korean Male'},
  { name: 'Korean Female'},
  { name: 'Spanish Male (AR)'},
  { name: 'Spanish Female (AR)'},
  { name: 'Indian Male (HI)'},
  { name: 'Indian Female (HI)'},
]

export const CUSTOM_VOICE_TYPES = [
  { name: 'US Female' },
  { name: 'US Male' },
  { name: 'US Eng - Christopher' },
  { name: 'Spanish Female' },
  { name: 'Spanish Male' },
  { name: 'Indian Female' },
  { name: 'Indian Male' },
  { name: 'British Male 2' },
  { name: 'British Female 2' },
  { name: 'British Male' },
  { name: 'British Female' },
  { name: 'US Male 2' },
  { name: 'US Female 2' },
  { name: 'Spanish Male(US)' },
  { name: 'Spanish Female(US)' },
  { name: 'Spanish Male(Mex)' },
  { name: 'Spanish Female(Mex)' },
  { name: 'French Male(Can)' },
  { name: 'French Female(Can)' },
  { name: 'French Male(Fr)' },
  { name: 'French Female(Fr)' },
  { name: 'Brazilian Male (PT)' },
  { name: 'Brazilian Female (PT)' },
  { name: 'French Female (CA)' },
  { name: 'French Male (CA)' },
  { name: 'Australian Female' },
  { name: 'Australian Male' },
  { name: 'Arabic Male (SA)' },
  { name: 'Arabic Female (SA)' },
  { name: 'Russian Male' },
  { name: 'Russian Female' },
  { name: 'Chinese Male (MS)' },
  { name: 'Chinese Female (MS)' },
  { name: 'Korean Male'},
  { name: 'Korean Female'},
  { name: 'Spanish Male (AR)'},
  { name: 'Spanish Female (AR)'},
  { name: 'Indian Male (HI)'},
  { name: 'Indian Female (HI)'},
  { name: 'Hebrew Male'},
  { name: 'Hebrew Female'},
  { name: 'Japanese Male'},
  { name: 'Japanese Female'},
  { name: 'Irish Male'},
  { name: 'Irish Female'},
  { name: 'Dutch Male'},
  { name: 'Dutch Female'},
  { name: 'Czech Male'},
  { name: 'Czech Female'},
  { name: 'Bulgarian Male'},
  { name: 'Bulgarian Female'},
  { name: 'German Male'},
  { name: 'German Female'},
  { name: 'Greek Male'},
  { name: 'Greek Female'},
  { name: 'Filipino Male'},
  { name: 'Filipino Female'},
  { name: 'Finnish Male'},
  { name: 'Finnish Female'},
  { name: 'Hungarian Male'},
  { name: 'Hungarian Female'},
  { name: 'Italian Male'},
  { name: 'Italian Female'},
  { name: 'Norwegian Male'},
  { name: 'Norwegian Female'},
  { name: 'Persian Male'},
  { name: 'Persian Female'},
  { name: 'Polish Male'},
  { name: 'Polish Female'},
  { name: 'Serbian Male'},
  { name: 'Serbian Female'},
  { name: 'Slovakian Male'},
  { name: 'Slovakian Female'},
  { name: 'Swahili Male'},
  { name: 'Swahili Female'},
  { name: 'Vietnamese Male'},
  { name: 'Vietnamese Female'},
  { name: 'Ukranian Male'},
  { name: 'Ukranian Female'}
]

export const CUSTOM_VOICE_IDS = {
  'US Female': 69,
  'US Male': 79,
  'US Eng - Christopher': 122,
  'Spanish Female': 91,
  'Spanish Male': 90,
  'Indian Female': 26,
  'Indian Male': 27,
  'British Male 2': 89,
  'British Female 2': 87,
  'British Male': 89,
  'British Female': 87,
  'US Male 2': 53,
  'US Female 2': 46,
  'Spanish Male(US)': 121,
  'Spanish Female(US)': 62,
  'Spanish Male(Mex)': 93,
  'Spanish Female(Mex)': 92,
  'French Male(Can)': 83,
  'French Female(Can)': 82,
  'French Male(Fr)': 86,
  'French Female(Fr)': 85,
  'Brazilian Male (PT)': 100,
  'Brazilian Female (PT)': 99,
  'Australian Female': 106,
  'Australian Male': 68,
  'Arabic Male (SA)': 103,
  'Arabic Female (SA)': 104,
  'Russian Male': 101,
  'Russian Female': 102,
  'Chinese Male (MS)': 119,
  'Chinese Female (MS)': 120,
}

export const CUSTOM_VOICE_ID_LABELS = {
  '69': 'US Female',
  '79': 'US Male',
  '122': 'US Eng - Christopher',
  '91': 'Spanish Female',
  '90': 'Spanish Male',
  '26': 'Indian Female',
  '27': 'Indian Male',
  '89': 'British Male 2',
  '87': 'British Female 2',
  '89': 'British Male',
  '87': 'British Female',
  '53': 'US Male 2',
  '46': 'US Female 2',
  '121': 'Spanish Male(US)',
  '62': 'Spanish Female(US)',
  '93': 'Spanish Male(Mex)',
  '92': 'Spanish Female(Mex)',
  '83': 'French Male(Can)',
  '82': 'French Female(Can)',
  '86': 'French Male(Fr)',
  '85': 'French Female(Fr)',
  '100': 'Brazilian Male (PT)',
  '99': 'Brazilian Female (PT)',
  '106': 'Australian Female',
  '68': 'Australian Male',
  '103': 'Arabic Male (SA)',
  '104': 'Arabic Female (SA)',
  '101': 'Russian Male',
  '102': 'Russian Female',
  '119': 'Chinese Male (MS)',
  '120': 'Chinese Female (MS)'
}