import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

export function useForm(initialFieldValues) {
    const [values, setValues] = useState(initialFieldValues)
    const [errors, setErrors] = useState({})

    const handleInputChange = (e, newVals) => {
        if (!newVals) {
            const { name, value } = e.target
            setValues({
                ...values,
                [name]: value
            })
        }else{
            setValues({
                ...values,
                country: newVals.code
            })
        }

    }
    return {
        values,
        setValues,
        handleInputChange,
        errors,
        setErrors
    }
};

const useStyle = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1)
        }
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3)
    }
}))

export function Form(props) {
    const classes = useStyle()
    const {children, ...other} = props
    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
};