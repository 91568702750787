import { React, useState, Fragment } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { useSelector } from "react-redux";
import {
  getSingleTSSP,
  checkIfWidgetExists,
} from "../../../../services/backend-service";
import { mdiPlayCircle } from "@mdi/js";
import { useDispatch } from "react-redux";
import { setFile } from "../../../../reducers/file";
import "./HomePage.css";
import "./HomePageBreakpoints.css";
import SettingsIcon from "@material-ui/icons/Settings";
import MicIcon from "@material-ui/icons/Mic";
import NarrationRow from "../../../UI/NarrationRow/NarrationRow";
import NewNarrationButton from "../../../UI/NewNarrationButton/NewNarrationButton";
import AddIcon from "@material-ui/icons/AddBox";
import Icon from "@mdi/react";
import PropTypes from "prop-types";
import { useWindowSize } from "../../../../helpers/get-window-size";

const HomePage = ({
  onNewNarration,
  onPodcastClicked,
  onRssClicked,
  setMode,
}) => {
  const size = useWindowSize();
  const dispatch = useDispatch();
  const ttsp = useSelector((state) => state.analytics.ttsPackages);
  const user = useSelector((state) => state.user);
  const [rssOpen, setRssOpen] = useState(false);
  const [podOpen, setPodOpen] = useState(false);
  const [teamOpen, setTeamOpen] = useState(false);
  const narrationTitles = {};

  console.log(user);

  const closeInfoWindow = () => {
    if (rssOpen || teamOpen || podOpen) {
      setRssOpen(false);
      setPodOpen(false);
      setTeamOpen(false);
    } else {
      setRssOpen(true);
      setPodOpen(true);
      setTeamOpen(true);
    }
  };
  return (
    <div className="page home-page">
      <h1>{`${user.organisation}'s Activity`}</h1>

      {/* Info window */}
      <div id="home-page-info">
        <div className="home-page-info-arrow" onClick={closeInfoWindow}>
          {rssOpen || podOpen || teamOpen ? "▲" : "▼"}
        </div>
        <h1>{`Welcome to ${user.organisation}'s dashboard`}</h1>
        <span style={{ width: "70%" }}>
          This is where you will create your audio! Here are some steps to help
          you get started. You can find more instructions and guides under
          “Help” in the sidebar.
        </span>
        <div className="row-container">
          {!teamOpen ? (
            <div
              className="home-page-options"
              onClick={() => {
                setTeamOpen(true);
              }}
            >
              <AddIcon className="home-page-icon" />
              <h2>Create an instant narration</h2>
              <span>▼</span>
            </div>
          ) : (
            <div className="home-page-options column">
              <div className="row-container">
                <AddIcon className="home-page-icon" />
                <h2>Create an instant narration</h2>
                <span
                  onClick={() => {
                    setTeamOpen(false);
                  }}
                >
                  ▲
                </span>
              </div>
              <p>
                Try submitting a url to your article or a text file and convert
                it into an audio narration. Select your desired narrator voice
                and optional music track and you should have your new narration
                in minutes
              </p>
              <h3 className="pointer" onClick={onNewNarration}>
                <span>Create instant narration </span>
                {">"}
              </h3>
            </div>
          )}
          {/* Auto Narrate */}
          {!rssOpen ? (
            <div
              className="home-page-options"
              onClick={() => {
                setRssOpen(true);
              }}
            >
              <SettingsIcon className="home-page-icon" />
              <h2>Set up auto-narrate</h2>
              <span>▼</span>
            </div>
          ) : (
            <div className="home-page-options column">
              <div className="row-container">
                <SettingsIcon className="home-page-icon" />
                <h2>Set up auto-narrate</h2>
                <span
                  onClick={() => {
                    setRssOpen(false);
                  }}
                >
                  ▲
                </span>
              </div>
              <p>
                Sync your RSS feeds with Ad Auris to automate the narration
                process. We’ll scan your site every 30min to check for new
                articles. Your narrations will automatically appear on every new
                article.{" "}
              </p>
              <p>Setup time: 5min</p>
              <h3 className="pointer" onClick={onRssClicked}>
                <span>Start set-up </span>
                {">"}
              </h3>
            </div>
          )}

          {/* Podcast */}
          {!podOpen ? (
            <div
              className="home-page-options"
              onClick={() => {
                setPodOpen(true);
              }}
            >
              <MicIcon className="home-page-icon" />
              <h2>Create a podcast channel</h2>
              <span>▼</span>
            </div>
          ) : (
            <div className="home-page-options column">
              <div className="row-container">
                <MicIcon className="home-page-icon" />
                <h2>Create a podcast channel</h2>
                <span
                  onClick={() => {
                    setPodOpen(false);
                  }}
                >
                  ▲
                </span>
              </div>
              <p>
                Grow your audience by distributing your narrations to podcasting
                platforms like Spotify and Apple Podcasts. Set up your channel
                once and then add the narrations you like as “episodes” to your
                channel.{" "}
              </p>
              <p>Setup time: 3min</p>
              <h3 className="pointer" onClick={onPodcastClicked}>
                <span>Create channel </span>
                {">"}
              </h3>
            </div>
          )}
        </div>
      </div>

      <div className="new-button-container horizontal-center">
        <NewNarrationButton onClick={onNewNarration} label="+ new" />
      </div>

      <table className="narrations-table home-table">
        <thead>
          <NarrationRow
            columns={[
              {
                content: (
                  <span className="narration-label">Latest Narrations</span>
                ),
                double: true,
              },
              size.width > 1000 ? { content: <span></span> } : null,
              size.width > 1000 ? { content: <span></span> } : null,
              size.width > 1000 ? { content: <span></span> } : null,
              {
                content: <span className="narration-label"></span>,
              },
              {
                content: <span className="narration-label">Date</span>,
              },
            ]}
          />
        </thead>
        <tbody className="feeds-table" data-testid="rss-feeds-tble">
          {ttsp ? (
            ttsp.slice(0, 5).map((pack) => {
              //Check if the narration has already been rendered to avoid duplicates
              if (narrationTitles[pack.title]) {
                return <Fragment></Fragment>;
              } else {
                narrationTitles[pack.title] = true;
                return (
                  <NarrationRow
                    columns={[
                      {
                        content: (
                          <Fragment>
                            <span
                              title={pack.title}
                              className="narration-play-icon"
                              onClick={async () => {
                                setMode("showAudio");
                                checkIfWidgetExists(
                                  user.routeIdentifier,
                                  pack.route_identifier,
                                  pack.id,
                                  (exists) => {
                                    dispatch(
                                      setFile({ audioExists: exists, ...pack })
                                    );
                                  }
                                );
                                getSingleTSSP(
                                  user.organisationId,
                                  pack.id,
                                  user.id,
                                  user.routeIdentifier,
                                  null,
                                  (iframe, iframeString, title, audioFile) => {
                                    dispatch(
                                      setFile({
                                        title: pack.title,
                                        iframe,
                                        iframeString,
                                        audioFile,
                                      })
                                    );
                                  }
                                );
                              }}
                            >
                              <div className="homepage-row">
                                <Icon
                                  path={mdiPlayCircle}
                                  color="#88D841"
                                  size={size.width > 1000 ? 2 : 1.5}
                                />
                                <span className="narration-title homepage-row-title">
                                  {pack.title}
                                </span>
                              </div>
                            </span>
                          </Fragment>
                        ),
                        double: true,
                      },
                      {
                        content: (
                          <div className="narration-tags home-narration-tags">
                            <i
                              className="in-progress-custom"
                              style={{
                                opacity: Number(pack.audio_count) < 1 ? 1 : 0,
                              }}
                            >
                              in-progress
                            </i>
                            <i
                              className="custom-narration"
                              style={{
                                opacity:
                                  pack.narration_method === "Custom" ? 1 : 0,
                              }}
                            >
                              custom
                            </i>
                            <i
                              className="podcast-episode"
                              style={{
                                opacity: Number(pack.episode_count) > 0 ? 1 : 0,
                              }}
                            >
                              podcast
                            </i>
                          </div>
                        ),
                      },
                      size.width > 1000 ? { content: <span></span> } : null,
                      size.width > 1000 ? { content: <span></span> } : null,
                      size.width > 1000 ? { content: <span></span> } : null,

                      {
                        content: (
                          <span className="narration-title">
                            {pack.formatted_date}
                          </span>
                        ),
                      },
                    ]}
                  />
                );
              }
            })
          ) : (
            <h1 className="row-container">
              <i>You have no narrations yet, click “+new” to get started!</i>
            </h1>
          )}
        </tbody>
      </table>
      <div className="empty-space"></div>
    </div>
  );
};

HomePage.propTypes = {
  onNewNarration: PropTypes.func,
  onNarrationClicked: PropTypes.func,
  onPodcastClicked: PropTypes.func,
  onRssClicked: PropTypes.func,
  setMode: PropTypes.func,
};

export default HomePage;
