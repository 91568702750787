import React from "react";
import CustomTooltip from "../../../../UI/CustomTooltip/CustomTooltip";
import TooltipMessage from "./TooltipMessage";
import QuartileChip from "./QuartileChip";
import QuartileLoading from "./QuartileLoading";
import PercentChange from "./PercentChange";

const AverageChart = ({
  label,
  percentChange,
  lastDaysAmount,
  quartile,
  querying,
  value,
}) => {
  return (
    <div className="averageChartContainer">
      <div className="averageChart">
        {typeof value === "number" && (
          <React.Fragment>
            <h4>{label}</h4>
            <h1>{value}</h1>
          </React.Fragment>
        )}
      </div>
      {querying ? (
        <QuartileLoading />
      ) : (
        // <Skeleton
        // 	animation="wave"
        // 	variant="rectangular"
        // 	width="160px"
        // 	height="10%"
        // 	style={{ margin: "auto", marginTop: "5px" }}
        // />
        <React.Fragment>
          {percentChange && lastDaysAmount && (
            <PercentChange change={PercentChange} days={lastDaysAmount} />
          )}
          {typeof quartile === "number" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "15%",
              }}
            >
              <QuartileChip days={lastDaysAmount} quartile={quartile} />
              <CustomTooltip
                placement="right"
                message={
                  <p style={{ lineHeight: "15px" }}>
                    <TooltipMessage />
                  </p>
                }
              />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default AverageChart;
