import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "./HomePage.css";
import { COLORS } from "../../../constants/theme";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useHistory } from "react-router-dom";
import backendService from "../../../services/backend-service";
import {
  setUser,
  setIsThirdPartyAuthUser,
  setFirebaseToken,
} from "../../../reducers/user";
import { useDispatch } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Spinner from "../../UI/Spinner/Spinner";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebase } from "../../../services/firebase-service";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "95vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
  title: {
    color: COLORS.primary,
    fontWeight: "bold",
  },
  loginTitle: {
    color: COLORS.primary,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    height: "1.5em",
    width: "100%",
    visibility: "visible",
    marginTop: "20px",
    marginBottom: "20px",
    fontSize: "xx-large",
    fontWeight: "1000",
    position: "relative",
    verticalAlign: "middle",
    justifyContent: "center",
    margin: "auto",
  },
}));

export default function HomePage() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "/dashboard",
    // We will display Google and Microsoft as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
        fullLabel: "sign up with Google",
      },
      {
        provider: "microsoft.com",
        providerName: "Microsoft",
        fullLabel: "sign up with Microsoft",
        scopes: ["mail.read"],
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
        fullLabel: "Sign up with Email",
        buttonColor: "#0099ff",
      },
    ],

    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        setShowSpinner(true);
        dispatch(setIsThirdPartyAuthUser(true));

        authResult.user.getIdTokenResult().then(async (tokenResult) => {
          const firebaseToken = tokenResult.token;
          dispatch(setFirebaseToken(firebaseToken));
          try {
            //Are they already signed up?
            const userExists = await backendService.checkIfUserExistsInBackend(
              firebaseToken
            );
            if (!userExists) {
              //Are they an email password user? If so need to check if they have verified their email
              if (authResult.additionalUserInfo.providerId === "password") {
                if (!authResult.user.emailVerified) {
                  //Go to the verify page
                  history.push("/verify");
                  return;
                }
              }
              //User has used a thirdparty auth provider, therefore their email is already verified, continue to signup.
              history.push("/signup");
            } else {
              //They are already a user, log them in and send them to the dashboard
              const loginResponse = await backendService.login(firebaseToken);
              console.log(loginResponse.data);
              dispatch(setUser(loginResponse.data));
              history.push("/dashboard");
            }
          } catch (err) {
            console.log(err);
            setShowSpinner(false);
            setErrorMessage("Could not sign in");
          }
        });
      },
    },
  };

  const userErrorMessage =
    "It look like something went wrong on our end. Please refresh the page and try again.";

  return (
    <div id="login-page">
      {errorMessage ? (
        <AppBar
          test-id="loginErrorBar"
          className="error-message-bar"
          style={{ background: "red" }}
        >
          {userErrorMessage}
        </AppBar>
      ) : null}
      <div id="login-page-content">
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className="paper">
            <Typography
              className={classes.title}
              variant="h3"
              component="h2"
              gutterBottom
            >
              Welcome to your
            </Typography>
            <Typography
              className={classes.title}
              variant="h3"
              component="h2"
              gutterBottom
            >
              Audio Journey!
            </Typography>
            {showSpinner ? <Spinner /> : null}
          </div>
        </Container>
        <div className="authProvider-login-container"></div>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
        <Typography color="#7B7B7B" component="p" variant="p">
          Already have an account?
          <a href="/login">
            <Button color="textSecondary">login</Button>
          </a>
        </Typography>
      </div>
    </div>
  );
}
