import { React, useState, Fragment } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { useSelector } from "react-redux";
import HistoryBar from "../../../UI/HistoryBar/HistoryBar";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import MicIcon from "@material-ui/icons/Mic";
import AddIcon from "@material-ui/icons/Add";
import "./Settings.css";
import "./SettingsBreakpoints.css";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@material-ui/core/Button";

const Settings = ({
  onNarrationClicked,
  onPricingClicked,
  onRssClicked,
  onPodcastClicked,
  onHomeClicked,
  onNewRssClicked,
}) => {
  const rss = useSelector((state) => state.analytics.rssFeeds);
  const user = useSelector((state) => state.user);
  let podcast = useSelector((state) => state.analytics.podcast) || {};

  let hasAnRssFeed = false;
  if (rss && rss.length > 0) {
    hasAnRssFeed = true;
  }

  return (
    <Fragment>
      <div className="page settings-page">
        <HistoryBar
          items={[
            {
              text: "Home",
              click: onHomeClicked,
            },
          ]}
          currentItem={"Settings"}
        />
        <h1>{user.organisation}'s Settings</h1>
        <div className="underline"></div>

        <section className="settings-section">
          <aside>
            <PersonAddIcon className="settings-icon icon-positioning" />
          </aside>
          <main>
            <h1>Subscription Status</h1>
            <i>
              To learn more about what’s included in the other tiers, or to
              upgrade your account check our Pricing Guide, or contact sales at
              tina.haertel@ad-auris.com. Please contact sales to cancel your
              subscription.{" "}
            </i>
            <div className="subscription-links-container row-container">
              <div
                className="subscription-link clickable-text-large section-title"
                onClick={onPricingClicked}
              >
                Pricing Guide
              </div>
              <div
                className="subscription-link clickable-text-large section-title"
                onClick={() => {
                  window.location =
                    "mailto:tina.haertel@ad-auris.com?subject=Contact%20Sales&body=";
                }}
              >
                Contact Sales
              </div>
            </div>
            <main>
              <h1>Invite a team member</h1>
              <i>
                Collaborate with your team by keeping track of articles that
                have been narrated, added to podcasts, etc. Send the invite code
                to your team members to sync accounts
              </i>
              <div className="horizontal-row-settings-page">
                <h1 className="settings-page-medium-title">You are a:</h1>
                <h1 className="settings-page-medium-title">{user.role} </h1>
              </div>
              <div className="horizontal-row-settings-page">
                {user.role === "Admin" ? (
                  <Fragment>
                    <h1 className="settings-page-medium-title">
                      Your invite code:{" "}
                    </h1>
                    <div className="rss-voice rss-iframe">
                      {user.joinCode || "Null join code"}
                    </div>
                  </Fragment>
                ) : (
                  <h1>Only Admins can invite new members</h1>
                )}
                {user.role === "Admin" ? (
                  <CopyToClipboard
                    text={user.joinCode || ""}
                    onCopy={() => alert("Your Invite Code has been Copied!")}
                  >
                    <Button
                      className="copy-button"
                      id="iframe-copy-button"
                      scolor="primary"
                      autoFocus
                    >
                      copy code
                    </Button>
                  </CopyToClipboard>
                ) : (
                  <Fragment></Fragment>
                )}
              </div>
            </main>
          </main>
        </section>
        <div className="underline"></div>
        <section className="settings-section">
          <aside>
            <SettingsIcon className="settings-icon icon-positioning" />
          </aside>
          <main>
            <h1>Auto-narrate with RSS</h1>
            <i>
              Sync your RSS feeds with Ad Auris to automate the narration
              process. We’ll scan your site every 30min to check for new
              articles. Your narrations will automatically appear on every new
              article.{" "}
            </i>
            {hasAnRssFeed ? (
              <h1 className="row-container">
                <h1 className="pointer" onClick={onRssClicked}>
                  {">"}
                  <span className="icon  clickable-text-medium"> My feeds</span>
                </h1>
              </h1>
            ) : (
              <Fragment>
                <h1 className="row-container">
                  <h1>
                    {">"}
                    <span className="text-medium-disabled"> My feeds</span>
                  </h1>
                </h1>
                <div className="">
                  <div className="vertical-flex-box-settings-page">
                    <div className="text-medium">
                      <i className="icon">
                        You have not synced an RSS feed yet click "Sync new
                        feed" to get started
                      </i>
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      <div className="align-to-right">
                        <h1 className="pointer" onClick={onRssClicked}>
                          {">"}{" "}
                          <span className="icon  clickable-text-medium sync-new-feed">
                            Sync new feed
                          </span>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </main>
        </section>

        <div className="underline"></div>

        <section className="settings-section">
          <aside>
            <MicIcon className="settings-icon icon-positioning" />
          </aside>
          <main>
            <h1>Podcasting</h1>
            <i>
              Grow your audience by distributing your narrations to podcasting
              platforms like Spotify and Apple Podcasts. Set up your channel
              once and then add the narrations you like as “episodes” to your
              channel.
            </i>
            <h1 className="row-container">
              {podcast.id ? (
                <div>
                  <h1 className="pointer" onClick={onPodcastClicked}>
                    {">"}
                    <span className="icon clickable-text-medium">
                      {" "}
                      My podcast channel
                    </span>
                  </h1>
                  <div className="row-container podcast-info-text">
                    <AddIcon
                      id="plus-button"
                      className="icon"
                      fontSize="large"
                      color="black"
                    />
                    <span className="settings-page-info-text">
                      To add an episode, go to{" "}
                      <span
                        className="clickable-embedded-text icon"
                        onClick={onNarrationClicked}
                      >
                        “My Narrations”
                      </span>{" "}
                      and choose “podcast” under distribution options for your
                      chosen narration.
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <h1>
                    {">"}
                    <span className="text-medium"> My podcast channel</span>
                  </h1>
                  <div className="vertical-flex-box-settings-page">
                    <div className="text-medium">
                      <i className="icon">
                        You don't have a podcast channel yet, click "Create
                        podcast channel" to get started
                      </i>
                    </div>
                    <div className="align-to-right">
                      <h1 className="pointer" onClick={onPodcastClicked}>
                        {">"}
                        <span className="icon clickable-text-medium">
                          Create podcast channel
                        </span>
                      </h1>
                    </div>
                  </div>
                  <div className="row-container podcast-info-text">
                    <AddIcon
                      id="plus-button"
                      className="icon"
                      fontSize="large"
                      color="black"
                    />
                    <span className="settings-page-info-text">
                      To add an episode, go to{" "}
                      <span
                        className="clickable-embedded-text icon"
                        onClick={onNarrationClicked}
                      >
                        “My Narrations”
                      </span>{" "}
                      and choose “podcast” under distribution options for your
                      chosen narration.
                    </span>
                  </div>
                </div>
              )}
            </h1>
          </main>
        </section>
      </div>
    </Fragment>
  );
};

export default Settings;
