import axios from "axios";
import { firebase } from "../services/firebase-service";
// const baseUrl = process.env.NODE_ENV === 'production' ? process.env.PRODUCTION_URL : 'http://localhost:5500';
import { baseUrl } from "../constants/constants";

export const getSubscriptionData = async () => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const token = await firebase.auth().currentUser.getIdToken();
  const response = await axios.post(
    baseUrl + "/accounts/subscription-data",
    { token },
    config
  );
  return response.data;
};

// counter in request body should only be from
/**
 * instant_counter
 * custom_counter
 * rss_counter
 */

export const hasRSSFeature = (SubscriptionData) => {
  return SubscriptionData.rss_active;
};

export const hasPodcastFeature = (SubscriptionData) => {
  return SubscriptionData.podcast_active;
};

export const getRemainingInstantNarrations = (SubscriptionData) => {
  return SubscriptionData.instant_limit - SubscriptionData.instant_counter;
};

export const getRemainingCustomtNarrations = (SubscriptionData) => {
  return SubscriptionData.custom_limit - SubscriptionData.custom_counter;
};

export const getRemainingRSS = (SubscriptionData) => {
  return SubscriptionData.rss_limit - SubscriptionData.rss_counter;
};

export const getTier = (SubscriptionData) => {
  return SubscriptionData.tier;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSubscriptionData,
};
