import React from 'react';
import ProductButton from '../ProductButton/ProductButton'
import './TierCard.css'

const TierCard = ({ usersCurrentTier, label, tier, subDuration, setDashboardMode, handleClose, setSelectedProduct, displayOnly }) => {
    let cardClass = "free-tier"
    let price = "$20"
    let text = <div className='tier-info'>"for independent bloggers or product trials"</div>

    if (tier === "Starter") {
        price = "$35"
        text = <div className='tier-info'>for small teams discovering audio creation"</div>
        cardClass = "pro-tier"
    } else if (tier === "Business") {
        price = "$75"
        text = <div className='tier-info'>"for medium-sized publications focused on audio accessibility"</div>
        cardClass = "corporate-tier"
    } else if (tier === "Enterprise") {
        cardClass = "enterprise-tier"
        price = "$200"
        text = <div className='tier-info'>"for publications with large content-volume"</div>
    }

    return (
        <div className={'tier-card ' + `${cardClass}`}>
            <div className="tier-card-title">{tier}</div>
            <div className="price-container">
                {tier !== "enterprise" && <div className="tier-card-price">{price}</div>}
                {tier === "enterprise" && <div className="tier-card-big-text contact-sales-text">Contact sales</div>}
                {tier !== "enterprise" && <div className="monthly-text">/month</div>}
            </div>
            {text}
            {!displayOnly &&
                <ProductButton
                    setSelectedProduct={setSelectedProduct}
                    handleClose={handleClose}
                    setDashboardMode={setDashboardMode}
                    disabled={usersCurrentTier ? !usersCurrentTier.includes(tier) : true}
                    tier={tier}
                    subDuration={subDuration}
                    priceId='price_1IjBMsJwn4ANsYedIwAxyMzg'
                />
            }

        </div>
    );
};

export default TierCard;