import React, { useRef, useCallback, useState, useEffect } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import CustomTooltip from "../../../../UI/CustomTooltip/CustomTooltip";
import Tooltip from "@mui/material/Tooltip";
import celebrationIcon from "./celebrate.png";
import { motion } from "framer-motion";
import Grid from "@material-ui/core/Grid";
import { createChartFilter } from "./functions/createChartFilter";
import TooltipMessage from "./TooltipMessage";
import QuartileChip from "./QuartileChip";
import PercentLoading from "./PercentLoading";
import QuartileLoading from "./QuartileLoading";
import PercentChange from "./PercentChange";

const sdk = new ChartsEmbedSDK({
	baseUrl: "https://charts.mongodb.com/charts-events-structure-eiwnq",
});

const CountChart = ({
	user,
	chartId,
	filterByOrgName,
	percentChange,
	lastDaysAmount,
	quartile,
	querying,
	usePublication,
	useDate,
	useLocalTime,
	showIcon,
	TimeListened,
}) => {
	let filter = createChartFilter({
		usePublication,
		useDate,
		useLocalTime,
		lastDaysAmount,
		routeIdentifier: user.routeIdentifier,
	});

	const chart = sdk.createChart({
		chartId,
		filter: filter,
		showAttribution: false,
	});

	const refChart = useRef(null);
	const renderChart = useCallback(
		async (ref) => {
			try {
				await chart.render(ref);
			} catch (e) {
				console.error(e);
			}
		},
		[lastDaysAmount]
	);

	const setRefChart = useCallback(
		(ref) => {
			if (ref) {
				renderChart(ref);
			}
			// Save a reference to the node
			refChart.current = ref;
		},
		[renderChart]
	);
	return (
		<div className="countChartContainer">
			<div className="countChart" ref={setRefChart}></div>
			{querying ? (
				<>
					{lastDaysAmount && <PercentLoading />}
					<QuartileLoading />
				</>
			) : (
				<>
					{percentChange && lastDaysAmount && (
						<>
							{!percentChange.includes("null") ? (
								<PercentChange change={percentChange} days={lastDaysAmount} />
							) : (
								<p className="countpercentChange">
									<b>Percentile data not yet available</b>
								</p>
							)}
						</>
					)}
					{typeof quartile === "number" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "15%",
								marginTop: lastDaysAmount ? "" : "20px",
							}}
						>
							<QuartileChip days={lastDaysAmount} quartile={quartile} />
							<CustomTooltip
								placement="right"
								message={
									<p style={{ lineHeight: "15px" }}>
										<TooltipMessage />
									</p>
								}
							/>
						</div>
					)}
					{showIcon && TimeListened > 0 && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "15%",
							}}
						>
							<Tooltip
								title={`That's like watching the film Jurrasic park ${TimeListened} times!`}
							>
								<Grid
									component={motion.div}
									whileHover={{ scale: 1.5 }}
									whileTap={{ scale: 1.0 }}
								>
									<div className="celebrationIcon">
										<img src={celebrationIcon} />
									</div>
								</Grid>
							</Tooltip>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default CountChart;
