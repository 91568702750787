import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import backendService from "../../../../services/backend-service";
import Spinner from "../../../UI/Spinner/Spinner";
// import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import "./ProfessionalEditing.css";
// Needed for certain elements
// import '../FileUpload/FileUpload.css'
// import '../FileUpload/UploadForm.css'
import { firebase } from "../../../../services/firebase-service";
import HistoryBar from "../../../UI/HistoryBar/HistoryBar";
import AudioSample from "../../../UI/AudioSample/AudioSample";
import MusicSelector from "../../../UI/MusicSelector/MusicSelector";
import NewNarrationButton from "../../../UI/NewNarrationButton/NewNarrationButton";
import PopupModal from "../../../UI/PopupModal/PopupModal";
import FileUploadField from "../../../UI/FileUploadField/FileUploadField";
import AutoCompleteField from "../../../UI/AutoCompleteField/AutoCompleteField";
import TextBoxField from "../../../UI/TextBoxField/TextBoxField";
import TextField from "../../../UI/TextField/TextField";
import { COLORS } from "../../../../constants/theme";
import { registerdEvents, logEvent } from "../../../../logging/eventLogger";
import { CUSTOM_VOICE_TYPES } from "../../../../constants/constants";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useWindowSize } from "../../../../helpers/get-window-size";

const TONES = [
  { name: "Calm" },
  { name: "Cheerful" },
  { name: "Curious" },
  { name: "Dramatic" },
  { name: "Melancholy" },
  { name: "Ominous" },
];

/* Filename: ProfessionalEditing.js
 * Description: A file upload widget to be housed in the Dashboard.js component
 * Sends a post request to the backend with the uploaded file (currently only one file at a time).
 */

const ProfessionalEditing = ({ onHomeClicked }) => {
  const [title, setTitle] = useState("");
  const [comments, setComments] = useState("");
  const [titleVoice, setTitleVoice] = useState("");
  const [bodyVoice, setBodyVoice] = useState("");
  const [bgMusic, setBgMusic] = useState("");
  const [tone, setTone] = useState("");
  const [customStatus, setCustomStatus] = useState("");
  const user = useSelector((state) => state.user);

  const logNewCustomNarration = () => {
    const loggingData = {
      title: title,
      titleVoice: titleVoice,
      bodyVoice: bodyVoice,
      comments: comments,
    };
    logEvent(registerdEvents.createCustomNarration, user, loggingData);
  };

  const handleSubmit = async (files, allFiles) => {
    console.log(files, allFiles);
    setCustomStatus("querying");

    const formData = new FormData();
    formData.append("file", files[0].file);
    formData.append("id", user.id);
    formData.append("organisationId", user.organisationId);
    formData.append("email", user.email);
    formData.append("username", user.username);
    formData.append("title", title);
    formData.append("comments", comments);
    formData.append("tone", tone);
    formData.append("bgMusic", bgMusic);
    formData.append("titleVoice", titleVoice);
    formData.append("bodyVoice", bodyVoice);
    formData.append("user", JSON.stringify(user));
    formData.append("orgName", user.organisation);

    const token = await firebase.auth().currentUser.getIdToken();
    formData.append("token", token);

    allFiles.forEach((f) => f.remove());
    /** @todo add a loading spinner here */
    try {
      let fileResult = await backendService.uploadFile(formData, false);
      console.log(fileResult);
      setCustomStatus("success");
      setComments("");
    } catch (error) {
      setCustomStatus("error");
      if (error.response) {
        console.log(error.response);
      } else {
        console.log(error);
      }
    }
  };

  const size = useWindowSize();

  return (
    <div
      id="professional-editing-page"
      className="page"
      data-testid="upload-container"
    >
      <HistoryBar
        items={[
          {
            text: "Home",
            click: onHomeClicked,
          },
        ]}
        currentItem={"Professional Editing"}
      />

      <PopupModal
        open={customStatus}
        status={customStatus}
        successContent={{
          title: "Producing Customized Narration",
          body: (
            <div>
              <p>
                Custom narrations can take up to{" "}
                <b style={{ color: "var(--secondary-label-color)" }}>
                  24 hours
                </b>{" "}
                to make. You will be notified by email once it’s ready, and you
                can find the narration at “My Narration”
              </p>
              <button
                className="upgrade-button"
                onClick={() => setCustomStatus("")}
              >
                Got it
              </button>
            </div>
          ),
        }}
        errorContent={{
          title: "Error",
          body: (
            <Fragment>
              <p>Something went wrong, please try again</p>
            </Fragment>
          ),
        }}
        handleClose={() => {
          setCustomStatus("");
        }}
      />

      <span>
        Customize your narration with background music, multiple narrators, and
        article summaries.
      </span>
      <b>Turnaround time: 24 hours</b>

      <div
        className="row-container"
        style={{ justifyContent: "flex-start", alignItems: "center" }}
      >
        <h2 className="icon">Listen to a sample</h2>
        <AudioSample audioName="Example Professional" />
      </div>

      <span className="row-container" style={{ alignItems: "center" }}>
        <h2>Book a Consultation</h2>{" "}
        <i>(skip this step if consultation has already been completed)</i>
      </span>
      <span>
        To get started you will meet with a Sound Designer to discuss your
        desired outcome for your audio product.
      </span>

      <div
        className="row-container"
        style={{ justifyContent: "center", width: "100%" }}
      >
        <h1 className="pointer">
          {">"}{" "}
          <a
            href="https://calendly.com/logan-underwood/45min"
            target="_blank"
            rel="noopener noreferrer"
            className="icon  clickable-text-medium sync-new-feed"
          >
            Schedule Consultation
          </a>
        </h1>
      </div>

      <h2>Submit Article for Professional Editing</h2>

      <div className="row-container professional">
        <div className="column">
          <h1>1. Upload Article</h1>
          <div
            className="row-container justify-start horizontal-center"
            style={{ width: "70%" }}
          >
            <div
              className="input-label"
              style={{ marginTop: "20px", marginBottom: "-20px" }}
            >
              Title
            </div>
          </div>
          <div className="input-with-sample audio-select">
            <TextField
              value={title}
              setValue={setTitle}
              label="Title"
              width={300}
            />
          </div>
          <div
            className="horizontal-center"
            style={{ width: size.width <= 1440 ? "94%" : "73%" }}
          >
            <FileUploadField handleSubmit={handleSubmit} width="100%" />
          </div>
        </div>

        <div className="column">
          <h1>2. Basic Audio Settings</h1>
          <div style={{ marginLeft: "30px" }}>
            <div className="row-container justify-start">
              <div className="input-label" style={{ marginTop: "20px" }}>
                Title Voice
              </div>
            </div>
            <div
              className="input-with-sample audio-select"
              style={{ justifyContent: "flex-start" }}
            >
              <AutoCompleteField
                value={titleVoice}
                setValue={setTitleVoice}
                label="Title Voice"
                width={300}
                options={CUSTOM_VOICE_TYPES}
              />
              <AudioSample audioName={titleVoice} />
            </div>
            <div className="row-container justify-start">
              <div className="input-label" style={{ marginTop: "20px" }}>
                Body Voice
              </div>
            </div>
            <div
              className="input-with-sample audio-select"
              style={{ justifyContent: "flex-start" }}
            >
              <AutoCompleteField
                value={bodyVoice}
                setValue={setBodyVoice}
                label="Body Voice"
                options={CUSTOM_VOICE_TYPES}
                width={300}
              />
              <AudioSample audioName={bodyVoice} />
            </div>
            <div className="row-container justify-start">
              <div className="input-label" style={{ marginTop: "20px" }}>
                Tone of Article
              </div>
            </div>
            <div
              className="input-with-sample audio-select"
              style={{ justifyContent: "flex-start" }}
            >
              <AutoCompleteField
                value={tone}
                setValue={(e) => {
                  setTone(e);
                  setBgMusic(e);
                }}
                label="Tone"
                options={TONES}
                width={300}
              />
              <AudioSample audioName={null} />
            </div>
            <div
              className="row-container justify-start"
              style={{ marginTop: "20px" }}
            >
              <div className="input-label">Background Music</div>
            </div>
            <div
              className="input-with-sample audio-select"
              style={{ justifyContent: "flex-start" }}
            >
              <MusicSelector
                narrationTier={"custom"}
                music={bgMusic}
                width={size.width <= 1440 ? 280 : 300}
                setMusic={setBgMusic}
              />
            </div>
          </div>
          <div style={{ marginTop: "50px" }}>
            <NewNarrationButton
              onClick={() => {
                const submitButtons =
                  document.getElementsByClassName("dzu-submitButton");

                if (submitButtons[0]) {
                  submitButtons[0].dispatchEvent(
                    new MouseEvent("click", {
                      view: window,
                      bubbles: true,
                      cancelable: true,
                      buttons: 1,
                    })
                  );
                }
              }}
              disabled={!(title && titleVoice && bodyVoice)}
              label="submit"
            />
          </div>
        </div>

        <div className="column">
          <h1>Any Additional Comments?</h1>
          <div
            className="empty-space"
            style={{ marginTop: size.width <= 1440 ? "28px" : "60px" }}
          >
            .
          </div>
          <TextBoxField
            value={comments}
            setValue={setComments}
            label="Comments"
            placeholder="Please leave any additional comments for your Sounds Designer"
          />
        </div>
      </div>

      <div className="empty-space">_</div>

      <div className="empty-space">_</div>
    </div>
  );
};

export default ProfessionalEditing;
