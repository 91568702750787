import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { color } from "@material-ui/system";

const theme = createTheme({
	palette: {
		primary: {
			main: "#76ff03",
		},
	},
});

const AutoCompleteField = ({ value, setValue, width, options, label }) => {
	return (
		// <ThemeProvider theme={theme}>
		<Autocomplete
			className="dropDown"
			onChange={(e, value) => (value ? setValue(value.name) : setValue(""))}
			options={options}
			getOptionLabel={(option) => option.name}
			style={{ width }}
			inputValue={value}
			value={value}
			renderInput={(params) => (
				<ThemeProvider theme={theme}>
					<TextField
						style={{
							color: "#76ff03",
							outlineColor: "#76ff03",
							borderColor: "#76ff03",
						}}
						// sx={{
						// 	color: "success",
						// 	outlineColor: "#76ff03",
						// 	borderColor: "#76ff03",
						// }}
						{...params}
						placeholder={label}
						color="success"
						variant="outlined"
					/>
				</ThemeProvider>
			)}
			test-id="upload-voice-select"
			color="success"
		/>
	);
};

export default AutoCompleteField;
