import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';


const UploadModal = ({showInvite, setShowInvite, joinCode}) => {
    const handleClose = () => setShowInvite(false)
    
    return (
        <Dialog
          open={showInvite}
          onClose={handleClose}
          aria-labelledby="invite-dialog-title"
          aria-describedby="invite-dialog-description"
        >
          <div id="upload-modal">
            <DialogContent>
              <DialogContentText id="invite-dialog-description">
                Ask your team to use this code when signing up
              </DialogContentText>
              <div id="invite-dialog-code">
                {joinCode}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Close
          </Button>
            </DialogActions>
          </div>
        </Dialog>
    );
};

export default UploadModal;