import React, { useState, useEffect } from 'react';
import SideBarItems from './SideBarItems/SideBarItems'
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import sideBarStyles from '../../../../constants/sideBarStyles'
import './SideBar.css'

const SideBar = ({
    onUploadMenuButtonClicked,
    onProfessionalButtonClicked,
    onFilesButtonClicked,
    onSettingsClicked,
    onHomeClicked,
    onAnalyticsClicked,
    mode
}) => {
    const classes = sideBarStyles();
    const [open, setOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(0)

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions)
        updateWindowDimensions()

        document.addEventListener('click', (event) => {
            if (event.target.id !== 'side-bar' && event.target.id !== 'side-bar-logo') {
                setOpen(false)
            }
        })

        return () => window.removeEventListener('resize', updateWindowDimensions)

    }, [])

    useEffect(() => {
        if (windowWidth < 1700) {
            setOpen(false)
            return
        }
        setOpen(true)
    }, [windowWidth])

    const updateWindowDimensions = () => {
        setWindowWidth(window.innerWidth)
    }

    const handleDrawerOpen = () => {
        if (open) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }
    return (
        <Drawer
            id='side-bar'
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
        >
            <Divider />
            <SideBarItems
                id='side-bar-content'
                mode={mode}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                onHomeClicked={onHomeClicked}
                onUploadMenuButtonClicked={onUploadMenuButtonClicked}
                onFilesButtonClicked={onFilesButtonClicked}
                onSettingsClicked={onSettingsClicked}
                onProfessionalButtonClicked={onProfessionalButtonClicked}
                onAnalyticsClicked={onAnalyticsClicked}
            />
        </Drawer>
    );
};

export default SideBar;