const { makeStyles } = require('@material-ui/core/styles');
const sideBarStyles = makeStyles((theme) => ({
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: 275,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    drawerHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    drawerLogo: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '30px',
        color: '#3F7045',
        fontWeight: '700',
        marginLeft: '10px',
        marginTop: '10px',
        marginBottom: '10px'
    }
}));
export default sideBarStyles;