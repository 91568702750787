import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Spinner from "../Spinner/Spinner";
import { ReactComponent as WarningUpdated } from "../Graphics/warningUpdated.svg";
import { ReactComponent as GreenCheck } from "../Graphics/greenCheck.svg";
import ClearIcon from "@material-ui/icons/Clear";
import "./PopupModal.css";
// import {useWindowSize} from '../../../helpers/get-window-size';
// const windowSize = useWindowSize();

const popupContent = (
  status,
  handleClose,
  optionalAction,
  infoContent,
  warningContent,
  errorContent,
  successContent
) => {
  switch (status) {
    case "info":
      return (
        <div className="popup-content">
          <h1>{infoContent.title}</h1>
          {infoContent.body}
        </div>
      );
    case "success":
      return (
        <div className="popup-content">
          <h1>{successContent.title}</h1>
          <GreenCheck />
          <div style={{ marginTop: "-100px" }}>{successContent.body}</div>
        </div>
      );
    case "warning":
      return (
        <div className="popup-content">
          <h1 className="warning">{warningContent.title}</h1>
          <div className="warning-icon">
            <WarningUpdated />
            {/* <Exclamation className='exclamation' /> */}
          </div>
          {warningContent.body}
        </div>
      );
    case "error":
      return (
        <div className="popup-content">
          <h1>{errorContent.title}</h1>
          <div className="warning-icon">
            <WarningUpdated />
            {/* <Exclamation className='exclamation' /> */}
          </div>
          {errorContent.body}
        </div>
      );
    default:
      return (
        <div className="popup-content">
          <Spinner />
        </div>
      );
  }
};

const PopupModal = ({
  open,
  status,
  handleClose,
  optionalAction,
  infoContent,
  warningContent,
  errorContent,
  successContent,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="iframe-dialog-container"
      maxWidth="lg"
      fullWidth={true}
    >
      <div
        className="pricing-close-button"
        onClick={handleClose}
        color="primary"
        autoFocus
      >
        <ClearIcon fontSize="large" />
      </div>
      <DialogContent className="popup-modal" style={{ width: "96%" }}>
        <div id="alert-dialog-description">
          {popupContent(
            status,
            handleClose,
            optionalAction || {},
            infoContent || {},
            warningContent || {},
            errorContent || {},
            successContent || {}
          )}
        </div>
      </DialogContent>
      <div className="row-container">
        {optionalAction ? (
          <Button onClick={optionalAction.function} color="primary" autoFocus>
            {optionalAction.text}
          </Button>
        ) : (
          <Fragment></Fragment>
        )}
      </div>
    </Dialog>
  );
};

export default PopupModal;
