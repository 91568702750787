import React, { useRef, useState, useEffect } from 'react';
import { mdiPlayCircle, mdiPauseCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import './AudioSample.css'
import { COLORS } from '../../../constants/theme'
import calm from './calm.mp3'
import curious from './curious.mp3'
import dramatic from './dramatic.mp3'
import cheerful from './beats_harp_clip.mp3'
import ominous from './ominous_gathering_stasis_clip.mp3'
import melancholy from './melancholy_clip.mp3'

const AudioSample = ({ audioName }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)
  const [aurdioSrc, setAudioSrc] = useState('')
  useEffect(() => {
    setIsPlaying(false)
    audioRef.current.pause()
    audioRef.current.load()
    audioRef.current.addEventListener('ended', (event) => {
      setIsPlaying(false)
    });

    switch (audioName) {
      case 'British Male':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_Brian.mp3')
        break;
      case 'British Female':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_%20Amy.mp3')
        break;
      case 'US Female':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_%20Joanna.mp3')
        break;
      case 'US Male':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_%20Matthew.mp3')
        break;
      case 'Australian Male':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_%20Russell.mp3')
        break;
      case 'Australian Female':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_%20Olivia.mp3')
        break;
      case 'Indian Female':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_Aditi.mp3')
        break;
      case 'Indian Male':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_en-IN-Wavenet-B.mp3')
        break;
      case 'French Female (CA)':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_fr-CA-Wavenet-C.mp3')
        break;
      case 'French Male (CA)':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_fr-CA-Wavenet-B_1619555857481.mp3')
        break;
      case 'French Female (FR)':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_fr-FR_ReneeV3Voice_1619555902749.mp3')
        break;
      case 'French Male (FR)':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_fr-FR_NicolasV3Voice_1619555964264.mp3')
        break;
      case 'Spanish Female (EE. UU.)':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_es-LA_SofiaV3Voice.mp3')
        break;
      case 'Spanish Male (ES)':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/SAMPLE_es-ES_EnriqueV3Voice.mp3')
        break;
      case 'Default Track':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/audio-samples/Denzel_Sprak_Fade_18s.mp3')
        break;
      case 'Example Custom':
        setAudioSrc('https://storage.googleapis.com/ad-auris-mvp-bucket/data-dashboard-narrations/The%20Poem%20Has%20to%20Discover%20Things%20An%20Interview%20with%20Jericho%20Brown.mp3')
        break;
      case 'Example Professional': 
        setAudioSrc('https://d3ctxlq1ktw2nl.cloudfront.net/staging/2021-05-19/1e7af08b94d0c995dc62213752919b6a.m4a')
        break
      case 'Calm':
        setAudioSrc(calm)
        break;
      case 'Curious':
        setAudioSrc(curious)
        break;
      case 'Dramatic':
        setAudioSrc(dramatic)
        break;
      case 'Cheerful':
        setAudioSrc(cheerful)
        break;
      case 'Ominous':
        setAudioSrc(ominous)
        break;
      case 'Melancholy':
        setAudioSrc(melancholy)
        break;
      default:
        setAudioSrc('')
    }
  }, [audioName])

  const playAudio = () => {
    if (!audioName) {
      return
    }
    const audioEl = audioRef.current
    if (isPlaying) {
      audioEl.pause()
      setIsPlaying(false)
    } else {
      audioEl.play()
      setIsPlaying(true)
    }
  }
  let icon = null;
  if (!audioName) {
    icon = null
  } else if (isPlaying) {
    icon = <Icon id='mdiPauseCircleOutline' path={mdiPauseCircleOutline} color={COLORS.primary} size={2} />
  } else {
    icon = <Icon id='small-play-icon' path={mdiPlayCircle} color={COLORS.primary} size={2} />
  }


  return (
    <div onClick={playAudio} className='audio-btn'>
      {icon}
      <audio className="audio-element" ref={audioRef}>
        <source src={aurdioSrc}></source>
      </audio>
    </div>
  );
};

export default AudioSample;