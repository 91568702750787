/* Filename: RssFeedInsert.js
 * Description: Inserts a new rss feed.
 */

import { React, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import backendService from "../../../../services/backend-service";
import { firebase } from "../../../../services/firebase-service";
import Button from "@material-ui/core/Button";
import HistoryBar from "../../../UI/HistoryBar/HistoryBar";
import AudioSample from "../../../UI/AudioSample/AudioSample";
import CustomTooltip from "../../../UI/CustomTooltip/CustomTooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PopupModal from "../../../UI/PopupModal/PopupModal";
import "./RssFeedInsert.css";
import { generateRssIframe } from "../../../../helpers/iframe-code-generator";
import {
  CUSTOM_VOICE_TYPES,
  CUSTOM_VOICE_IDS,
} from "../../../../constants/constants";
import TextField from "../Reusable/TextField";
import CheckedSwitch from "../Reusable/Switch";
import AutoComplete from "../Reusable/AutoComplete";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  textField: {
    width: "55%",
    marginLeft: "auto",
    marginRight: "10px",
    paddingBottom: 0,
    marginTop: "9px",
    fontWeight: 500,
  },
}));

export default function RssFeedInsert({
  onHomeClicked,
  onSettingsClicked,
  onRssClicked,
}) {
  const [feed, setFeed] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [requestState, setRequestState] = useState("");
  const [err, setErr] = useState("");

  const user = useSelector((state) => state.user);

  const handleClose = () => {
    setRequestState("");
  };

  const newRss = async () => {
    setRequestState("querying");

    console.log(feed.length > 0);

    console.log(title.length > 0);

    console.log(body.length > 0);

    console.log(name.length > 0);

    if (
      feed.length > 0 &&
      title.length > 0 &&
      body.length > 0 &&
      name.length > 0
    ) {
      const token = await firebase.auth().currentUser.getIdToken();
      console.log({
        feedUrl: feed,
        websiteUrl: null,
        titleVoice: title,
        narrationType: body,
        organisationId: user.organisationId,
        id: user.id,
        name: name,
        token,
        categoryTitle: CUSTOM_VOICE_IDS[title],
        categoryBody: CUSTOM_VOICE_IDS[body],
      });
      backendService
        .newRss({
          feedUrl: feed,
          websiteUrl: null,
          titleVoice: title,
          narrationType: body,
          organisationId: user.organisationId,
          id: user.id,
          name: name,
          token,
          categoryTitle: CUSTOM_VOICE_IDS[title],
          categoryBody: CUSTOM_VOICE_IDS[body],
        })
        .then((res) => {
          console.log(res);
          setRequestState("success");
        })
        .catch((err) => {
          console.log(err);
          if (err.message === "Request failed with status code 400") {
            setErr(
              "Could not find any articles in your feed, please check your input and try again"
            );
          } else if (err.message === "Request failed with status code 403") {
            setErr("You already have a feed with this url");
          } else {
            setErr(err.message);
          }
          setRequestState("error");
        });
    }
  };

  const classes = useStyles();
  return (
    <div id="rss-feed-insert" className="page">
      <HistoryBar
        items={[
          {
            text: "Home",
            click: onHomeClicked,
          },
          {
            text: "Settings",
            click: onSettingsClicked,
          },
        ]}
        currentItem="Sync New Feed"
      />

      <PopupModal
        open={requestState}
        status={requestState}
        successContent={{
          title: `Auto Narrate is now ready for ${name}`,
          body: (
            <div className="row-container justify-end full-width">
              <h1 className="pointer" onClick={onRssClicked}>
                <span className="icon clickable-text-medium">
                  Go to My RSS Feeds
                </span>
              </h1>
            </div>
          ),
        }}
        errorContent={{
          title: "Error",
          body: <p>{err}</p>,
        }}
        handleClose={handleClose}
      />

      <form
        className={classes.form}
        id="new-rss-form"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          newRss();
        }}
      >
        <div id="upload-form-top">
          <h1>1. Set-up RSS Feed</h1>
          <i className="rss-label-description">
            Your RSS feed will be scanned every 30 minutes for new articles to
            be narrated.
          </i>
          <div className="new-rss-options">
            <div className="row-container rss-input-field">
              <h2>RSS URL:</h2>
              {/* <TextFieldComponent
								value={feed}
								setValue={setFeed}
								width={500}
								label="Paste RSS Feed URL"
							/> */}
              <TextField
                preFilledText={"Paste RSS Feed URL"}
                value={feed}
                setValue={setFeed}
              />
              <CustomTooltip
                link="https://www.ad-auris.com/post/rss-feeds-and-how-to-find-yours"
                message="An RSS feed is an .xml file that contains your newest content. If you don’t know your RSS feed, click this icon to read a quick guide."
              />
            </div>
            <div className="row-container">
              <h2>RSS Feed Title:</h2>
              <TextField
                preFilledText={"Create a title for this RSS Feed"}
                value={name}
                setValue={setName}
              />
              <CustomTooltip message="Choose a name that will be easy for you to identify which content is in the RSS feed" />
            </div>
          </div>
          <div className="empty-space">.</div>

          <h1>2. Narration Settings</h1>
          <i className="rss-label-description">
            These will be the voices narrating your rss feed
          </i>
          <div className="new-rss-options">
            <div className="new-rss row-container rss-input-field">
              <h2>Title Voice:</h2>
              <AutoComplete
                value={title}
                setValue={setTitle}
                label="Title Voice"
                width={500}
                options={CUSTOM_VOICE_TYPES}
              />

              <AudioSample audioName={title} />
            </div>
            <div className="row-container">
              <h2>Body Voice:</h2>
              <AutoComplete
                value={body}
                setValue={setBody}
                label="Body Voice"
                width={500}
                options={CUSTOM_VOICE_TYPES}
              />
              <AudioSample audioName={body} />
            </div>
          </div>
          <div
            className="row-container horizontal-center"
            style={{ width: "20%", marginTop: "20px" }}
          >
            <button
              id="new-rss-button"
              variant="contained"
              type="submit"
              color="primary"
            >
              Save new RSS
            </button>
          </div>
          {/* <div className="row-container podcast-label">
						<h1>3. Auto-add to Podcast</h1>
						<CheckedSwitch />
					</div> */}
          <h1>3. Embed Audio Player</h1>
          <i className="rss-label-description">
            By embeding this audio player code onto your Content Managaement
            System template, the narration will automatically appear on every
            new article.
          </i>
          <div className="row-container rss-code">
            <h2>iframe for Audio Player:</h2>
            <span className="iframe-code-container rss">[iframe code]</span>
            <CopyToClipboard
              text={generateRssIframe(user.organisationId, user.organisation)}
              onCopy={() => alert("Your iframe code has been copied!")}
            >
              <div>
                <Button id="iframe-copy-button" color="primary" autoFocus>
                  copy code
                </Button>
              </div>
            </CopyToClipboard>
          </div>
          <div
            className="row-container"
            style={{ color: "var(--font-label-color)" }}
          >
            <div className="column iframe-instructions row-container">
              <div style={{ fontWeight: "bolder" }}>
                To embed your narration follow these steps or watch this{" "}
                <a
                  href="https://www.youtube.com/watch?v=7tTElQmPjs4&t=2s"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  video
                </a>{" "}
                :
              </div>
              <div>1. Copy the iframe code in the box above.</div>
              <div>
                2. In your CMS article template, paste the iframe code in the
                HTML editor.
              </div>
              <div>
                3. Now, every new article published will automatically be
                accessible via an audio widget!
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="empty-space">.</div>
    </div>
  );
}
