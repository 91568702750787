// Description: A page that lists all the user's uploaded files and their current status

import { React, useState, useEffect } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { useSelector } from "react-redux";
import FileList from "../FileList/FileList";
import AudioSample from "../../../UI/AudioSample/AudioSample";
import HistoryBar from "../../../UI/HistoryBar/HistoryBar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@material-ui/core/Button";
import "./RssView.css";
import CustomTooltip from "../../../UI/CustomTooltip/CustomTooltip";
import { CUSTOM_VOICE_TYPES, CUSTOM_VOICE_IDS } from "../../../../constants/constants";
import AutoCompleteField from "../../../UI/AutoCompleteField/AutoCompleteField";
import PopupModal from "../../../UI/PopupModal/PopupModal";
import { generateRssIframe } from "../../../../helpers/iframe-code-generator";
import CategoryList from "./CategoryList";
const {
  sortPriority,
  changeVoices,
  deleteCategory,
  createNewCategory,
  updateCategoryVoice,
  updateCategoryNarrate,
  updateCategoryPriority,
} = require('./functions/categoryModifications');

const RssView = ({
  onIndividualRssClicked,
  onRssClicked,
  setMode,
  onSettingsClicked,
  onHomeClicked,
}) => {
  const rss = useSelector((state) => state.analytics.currentRss) || {};
  const stateCategories = useSelector((state) => state.analytics.categories);
  const [categories, setCategories] = useState(
    (
      stateCategories.filter((category) => {
        return (category.rss_feed_id === rss.id && category.priority !== null)
      })
        .sort(sortPriority)
    )
  );
  const [categoryOptions, setCategoryOptions] = useState(
    stateCategories.filter((category) => {
      return (category.priority === null && category.name !== null && !category.name.includes("_base_category"))
    })
  );
  const [selectedOption, setSelectedOption] = useState("");
  const user = useSelector((state) => state.user);
  const [titleVoice, setTitleVoice] = useState(rss.title_voice);
  const [bodyVoice, setBodyVoice] = useState(rss.body_voice);
  const [popupStatus, setPopupStatus] = useState("");
  const [deletePopupStatus, setDeletePopupStatus] = useState("");
  const [showCategoryWarning, setShowCategoryWarning] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState("");
  const [errorPopupTitle, setErrorPopupTitle] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});

  useEffect(() => {
    setTitleVoice(rss.title_voice);
    setBodyVoice(rss.body_voice);
  }, [rss]);

  const openDeletePopup = (cat) => {
    setDeletePopupStatus('warning')
    setSelectedCategory(cat)
  }

  const openErrorPopup = (errorMessage) => {
    setErrorPopupTitle(errorMessage);
    setShowErrorPopup('error');
  }

  return (
    <div id="rss-view" className="page">
      <HistoryBar
        items={[
          {
            text: "Home",
            click: onHomeClicked,
          },
          {
            text: "Settings",
            click: onSettingsClicked,
          },
          {
            text: "My Feeds",
            click: onRssClicked,
          },
        ]}
        currentItem={rss.name}
      />
      <PopupModal
        open={popupStatus}
        status={popupStatus}
        successContent={{
          title: `Default voices for ${rss.name} changed!`,
          body: (
            <span>
              Your edits have been registered and will render over the next few
              minutes. Changes will effect only narrations moving forward.
            </span>
          ),
        }}
        handleClose={() => {
          setPopupStatus("");
        }}
      />
      <PopupModal
        open={deletePopupStatus}
        status={deletePopupStatus}
        warningContent={{
          title: `Are you sure you want to delete this category?`,
          body: (
            <>
              <p>
                This action can't be reversed
              </p>
              <button
                style={{ zIndex: 99999, marginBottom: '100px' }}
                onClick={() => {
                  setDeletePopupStatus("querying");
                  deleteCategory({ organisationId: user.organisationId, rssId: rss.id, selectedCategory, categories, setDeletePopupStatus, setCategories, openErrorPopup })
                }}
                className="delete-button-popup"
              >
                Delete
              </button>
            </>
          ),
        }}
        handleClose={() => {
          setDeletePopupStatus("");
        }}
      />
      <PopupModal
        open={showErrorPopup}
        status={showErrorPopup}
        errorContent={{
          title: errorPopupTitle,
          body: (
            <p style={{ marginBottom: '100px' }}>
              Please try again later or contact us
            </p>
          ),
        }}
        handleClose={() => {
          setShowErrorPopup("");
        }}
      />
      <PopupModal
        open={showCategoryWarning}
        status={showCategoryWarning}
        warningContent={{
          title: `You've hit your 3 category limit`,
          body: (
            <p style={{ marginBottom: '100px' }}>
              At this time, you may only select 3 categories for special narration settings.
            </p>
          ),
        }}
        handleClose={() => {
          setShowCategoryWarning("");
        }}
      />

      <div className="row-container">
        <div className="rss-view-settings">
          <h1>Narration Settings</h1>
          <i>
            These will be the voices narrating the articles in your RSS feed
          </i>
          <h3
            className="row-container"
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <span>Title Voice: </span>
            <AutoCompleteField
              value={titleVoice}
              setValue={setTitleVoice}
              label="Title Voice"
              width={"80%"}
              options={CUSTOM_VOICE_TYPES}
            />
            <AudioSample audioName={titleVoice} />
          </h3>
          <h3
            className="row-container"
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <span>Body Voice: </span>
            <AutoCompleteField
              value={bodyVoice}
              setValue={setBodyVoice}
              label="Body Voice"
              width={"80%"}
              options={CUSTOM_VOICE_TYPES}
            />
            <AudioSample audioName={bodyVoice} />
          </h3>
          {(titleVoice !== rss.title_voice || bodyVoice !== rss.body_voice) &&
            bodyVoice !== "" &&
            titleVoice !== "" ? (
            <button className="form-button rss" onClick={() => {
              changeVoices({ setPopupStatus, rssId: rss.id, titleVoice, bodyVoice, organisationId: user.organisationId })
            }}>
              save
            </button>
          ) : (
            <button className="form-button rss disabled">save</button>
          )}
        </div>
        <div className="rss-view-settings">
          <h1>Audio Player Iframe</h1>
          <i>Embed this code in your CMS template</i>

          <div
            className="row-container rss-code"
            style={{ marginLeft: "0", width: "100%" }}
          >
            <span className="iframe-code-container rss">
              {generateRssIframe(user.organisationId, user.organisation)}
            </span>
            <CopyToClipboard
              text={generateRssIframe(user.organisationId, user.organisation)}
              onCopy={() => alert("Your iframe code has been copied!")}
            >
              <div>
                <Button id="iframe-copy-button" color="primary" autoFocus>
                  copy iframe
                </Button>
              </div>
            </CopyToClipboard>
            <CustomTooltip message="This iframe code will only work for narrations listed on this page. If you choose to narrate an article outside of this RSS feed, you will need to embed a separate iframe code." />
          </div>
        </div>
      </div>

      <div className="row-container category-container">
        <h1>RSS Categories</h1>
        <div className="column-container" style={{ width: '30%', marginLeft: '10px' }}>
          <AutoCompleteField
            value={selectedOption}
            setValue={setSelectedOption}
            label="Category"
            width={"100%"}
            options={categoryOptions}
          />
          <div className="row-container">
            <i>Can't find a category?</i>
            <CustomTooltip message={(
              <p>Categories are case and whitespace sensitive, and are taken from your RSS feed. More info <a href='https://www.ad-auris.com/post/rss-feed-categories' target='_blank' rel="noreferrer">here</a>.</p>
            )} />
          </div>
        </div>
        <Button
          id="iframe-copy-button"
          className="category-add-button"
          color="primary"
          disabled={!selectedOption}
          onClick={() => {
            createNewCategory({
              name: selectedOption,
              rssId: rss.id,
              categories,
              categoryOptions,
              organisationId: user.organisationId,
              setSelectedOption,
              setCategories,
              setShowCategoryWarning,
              openErrorPopup
            })
          }}
        >
          add
        </Button>
      </div>

      <CategoryList
        defaultTitleVoice={titleVoice}
        defaultBodyVoice={bodyVoice}
        updateVoice={updateCategoryVoice}
        updatePriority={updateCategoryPriority}
        updateNarrate={updateCategoryNarrate}
        deleteCategory={openDeletePopup}
        categories={categories}
        organisationId={user.organisationId}
        rssId={rss.id}
        setCategories={setCategories}
        openErrorPopup={openErrorPopup}
      />

      <FileList
        rss={true}
        setMode={setMode}
        onNarrationsClicked={() => {
          onIndividualRssClicked(rss.id, rss);
        }}
      />
    </div>
  );
};

export default RssView;
