import React from "react";
import "./Spinner.css";
import { css } from "@emotion/react";

import BeatLoader from "react-spinners/BeatLoader";
import { COLORS } from "../../../constants/theme";

// old Spinner
// const Spinner = () => {
//     return (
//         <div className="lds-hourglass"></div>
//     );
// };

// export default Spinner;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Spinner = ({ overrideColor }) => {
  let color = COLORS.primary;

  if (overrideColor) {
    color = overrideColor;
  }

  return (
    <div className="loading-spinner">
      <BeatLoader color={color} loading={true} size={30} />
    </div>
  );
};

export default Spinner;
