import { React, Fragment, useState, useEffect } from "react";
import "./Analytics.css";
import HistoryBar from "../../../UI/HistoryBar/HistoryBar";
import DataVisualChart from "./Charts/DataVisualChart";
import CountChart from "./Charts/CountChart";
import AverageChart from "./Charts/AverageChart";
import { useWindowSize } from "../../../../helpers/get-window-size";
import chartIds from "./charts.json";
import timeSpecificCharts from "./timeSpecificCharts.json";
import axios from "axios";
import TimeButton from "./TimeButton";
const { cleanMongoData } = require("./Charts/functions/cleanMongoData");
import {
	percentCall,
	quartileCall,
	avgPercentCall,
	avgQuartileCall,
} from "./CallsForAxios";

const Analytics = ({ onHomeClicked, user }) => {
	const baseUrl = `https://ad-auris-logging-db-staging-l72twop3ra-uc.a.run.app/publisher/${user.routeIdentifier}`;
	const { width, height } = useWindowSize();
	const [days, setDays] = useState(null);
	const [averageListen, setAverageListen] = useState(null);
	const [averageMinutes, setAverageMinutes] = useState(null);
	// dimension: percentage-change
	const [percentNarrated, setNarratedPercent] = useState(null);
	const [percentListens, setListensPercent] = useState(null);
	const [percentTimeListened, setTimeListenedPercent] = useState(null);
	const [percentAverageListens, setAverageListensPercent] = useState(null);
	const [percentAverageTimeListened, setAverageTimeListenedPercent] =
		useState(null);
	//dimension: quantile
	const [quantileNarrated, setNarratedQuantile] = useState(null);
	const [quantileListens, setListensQuantile] = useState(null);
	const [quantileTimeListened, setTimeListenedQuantile] = useState(null);
	const [quantileAverageListens, setAverageListensQuantile] = useState(null);
	const [quantileAverageTimeListened, setAverageTimeListenedQuantile] =
		useState(null);

	const [querying, setQuerying] = useState(true);
	const [newData, setNewData] = useState(null);
	const [TimeListened, setTimeListened] = useState(null);

	const setDataStates = (data) => {
		setNarratedPercent(
			data.narratedPercentage >= 0
				? `+${data.narratedPercentage}%`
				: `-${data.narratedPercentage}%`
		);
		setListensPercent(
			data.listensPercentage >= 0
				? `+${data.listensPercentage}%`
				: `-${data.listensPercentage}%`
		);
		setTimeListenedPercent(
			data.timeListenedPercentage >= 0
				? `+${data.timeListenedPercentage}%`
				: `-${data.timeListenedPercentage}%`
		);
		setNarratedQuantile(data.narratedQuantile.toString());
		setListensQuantile(data.listensQuantile.toString());
		setTimeListenedQuantile(data.timeListenedQuantile.toString());
		setAverageListen(data.averageListens);
		setAverageMinutes(data.averageMinutes);
		setAverageListensQuantile(data.averageListensQuantile);
		setAverageTimeListenedQuantile(data.averageMinutesQuantile);
		setTimeListened(data.timeListened);
	};

	useEffect(() => {
		setQuerying(true);
		setAverageListen(null);
		setAverageMinutes(null);
		const query = async () => {
			const mongoData = {};
			// Chart 1 calls
			const percentNarrated = await percentCall(
				"narrations-created",
				baseUrl,
				days
			);
			const quantileNarrated = await quartileCall(
				"narrations-created",
				baseUrl,
				days
			);

			// Chart 2 calls
			const percentListens = await percentCall("listens", baseUrl, days);
			const quantileListens = await quartileCall("listens", baseUrl, days);
			// Chart 3 calls
			const percentTimeListened = await percentCall(
				"time-listened",
				baseUrl,
				days
			);
			const quantileTimeListened = await quartileCall(
				"time-listened",
				baseUrl,
				days
			);
			// Chart 4 calls
			const listensAverage = await avgPercentCall("listens", baseUrl, days);
			const listensAverageQuantile = await avgQuartileCall(
				"listens",
				baseUrl,
				days
			);
			// Chart 5 calls
			const minutesAverage = await avgPercentCall(
				"time-listened",
				baseUrl,
				days
			);
			const minutesAverageQuantile = await avgQuartileCall(
				"time-listened",
				baseUrl,
				days
			);
			console.log("percentNarrated", percentNarrated);
			const minutesListened = await axios
				.get(`${baseUrl}/time-listened/total?days_ago=${days}`)
				.catch((err) => {
					console.log(err);
				})
				.catch((err) => {
					console.log(err);
				});

			mongoData.narratedPercentage = cleanMongoData(
				percentNarrated,
				"percentage_change"
			);
			mongoData.listensPercentage = cleanMongoData(
				percentListens,
				"percentage_change"
			);
			mongoData.timeListenedPercentage = cleanMongoData(
				percentTimeListened,
				"percentage_change"
			);
			mongoData.narratedQuantile = cleanMongoData(
				quantileNarrated,
				"percentile"
			);
			mongoData.listensQuantile = cleanMongoData(quantileListens, "percentile");
			mongoData.timeListenedQuantile = cleanMongoData(
				quantileTimeListened,
				"percentile"
			);
			mongoData.averageListens = cleanMongoData(listensAverage, "average");
			mongoData.averageMinutes = cleanMongoData(minutesAverage, "average");
			mongoData.averageListensQuantile = cleanMongoData(
				listensAverageQuantile,
				"percentile"
			);
			mongoData.averageMinutesQuantile = cleanMongoData(
				minutesAverageQuantile,
				"percentile"
			);
			mongoData.timeListened = cleanMongoData(minutesListened, "total");

			console.log(mongoData);

			return mongoData;
		};
		query().then((data) => {
			setNewData(data);
			setQuerying(false);
		});
	}, [days]);

	useEffect(() => {
		if (newData) {
			setDataStates(newData);
		}
	}, [newData]);

	return (
		<Fragment>
			<HistoryBar
				items={[
					{
						text: "Home",
						click: onHomeClicked,
					},
				]}
				currentItem={"Analytics"}
			/>
			<TimeButton days={days} setDays={setDays} />
			<div id="page analytics-page">
				<div className="charts-container counts">
					{/* articlesNarrated needs to be filtered differently hence use of filter as such*/}
					<CountChart
						user={user}
						chartId={chartIds.articlesNarrated}
						filterByOrgName={false}
						percentChange={percentNarrated}
						lastDaysAmount={days}
						quartile={Number(quantileNarrated)}
						querying={querying}
						usePublication={true}
						showIcon={false}
						TimeListened={TimeListened}
						useDate
					/>
					<CountChart
						user={user}
						chartId={
							days
								? timeSpecificCharts[days.toString()].totalMinutes
								: chartIds.totalMinutesListened
						}
						filterByOrgName={false}
						percentChange={percentTimeListened}
						lastDaysAmount={days}
						quartile={Number(quantileTimeListened)}
						querying={querying}
						showIcon={true}
						TimeListened={TimeListened}
					/>
					<CountChart
						user={user}
						chartId={chartIds.totalListens}
						filterByOrgName={false}
						percentChange={percentListens}
						lastDaysAmount={days}
						quartile={Number(quantileListens)}
						querying={querying}
						showIcon={false}
						TimeListened={TimeListened}
						useLocalTime
					/>
				</div>
				<div className="charts-container counts">
					<AverageChart
						label={"Average Listens per Narration"}
						value={averageListen}
						percentChange={percentAverageListens}
						lastDaysAmount={days}
						quartile={quantileAverageListens}
						querying={querying}
						days={days}
						usePublication={true}
					/>
					<AverageChart
						label={"Average Minutes Engaged / Narration"}
						value={averageMinutes}
						percentChange={percentAverageTimeListened}
						lastDaysAmount={days}
						quartile={quantileAverageTimeListened}
						querying={querying}
						days={days}
						usePublication={true}
					/>
				</div>
				<div className="charts-container top">
					<DataVisualChart
						user={user}
						chartId={chartIds.averageCompletions}
						width={"70%"}
						lastDaysAmount={days}
					/>
					<DataVisualChart
						user={user}
						chartId={chartIds.averageCompletionRate}
						width={"70%"}
						lastDaysAmount={days}
					/>
				</div>
				<div className="charts-container middle">
					{/* <DataVisualChart
						user={user}
						chartId={
							days
								? timeSpecificCharts[days.toString()].topNarrations
								: chartIds.topNarrations
						}
						width={"100%"}
						lastDaysAmount={days}
					/> */}
					<DataVisualChart
						user={user}
						chartId={
							days
								? timeSpecificCharts[days.toString()].topNarrationsv2
								: chartIds.topNarrationsv2
						}
						width={"100%"}
						lastDaysAmount={days}
						customFilter={
							{ '_id.publisher': user.routeIdentifier }
						}
					/>					
					<DataVisualChart
						user={user}
						chartId={chartIds.devicesUsedToListen}
						width={"70%"}
						lastDaysAmount={days}
					/>
				</div>
				<div className="charts-container bottom">
					<DataVisualChart
						user={user}
						chartId={chartIds.listenerSpike}
						width={"70%"}
						lastDaysAmount={days}
					/>
				</div>
				<div className="charts-container belowBottom">
					<DataVisualChart
						user={user}
						chartId={chartIds.listenLast3Months}
						width={"100%"}
						lastDaysAmount={days}
					/>
					<DataVisualChart
						user={user}
						chartId={chartIds.listenerLocations}
						width={"100%"}
						lastDaysAmount={days}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default Analytics;
