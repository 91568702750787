/** @description - backend-service.js
 *
 *  -A central location for all requests to the backend.
 *
 *  @todo
 *  @author Keith Ong
 */
import { firebase } from "../services/firebase-service";
import axios from "axios";
// const baseUrl = process.env.NODE_ENV === 'production' ? process.env.PRODUCTION_URL : 'http://localhost:5500';
import {
  baseUrl,
  podcastBaseUrl,
  internalApiUrl,
  CUSTOM_VOICE_ID_LABELS,
} from "../constants/constants";
import { IFRAME_URL } from "../constants/constants";
import React from "react";
const crypto = require("crypto");
const algorithm = "aes-256-ctr";
const secretKey = "dH3P0c9fjeAm4I9dcap0Wlgotn2jgJsS";
let iv = crypto.randomBytes(16);

const decrypt = (hash) => {
  const decipher = crypto.createDecipheriv(
    algorithm,
    secretKey,
    Buffer.from(hash.split(".")[0], "hex")
  );

  const decrpyted = Buffer.concat([
    decipher.update(Buffer.from(hash.split(".")[1], "hex")),
    decipher.final(),
  ]);

  return decrpyted.toString();
};

const decryptOrgId = (hash) => {
  if (typeof hash === "number") {
    return hash;
  }
  return Number(decrypt(hash));
};

/** @description - getAnalyticsToken
 *
 *  - requests the backend for a GAPI token
 *
 *  - Not the same as the backend token, this token is specific to google analytics and is used only once to initialize GAPI
 *
 */
const getAnalyticsToken = async () => {
  //The backend provides the GA token only if backend token is attached to request
  try {
    const token = await firebase.auth().currentUser.getIdToken();
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const response = await axios.get(
      baseUrl + "/api/serviceAccessToken",
      { params: { token } },
      config
    );
    return response.data.access_token;
  } catch (err) {}
};

const uploadFile = async (formData, instant) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  // const token = await firebase.auth().currentUser.getIdToken()

  console.log(formData);
  const response = await axios.post(
    baseUrl + "/upload/instantFile",
    formData,
    config
  );

  return response;
};

const uploadWidgetImage = async (formData, instant) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post(
    baseUrl + "/upload/widgetImage",
    { ...formData, token },
    config
  );
  return response;
};

const parseUrl = async (formData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  // const token = await firebase.auth().currentUser.getIdToken()

  const response = await axios.post(baseUrl + "/upload/url", formData, config);
  return response;
};

const instantNarration = async (formData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  // const token = await firebase.auth().currentUser.getIdToken()

  const response = await axios.post(
    baseUrl + "/upload/instantNarration",
    formData,
    config
  );
  return response;
};

const newRss = async (formData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await firebase.auth().currentUser.getIdToken();
  const response = await axios.post(
    baseUrl + "/rss/newRssFeed",
    { ...formData, token },

    config
  );
  return response;
};

const updateRss = async (formData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post(
    baseUrl + "/rss/updateFeed",
    { ...formData, token },
    config
  );
  return response;
};

const signup = async (userInfo) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    baseUrl + "/accounts/signup",
    userInfo,
    config
  );
  return response;
};

export const getTTSP = async (id, organisationId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post(
    baseUrl + "/dashboard/articles",
    { user_id: id, organisation_id: organisationId, token },
    config
  );

  return response.data;
};

export const getOriginalText = async (text) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.get(text);

  return response;
};

export const getRSS = async (organisationId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //Not sure why extra check is needed here, currentUser doesnt exist when logging out with large number of narrations
  // if (firebase.auth.currentUser) {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post(
    baseUrl + "/rss/rssFeeds",
    { organisationId: organisationId, token },
    config
  );
  // console.log(response)
  console.log("RSS RESPONSE");
  console.log(response.data);
  return response.data;
  // }
  return null;
};

export const getSingleRSS = async (rssId) => {
  console.log("GET TTTSP");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post(
    baseUrl + "/rss/singleFeed",
    { rssId: rssId, token },
    config
  );

  return response.data;
};

export const deleteRssFeed = async (rssId) => {
  console.log("DELETE RSS");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post(
    baseUrl + "/rss/deleteFeed",
    { rssId: rssId, token },
    config
  );

  return response.data;
};

export const deleteTTSP = async (ttsId) => {
  console.log("DELETE TTSP");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post(
    baseUrl + "/dashboard/deleteTTSP",
    { ttsId: ttsId, token },
    config
  );

  return response.data;
};

export const getSingleTSSP = async (
  organisation_id,
  ttsp_id,
  user_id,
  routeIdentifier,
  articleIdentifier,
  cb
) => {
  console.log("GET SINGLE TTSP");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const token = await firebase.auth().currentUser.getIdToken();
    console.log(token);
    if (routeIdentifier && articleIdentifier) {
      const iframeSource = `${IFRAME_URL}/${routeIdentifier}/${articleIdentifier}`;
      const realIframe = (
        <iframe
          title={`${articleIdentifier}`}
          style={{ width: "400px", height: "250px" }}
          src={iframeSource}
        ></iframe>
      );
      const iframeString = `<iframe frameborder='0' scrolling='no'; style='width: 400px; height: 250px;' src=${iframeSource}></iframe>`;
      cb(realIframe, iframeString, articleIdentifier);
    } else {
      const response = await axios.post(
        baseUrl + "/dashboard/singleArticle",
        { organisation_id, tts_package_id: ttsp_id, user_id, token },
        config
      );
      console.log(response.data);
      cb(response.data.iframe, null, null, response.data.audioFile);
    }
  } catch (err) {
    console.log(err);
  }
};

export const checkIfWidgetExists = async (
  routeIdentifier,
  articleIdentifier,
  tts_package_id,
  cb
) => {
  console.log("checking if widget exists");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const token = await firebase.auth().currentUser.getIdToken();

    await axios.post(
      baseUrl + "/dashboard/audio",
      { routeIdentifier, articleIdentifier, tts_package_id, token },
      config
    );
    cb(true);
  } catch (error) {
    cb(false);
  }
};

const checkIfUserExistsInBackend = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //Signup with backend
  const response = await axios.post(
    baseUrl + "/accounts/userCheck",
    { token },
    config
  );
  return response.data;
};

const checkIfOrgExists = async (organizationCode) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //Signup with backend
  const response = await axios.post(
    baseUrl + "/accounts/organizationCheck",
    { organizationCode },
    config
  );
  return response.data.organization;
};

const login = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await axios.post(
    baseUrl + "/accounts/login",
    { token },
    config
  );
  return response;
};

const newPodcast = async (formData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await axios.post(
    baseUrl + "/podcast/newPodcast",
    formData,
    config
  );
  return response.data;
};

const newEpisode = async (formData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await firebase.auth().currentUser.getIdToken();
  formData.token = token;
  const response = await axios.post(
    baseUrl + "/podcast/newEpisode",
    formData,
    config
  );
  return response.data;
};

const deleteEpisode = async (formData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await firebase.auth().currentUser.getIdToken();
  formData.token = token;
  const response = await axios.post(
    baseUrl + "/podcast/deleteEpisode",
    formData,
    config
  );
  return response.data;
};

const uploadPodcastImage = async (formData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  console.log("UPLOAD FILE");

  const response = await axios.post(
    baseUrl + "/upload/podcastImage",
    formData,
    config
  );
  return response.data;
};

const checkPodcast = async (formData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await axios.post(
    baseUrl + "/podcast/exists",
    formData,
    config
  );
  return response.data;
};

const getLatestSubscriptionData = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post(
    baseUrl + "/accounts/subscription-data",
    { token },
    config
  );
  console.log(response.data);
  return response.data.subscriptionData;
};

export const getCategories = async (organisationId, rssId) => {
  const decryptedId = decrypt(organisationId);

  const response = await axios.get(
    `${internalApiUrl}/api/v1/organisation/${decryptedId}/rss_feed/${rssId}/category`
  );
  // console.log(response)
  console.log("CATEGORY RESPONSE");
  console.log(response.data);

  const categories = response.data;

  for (let i = 0; i < categories.length; i++) {
    if (categories[i].category_voices) {
      const voices = await getCategoryVoices(
        decryptedId,
        categories[i].id
      ).catch((err) => {
        console.log(err);
      });

      if (voices) {
        const bodyVoice = voices.body_voice_id;
        const titleVoice = voices.title_voice_id;

        categories[i].bodyVoice =
          CUSTOM_VOICE_ID_LABELS[bodyVoice ? bodyVoice.toString() : null];
        categories[i].titleVoice =
          CUSTOM_VOICE_ID_LABELS[titleVoice ? titleVoice.toString() : null];
      }
    }
  }

  return categories;
};

export const deleteCategories = async (organisationId, categoryId) => {
  const decryptedId = decrypt(organisationId);

  const response = await axios.delete(
    `${internalApiUrl}/api/v1/organisation/${decryptedId}/category/${categoryId}`
  );
  return response.data;
};

export const createCategories = async (organisationId, newCategory) => {
  const decryptedId = decrypt(organisationId);

  console.log(decryptedId, newCategory);

  const response = await axios.post(
    `${internalApiUrl}/api/v1/organisation/${decryptedId}/category`,
    newCategory
  );
  return response.data;
};

export const updateCategories = async (
  organisationId,
  rssId,
  categoryId,
  categoryUpdate
) => {
  const decryptedId = decrypt(organisationId);

  console.log(categoryUpdate);

  const response = await axios.patch(
    `${internalApiUrl}/api/v1/organisation/${decryptedId}/rss_feed/${rssId}/category/${categoryId}`,
    categoryUpdate
  );
  return response.data;
};

export const getCategoryVoices = async (organisationId, categoryId) => {
  // const decryptedId = decrypt(organisationId);

  const response = await axios.get(
    `${internalApiUrl}/api/v1/organisation/${organisationId}/category/${categoryId}/category-voice`
  );
  // console.log(response)
  console.log("CATEGORY RESPONSE");
  console.log(response.data);
  return response.data;
};

export const createCategoryVoices = async (
  organisationId,
  rssId,
  categoryId,
  newVoices
) => {
  const decryptedId = decrypt(organisationId);

  const response = await axios.post(
    `${internalApiUrl}/api/v1/organisation/${decryptedId}/rss_feed/${rssId}/category/${categoryId}/category-voice`,
    newVoices
  );
  return response.data;
};

export const updateCategoryVoices = async (
  organisationId,
  categoryId,
  rssId,
  voiceUpdate
) => {
  const decryptedId = decrypt(organisationId, rssId);

  const response = await axios.patch(
    `${internalApiUrl}/api/v1/organisation/${decryptedId}/rss_feed/${rssId}/category/${categoryId}/category-voice`,
    voiceUpdate
  );
  return response.data;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  signup,
  checkIfOrgExists,
  checkIfUserExistsInBackend,
  getAnalyticsToken,
  uploadFile,
  parseUrl,
  instantNarration,
  uploadWidgetImage,
  newRss,
  getRSS,
  getSingleRSS,
  deleteRssFeed,
  deleteTTSP,
  newPodcast,
  newEpisode,
  uploadPodcastImage,
  checkPodcast,
  deleteEpisode,
  getOriginalText,
  getLatestSubscriptionData,
  updateRss,
  getCategories,
};
