import Skeleton from "@mui/material/Skeleton";

const skeletonPercentLoading = () => {
	return (
		<Skeleton
			animation="wave"
			variant="text"
			width="180px"
			style={{
				margin: "auto",
				marginTop: "18px",
				marginBottom: "13px",
			}}
      data-testid="percent-skeleton"
		/>
	);
};

export default skeletonPercentLoading;
