import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import "./LoginPage.css";
import backendService from "../../../services/backend-service";
import {
  setUser,
  setIsThirdPartyAuthUser,
  setFirebaseToken,
} from "../../../reducers/user";
import { setSubscriptionData } from "../../../reducers/subscription";
import { useDispatch } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Spinner from "../../UI/Spinner/Spinner";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebase } from "../../../services/firebase-service";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../constants/theme";
import Typography from "@material-ui/core/Typography";
import { v4 as uuidv4 } from "uuid";
import { logSession } from "../../../services/logging-service";

/* Filename: LoginPage.js
 * Description: Login and signup page.
 * Children: Google login widget.
 * Props: login and logout handlers from the top level App.js component.
 */
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "95vh",
  },
  title: {
    color: COLORS.primary,
    fontWeight: "bold",
  },
  loginTitle: {
    color: COLORS.primary,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    height: "1.5em",
    width: "100%",
    visibility: "visible",
    marginTop: "20px",
    marginBottom: "20px",
    fontSize: "xx-large",
    fontWeight: "1000",
    position: "relative",
    verticalAlign: "middle",
    justifyContent: "center",
    margin: "auto",
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "/dashboard",
    // We will display Google and Microsoft as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
        fullLabel: "Sign in with Google",
      },
      {
        provider: "microsoft.com",
        providerName: "Microsoft",
        fullLabel: "Sign in with Microsoft",
        scopes: ["mail.read"],
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
        fullLabel: "Sign in with Email",
        buttonColor: "#0099ff",
      },
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        setShowSpinner(true);
        dispatch(setIsThirdPartyAuthUser(true));

        authResult.user.getIdTokenResult().then(async (tokenResult) => {
          const firebaseToken = tokenResult.token;
          dispatch(setFirebaseToken(firebaseToken));
          try {
            //Are they already signed up?
            const userDef = await backendService.checkIfUserExistsInBackend(
              firebaseToken
            );
            console.log("user info =>");
            console.log(userDef);

            if (userDef.userExists === true) {
              console.log("Is a play app user");
              console.log(userDef);
              if (userDef.userType === "play app") {
                window.location.replace("https://play.ad-auris.com/signin");
                return;
              }
            }

            if (userDef.userExists === false) {
              //Are they an email password user? If so need to check if they have verified their email
              if (authResult.additionalUserInfo.providerId === "password") {
                if (!authResult.user.emailVerified) {
                  //Go to the verify page
                  history.push("/verify");
                  return;
                }
              }
              //User has used a thirdparty auth provider, therefore their email is already verified, continue to signup.
              history.push("/signup");
            } else {
              //They are already a user, log them in and send them to the dashboard
              const loginResponse = await backendService.login(firebaseToken);
              console.log(loginResponse.data);
              let sessionId = uuidv4();
              let userInfo = { ...loginResponse.data, sessionId };
              console.log(userInfo);
              dispatch(setUser(userInfo));
              dispatch(
                setSubscriptionData(loginResponse.data.subscriptionData)
              );
              await logSession(userInfo);
              history.push("/dashboard");
            }
          } catch (err) {
            console.log(err);
            setShowSpinner(false);
            setErrorMessage("Could not sign in");
          }
        });
      },
    },
  };

  const userErrorMessage =
    "It look like something went wrong on our end. Please refresh the page and try again.";

  return (
    <div id="login-page">
      {errorMessage ? (
        <AppBar
          test-id="loginErrorBar"
          className="error-message-bar"
          style={{ background: "red" }}
        >
          {userErrorMessage}
        </AppBar>
      ) : null}
      <div id="login-page-content">
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className="paper">
            {showSpinner ? <Spinner /> : null}
            <Typography
              className={classes.title}
              variant="h3"
              component="h2"
              gutterBottom
            >
              Welcome Back
            </Typography>
          </div>
        </Container>
        <div className="authProvider-login-container"></div>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    </div>
  );
}
