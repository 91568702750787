import React, { useState, useEffect } from 'react';
import { TextField, Grid, makeStyles, Paper, Button } from '@material-ui/core';
import { useForm, Form } from './useForm'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector } from 'react-redux';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import './PaymentForm.css'
import { countries } from './country-constants'
const initialFieldvalues = {
    fullName: '',
    email: '',
    city: '',
    country: '',
    address: '',
    address2: '',
    state: '',
    postalCode: '',
}

const useStyle = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3)
    }
}))

const CARD_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: 'black',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            ':-webkit-autofill': {
                color: '#fce883',
            },
            '::placeholder': {
                color: 'grey',
            },
        },
        invalid: {
            iconColor: '#red',
            color: 'red',
        },
    }
}

const PaymentForm = ({ selectedProduct, setPrice, coupon, attemptingPayment, setAttemptingPayment }) => {
    const user = useSelector(state => state.user);
    const classes = useStyle()
    const { values, setValues, handleInputChange, errors, setErrors } = useForm(initialFieldvalues)
    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const stripe = useStripe()
    const elements = useElements()

    useEffect(() => {
            axios.post("/payment/price", {
            tier: selectedProduct
        }).then(res => {
            console.log(res.data)
            setPrice(res.data.price / 100)
        })

    }, [selectedProduct])

    const emailValid = emailInput => {
        const regEx = /\S+@\S+\.\S+/
        return regEx.test(emailInput)
    }
    const validate = () => {
        let temp = {}
        temp.fullName = values.fullName ? "" : "This field is required"
        temp.email = emailValid(values.email) ? "" : "Email is not valid"
        temp.city = values.city ? "" : "This field is required"
        temp.address = values.address ? "" : "This field is required"
        temp.state = values.state ? "" : "This field is required"
        temp.postalCode = values.postalCode ? "" : "This field is required"
        temp.country = values.country ? "" : "This field is required"
        setErrors({ ...temp })
        return Object.values(temp).every(x => x == "")
    }
    const handleSubmit = async (e) => {
        setErrorMessage('')

        e.preventDefault()
        if (!validate()) {
            return
        }
        setAttemptingPayment(true)
        let billingDetails = {
            address: {
                city: values.city,
                country: values.country,
                line1: values.address,
                line2: values.address2,
                postal_code: values.postalCode,
                state: values.state,
            },
            name: values.fullName,
            email: values.email
        }
        try {
            console.log('making method')
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement),
                billing_details: billingDetails,
            })
            if (!error) {
                const { id } = paymentMethod
                const response = await axios.post("/payment", {
                    paymentMethodId: id,
                    uid: user.uid,
                    billingDetails,
                    tier: selectedProduct,
                    coupon,
                    user
                })
                console.log("Successful Payment")
                setSuccess(true)
            }
            else {
                setAttemptingPayment(false)
                setErrorMessage((error.message))
            }
        } catch (err) {
            console.log(err.response)
            setAttemptingPayment(false)
            setErrorMessage("Sorry, could not process your payment. Please check your card information or contact us at tina.haertel@ad-auris.com")
        }
    }
    // Take care with styling parent components of stripe elements like <CardElement>, using material UI's <Paper> component caused the CardElement not to render for some users. 
    return (
        <div>
            {!success && <div>
                <div className="error-text payment-form-message">{errorMessage}</div>
                <Form onSubmit={handleSubmit}>
                    <div className="card-holder">
                        <CardElement options={CARD_OPTIONS} />
                    </div>
                    <TextField
                        variant="outlined"
                        label="Full Name"
                        name="fullName"
                        value={values.fullName}
                        onChange={handleInputChange}
                        error={errors.fullName}
                        helperText={errors.fullName}
                    />
                    <TextField
                        variant="outlined"
                        label="Email"
                        name="email"
                        value={values.email}
                        onChange={handleInputChange}
                        error={errors.email}
                        helperText={errors.email}
                    />
                    <Autocomplete

                        id="country-select-demo"
                        sx={{ width: 300 }}
                        options={countries}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={errors.country}
                                // helperText="HELO"
                                label="Choose a country"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                        value={values.countries}
                        onChange={(e, newVal) => handleInputChange(e, newVal)}
                    />
                    <TextField
                        variant="outlined"
                        label="Address"
                        name="address"
                        value={values.address}
                        onChange={handleInputChange}
                        error={errors.address}
                        helperText={errors.address}
                    />
                    <TextField
                        variant="outlined"
                        label="Address Line 2"
                        name="address2"
                        value={values.address2}
                        onChange={handleInputChange}
                        error={errors.address2}
                        helperText={errors.address2}
                    />
                    <TextField
                        variant="outlined"
                        label="City"
                        name="city"
                        value={values.city}
                        onChange={handleInputChange}
                        error={errors.city}
                        helperText={errors.city}
                    />
                    <TextField
                        variant="outlined"
                        label="State/Province"
                        name="state"
                        value={values.state}
                        onChange={handleInputChange}
                        error={errors.state}
                        helperText={errors.state}
                    />
                    <TextField
                        variant="outlined"
                        label="Postal Code"
                        name="postalCode"
                        value={values.postalCode}
                        onChange={handleInputChange}
                        error={errors.postalCode}
                        helperText={errors.postalCode}
                    />

                </Form>
                {!attemptingPayment && <Button color="primary" variant="contained" onClick={handleSubmit}>SUBSCRIBE</Button>}
                {attemptingPayment && <div className='payment-form-message'>Your Payment is being processed, please wait a moment</div>}
            </div>}
            {success && <div className='successful-payment'>
                <div className='success-message'>
                    Payment successful, you are now subscribed to {selectedProduct} Tier
                </div>
            </div>}
        </div>
    );
};

export default PaymentForm;