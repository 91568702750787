import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { shadows } from "@material-ui/system";
import SignoutButton from "../../../UI/SignoutButton/SignoutButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useSelector } from "react-redux";
import { COLORS } from "../../../../constants/theme";
import PropTypes from "prop-types";
import "./ActionBar.css";
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		color: "red",
	},
	navbar: {
		background: COLORS.primary,
	},
	menuButton: {
		color: "white",
		fontSize: "35px",
		fontFamily: "Montserrat, sans-serif",
		fontWeight: "500",
		marginLeft: "20px",
	},
	title: {
		flexGrow: 1,
	},
	navButton: {
		color: "white",
		textDecoration: "none",
		margin: "20px",
		fontSize: "27px",
	},
	userName: {
		color: "#white",
	},
}));

const ActionBar = ({
	handleLogout,
	setShowVideo,
	onSettingsButtonClicked,
	onLogoClicked,
}) => {
	const user = useSelector((state) => state.user);
	const classes = useStyles();
	return (
		<AppBar id="dash-app-bar" data-testid="dash-app-bar">
			<Toolbar style={{ justifyContent: "flex-end" }}>
				<SignoutButton id="signout-button" handleLogout={handleLogout} />
			</Toolbar>
		</AppBar>
	);
};

ActionBar.propTypes = {
	handleLogout: PropTypes.func,
	setShowVideo: PropTypes.func,
	onSettingsButtonClicked: PropTypes.func,
	onLogoClicked: PropTypes.func,
};

export default ActionBar;
