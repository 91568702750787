import { React } from "react";
import NarrationRow from "../../../UI/NarrationRow/NarrationRow";
import { CUSTOM_VOICE_TYPES } from "../../../../constants/constants";
import AutoCompleteField from "../../../UI/AutoCompleteField/AutoCompleteField";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Menu, ArrowUpwardSharp, ArrowDownwardSharp } from '@material-ui/icons';
import CheckedSwitch from "../Reusable/Switch";
import CustomTooltip from "../../../UI/CustomTooltip/CustomTooltip";

const CategoryList = ({ categories, updateVoice, deleteCategory, updatePriority, updateNarrate, organisationId, rssId, setCategories, openErrorPopup }) => {
  return (
    <table className="narrations-table files-list-table category-list">
      <thead>
        <NarrationRow columns={[
          {
            content: <span className="narration-label">In order of priority <CustomTooltip message='If a story falls within two categories, the settings of the category above will be applied.' /></span>,
            float: 'bottom',
            double: true
          },
          {
            content: <span className="narration-label">halt narration</span>,
            float: 'bottom'
          },
          {
            content: <span className="narration-label column-container" style={{ width: '100%', textAlign: 'center' }}>
              <p>voice settings</p>
              <div className="row-container">
                <span>title</span>
                <span>body</span>
              </div>
            </span>,
            double: true
          },
          {
            content: <span className="narration-label"></span>
          },
        ]} />
      </thead>
      <tbody>
        {categories.map((category, index) => {
          return <NarrationRow columns={[
            {
              content:
                <div className="row-container align-center">
                  <div className="category-arrow-container">
                    <div onClick={() => {
                      updatePriority({ index, rssId, move: "up", categories, organisationId, setCategories, openErrorPopup })
                    }}>
                      <ArrowUpwardSharp className="icon" />
                    </div>

                    <div onClick={() => {
                      updatePriority({ index, rssId, move: "down", categories, organisationId, setCategories, openErrorPopup })
                    }}>
                      <ArrowDownwardSharp className="icon" />
                    </div>
                  </div>
                  <span className="narration-label">{category.name}</span>
                </div>,
              double: true
            },
            {
              content: <CheckedSwitch
                checked={!category.narrate}
                onClick={() => {
                  updateNarrate({ cat: category, rssId, narrate: !category.narrate, organisationId, categories, setCategories, openErrorPopup })
                }} />
            },
            {
              content: <span className="narration-label row-container" style={{ width: '100%' }}>
                <AutoCompleteField
                  value={category.titleVoice}
                  setValue={(voice) => {
                    updateVoice({ cat: category, voice, voiceType: 'titleVoice', categories, organisationId, rssId, setCategories, openErrorPopup })
                  }}
                  label="Title Voice"
                  width={"40%"}
                  options={CUSTOM_VOICE_TYPES}
                />
                <AutoCompleteField
                  value={category.bodyVoice}
                  setValue={(voice) => {
                    updateVoice({ cat: category, voice, voiceType: 'bodyVoice', categories, organisationId, rssId, setCategories, openErrorPopup })
                  }}
                  label="Body Voice"
                  width={"40%"}
                  options={CUSTOM_VOICE_TYPES}
                />
              </span>,
              double: true
            },
            {
              content:
                <button
                  className="delete-button"
                  onClick={() => {
                    deleteCategory(category)
                  }}
                >
                  <DeleteForeverIcon fontSize={"inherit"} />
                </button>
            },
          ]} />
        })}
      </tbody>
    </table>
  );
};

export default CategoryList;
