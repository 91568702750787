import { React, useState, useEffect, Fragment } from "react";
import "react-dropzone-uploader/dist/styles.css";
import "./FileList.css";
import "./FileListBreakpoints.css";
import { useSelector } from "react-redux";
import {
  getSingleTSSP,
  checkIfWidgetExists,
  deleteTTSP,
} from "../../../../services/backend-service";
import backendService from "../../../../services/backend-service";
import { useDispatch } from "react-redux";
import { setFile } from "../../../../reducers/file";
import { withStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Checkbox from "@material-ui/core/Checkbox";
import { mdiPlayCircle, mdiPauseCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { registerdEvents, logEvent } from "../../../../logging/eventLogger";
import PopupModal from "../../../UI/PopupModal/PopupModal";
import HistoryBar from "../../../UI/HistoryBar/HistoryBar";
import Spinner from "../../../UI/Spinner/Spinner";
import SearchIcon from "@material-ui/icons/Search";
import NarrationRow from "../../../UI/NarrationRow/NarrationRow";
import NewNarrationButton from "../../../UI/NewNarrationButton/NewNarrationButton";
import CustomTooltip from "../../../UI/CustomTooltip/CustomTooltip";
import PropTypes from "prop-types";
import { useWindowSize } from "../../../../helpers/get-window-size";

/* Filename: FileList.js
 * Description: A page that lists all the user's uploaded files and
 their current status
*/
const FileList = ({
  setMode,
  onNarrationsClicked,
  testData,
  rss,
  onHomeClicked,
  onNewNarration,
}) => {
  const size = useWindowSize();
  let dispatch = useDispatch();
  let fetchedTtsps = useSelector((state) => state.analytics.ttsPackages);
  let podcast = useSelector((state) => state.analytics.podcast);
  let user = useSelector((state) => state.user);
  let [searching, setSearching] = useState(false);
  let [ttsps, setTtsps] = useState(fetchedTtsps);
  let [search, setSearch] = useState("");
  const [order, setOrder] = useState("");
  const [currentOrder, setCurrentOrder] = useState("");
  const [status, setStatus] = useState("deciding");
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [comments, setComments] = useState("");
  const [err, setErr] = useState("");
  const [currentTTSP, setCurrentTTSP] = useState({ id: null, title: "" });
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteState, setDeleteState] = useState("deciding");
  const [podDescription, setPodDescription] = useState("");
  const [podSub, setPodSub] = useState("");

  const narrationTitles = {};

  let searchInterval = setInterval(() => {
    search === "" ? setTtsps(fetchedTtsps) : console.log("");
    clearInterval(searchInterval);
  }, 10);

  if (testData) {
    dispatch = () => {};
    ttsps = testData.files;
    podcast = testData.podcast;
    user = testData.user;
  }

  //Sort the narration list by case
  let sorted = [];
  switch (order) {
    case "date desc":
      sorted = ttsps.sort((a, b) => {
        return a.id - b.id;
      });
      setOrder("");
      setCurrentOrder("date desc");
      setTtsps(sorted);
      break;
    case "date asc":
      sorted = ttsps.sort((a, b) => {
        return -(a.id - b.id);
      });
      setOrder("");
      setCurrentOrder("date asc");
      setTtsps(sorted);
      break;
  }

  let onRequest = async () => {
    try {
      await backendService.editRequest({
        id: user.id,
        email: user.email,
        username: user.username,
        ttspId: currentTTSP.id,
        narrationType: `<b>Title voice:</b> ${title} <b>Body voice:</b> ${body}`,
        comments: comments,
        title: `Edit Request: ${currentTTSP.title}`,
      });
      logNewEditRequest({
        ttsp: currentTTSP,
        requestingTitleVoice: title,
        requestingBodyVoice: body,
        comments: comments,
        title: `${currentTTSP.title}`,
      });
      setStatus("success");
    } catch (err) {
      console.log(err);
      setErr(err.message);
      setStatus("error");
    }
  };

  const handleDelete = async () => {
    setStatus("deleting");
    await deleteTTSP(currentTTSP.id);
    onNarrationsClicked();
    setStatus("success");
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDelete(false);
    setStatus("deciding");
  };

  const logNewEditRequest = (loggingData = null) => {
    loggingData = !!loggingData ? loggingData : {};
    logEvent(registerdEvents.requestEdit, user, loggingData);
  };

  /**@todo needs cleaning */
  let articles = null;
  if (ttsps && Array.isArray(ttsps)) {
    articles = ttsps.map((ttsp) => {
      //Check if the narration has already been rendered to avoid duplicates
      if (narrationTitles[ttsp.title]) {
        return <Fragment></Fragment>;
      } else {
        narrationTitles[ttsp.title] = true;

        return (
          <NarrationRow
            columns={[
              {
                content: (
                  <Fragment>
                    <span
                      title={ttsp.title}
                      style={{ textOverflow: "ellipsis" }}
                      className="narration-play-icon"
                      onClick={async () => {
                        setMode("showAudio");
                        checkIfWidgetExists(
                          user.routeIdentifier,
                          ttsp.route_identifier,
                          ttsp.id,
                          (exists) => {
                            dispatch(setFile({ audioExists: exists, ...ttsp }));
                          }
                        );
                        getSingleTSSP(
                          user.organisationId,
                          ttsp.id,
                          user.id,
                          user.routeIdentifier,
                          null,
                          (iframe, iframeString, title, audioFile) => {
                            dispatch(
                              setFile({
                                title: ttsp.title,
                                iframe,
                                iframeString,
                                audioFile,
                              })
                            );
                          }
                        );
                      }}
                    >
                      <Icon
                        className="play-icon-narration-list"
                        path={mdiPlayCircle}
                        color="#88D841"
                        size={size.width > 1000 ? 2 : 1.3}
                      />
                      <span className="narration-title">{ttsp.title}</span>
                    </span>
                  </Fragment>
                ),
                double: true,
              },
              {
                content: (
                  <div className="narration-tags">
                    <i
                      className="in-progress-custom"
                      style={{ opacity: Number(ttsp.audio_count) < 1 ? 1 : 0 }}
                    >
                      in-progress
                    </i>
                    <i
                      className="custom-narration"
                      style={{
                        opacity: ttsp.narration_method === "Custom" ? 1 : 0,
                      }}
                    >
                      custom
                    </i>
                    <i
                      className="podcast-episode"
                      style={{
                        opacity: Number(ttsp.episode_count) > 0 ? 1 : 0,
                      }}
                    >
                      podcast
                    </i>
                  </div>
                ),
              },
              size.width > 1000 ? { content: <span></span> } : null,
              size.width > 1000 ? { content: <span></span> } : null,
              size.width > 1000 ? { content: <span></span> } : null,
              {
                content: (
                  <span className="narration-label narration-date">
                    {ttsp.formatted_date}
                  </span>
                ),
              },
              {
                content: !rss ? (
                  <button
                    className="delete-button"
                    onClick={() => {
                      setCurrentTTSP(ttsp);
                      setOpenDelete(true);
                    }}
                  >
                    <DeleteForeverIcon fontSize={"inherit"} />
                  </button>
                ) : (
                  <CustomTooltip message="To delete please remove the iframe directly from your article template." />
                ),
              },
            ]}
          />
        );
      }
    });
  }

  return (
    <div id="file-list-page" className="page" data-testid="dash-file-list">
      {!rss ? (
        <HistoryBar
          items={[
            {
              text: "Home",
              click: onHomeClicked,
            },
          ]}
          currentItem="My Narrations"
        />
      ) : (
        <Fragment></Fragment>
      )}

      {/* Delete Popup */}
      <PopupModal
        handleClose={handleClose}
        open={openDelete}
        status={status === "deciding" ? "error" : status}
        errorContent={{
          title: `Permanently Delete ${currentTTSP.title}?`,
          body: (
            <Fragment>
              <p>
                You will still have to delete the audio player from you article.
              </p>
              <button
                style={{ zIndex: 99999 }}
                onClick={handleDelete}
                className="delete-button-popup"
              >
                delete
              </button>
            </Fragment>
          ),
        }}
        successContent={{
          title: `${currentTTSP.title} Deleted!`,
          body: <Fragment></Fragment>,
        }}
      />

      <div
        className="row-container"
        style={{ width: "90%", justifyContent: "space-between" }}
      >
        <div style={{ marginBottom: "50px" }}>
          {!rss ? (
            <NewNarrationButton
              style={{ marginLeft: "0", marginRight: "auto" }}
              label="+ new"
              onClick={onNewNarration}
            />
          ) : (
            <Fragment></Fragment>
          )}
        </div>
        <div className="search-bar">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => {
              e.persist();

              clearInterval(searchInterval);
              let currentSearch = e.target.value;
              searchInterval = setInterval(() => {
                let newTtsps = [];

                fetchedTtsps.forEach((ttsp) => {
                  if (
                    ttsp.title
                      .toLowerCase()
                      .includes(currentSearch.toLowerCase())
                  ) {
                    newTtsps.push(ttsp);
                  }
                });

                setSearch(e.target.value);
                setTtsps(newTtsps);

                clearInterval(searchInterval);
                console.log(ttsps);
              }, 1000);
            }}
          ></input>
        </div>
      </div>
      {!searching ? (
        <Fragment>
          {ttsps ? (
            <table className="narrations-table files-list-table">
              <thead>
                <NarrationRow
                  columns={[
                    {
                      content: <span className="narration-label">Title</span>,
                      double: true,
                    },
                    size.width > 1000 ? { content: <span></span> } : null,
                    size.width > 1000 ? { content: <span></span> } : null,
                    size.width > 1000 ? { content: <span></span> } : null,
                    {
                      content: (
                        <span
                          className="pointer narration-label"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            console.log(currentOrder);
                            if (currentOrder === "date asc") {
                              setOrder("date desc");
                            } else {
                              setOrder("date asc");
                            }
                          }}
                        >
                          <span>Date</span>{" "}
                          <span style={{ opacity: "0.5", fontSize: "0.6em" }}>
                            {currentOrder === "date asc" ? "▼" : "▲"}
                          </span>
                        </span>
                      ),
                    },
                    {
                      content: <span className="narration-label">Delete</span>,
                    },
                  ]}
                />
              </thead>
              <tbody>{articles}</tbody>
            </table>
          ) : (
            <div className="title">Pretty empty in here...</div>
          )}
        </Fragment>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

FileList.propTypes = {
  setMode: PropTypes.func,
  onNarrationsClicked: PropTypes.func,
  testData: PropTypes.object,
  rss: PropTypes.bool,
  onHomeClicked: PropTypes.func,
  onNewNarration: PropTypes.func,
};

export default FileList;
